// Authentication
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Utils
export const CHANGE_CURRENT_DOCUMENT = 'CHANGE_CURRENT_DOCUMENT';
export const CHANGE_CURRENT_LOPA = 'CHANGE_CURRENT_LOPA';
export const GET_TABLE = 'GET_TABLE';
export const GET_TABLE_NOT_SYNC = 'GET_TABLE_NOT_SYNC';
export const GET_COUNTS = 'GET_COUNTS';
export const GET_DOCUMENT_COUNT = 'GET_DOCUMENT_COUNT';
export const GET_INPUT = 'GET_INPUT';
export const GET_INPUT_SYNC = 'GET_INPUT_SYNC';
export const GET_DEFAULT_INPUT = 'GET_DEFAULT_INPUT';
export const SHOULD_RECONNECT_CHECK = 'SHOULD_RECONNECT_CHECK';
export const RECONNECT_CHECK_OK = 'RECONNECT_CHECK_OK';
export const ADD_SELECTED_VALUE = 'ADD_SELECTED_VALUE';
export const REMOVE_SELECTED_VALUE = 'REMOVE_SELECTED_VALUE';
export const RESET_TABLE = 'RESET_TABLE';

// Record
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const CREATE_RECORD = 'CREATE_RECORD';
export const CREATE_RECORDS = 'CREATE_RECORDS';
export const DELETE_RECORD = 'DELETE_RECORD';
export const OPEN_RECORD_MODAL = 'OPEN_RECORD_MODAL';
export const CLOSE_RECORD_MODAL = 'CLOSE_RECORD_MODAL';
export const BATCH_UPDATE = 'BATCH_UPDATE';

// Location Editor
export const OPEN_LOCATION_EDITOR_MODAL = 'OPEN_LOCATION_EDITOR_MODAL';
export const CLOSE_LOCATION_EDITOR_MODAL = 'CLOSE_LOCATION_EDITOR_MODAL';
export const DELETE_LOCATION_EDITOR_RECORD = 'DELETE_LOCATION_EDITOR_RECORD';
export const UPDATE_SIDE_LOCATION_EDITOR = 'UPDATE_SIDE_LOCATION_EDITOR';
export const UPDATE_RECORD_LOCATION_EDITOR = 'UPDATE_RECORD_LOCATION_EDITOR';
export const GET_LETTERINGS = 'GET_LETTERINGS';

// Status
export const CHECK_API_STATUS = 'CHECK_API_STATUS';

// Viewer
export const VIEWER_OPEN_EDITOR = 'VIEWER_OPEN_EDITOR';
export const VIEWER_GET_EDITOR_ATTRIBUTES = 'VIEWER_GET_EDITOR_ATTRIBUTES';
export const VIEWER_TOGGLE_HIDE_MODE = 'VIEWER_TOGGLE_HIDE_MODE';
export const VIEWER_TOGGLE_SECTION = 'VIEWER_TOGGLE_SECTION';
export const VIEWER_GO_TO_PAGE = 'VIEWER_GO_TO_PAGE';

// Files
export const GET_DOCUMENTS_FROM_BUCKET = 'GET_DOCUMENTS_FROM_BUCKET';
export const PICK_FILE = 'PICK_FILE';
export const FILE_UPLOADED = 'FILE_UPLOADED';
export const GET_FILES_FROM_DB = 'GET_FILES_FROM_DB';

// Snackbar
export const SNACKBAR_ENQUEUE = 'SNACKBAR_ENQUEUE';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';
export const SNACKBAR_UPDATE = 'SNACKBAR_UPDATE';

// Document progression
export const GET_STEPS_PROGRESS = 'GET_STEPS_PROGRESS';
export const GET_STEP_RESET_INFO = 'GET_STEP_RESET_INFO';

// Step progression
export const UPDATE_STEP_PROGRESS = 'UPDATE_STEP_PROGRESS';
export const CLOSE_STEP_PROGRESS = 'CLOSE_STEP_PROGRESS';

// Document creation
export const DOCUMENT_CREATION_OPEN = 'DOCUMENT_CREATION_OPEN';
export const DOCUMENT_CREATION_CLOSE = 'DOCUMENT_CREATION_CLOSE';

// Formatting

// Section
export const SECTION_CHANGE_KEYWORD = 'SECTION_CHANGE_KEYWORD';
export const SECTION_CHANGE_SECTION = 'SECTION_CHANGE_SECTION';

// Equipment
export const EQUIPMENT_GET_DATA = 'EQUIPMENT_GET_DATA';
export const EQUIPMENT_CHANGE_FILTER = 'EQUIPMENT_CHANGE_FILTER';

// Description
export const DESCRIPTION_INIT = 'DESCRIPTION_INIT';
export const DESCRIPTION_GET_RULES = 'DESCRIPTION_GET_RULES';
export const DESCRIPTION_SORT_RULES = 'DESCRIPTION_SORT_RULES';

// Figure
export const FIGURE_GET_DESCRIPTIONS = 'FIGURE_GET_DESCRIPTIONS';
export const FIGURE_CHANGE_INPUT = 'FIGURE_CHANGE_INPUT';
export const FIGURE_CHANGE_KEYWORD = 'FIGURE_CHANGE_KEYWORD';
export const FIGURE_CHANGE_REGEX = 'FIGURE_CHANGE_REGEX';

// Line
export const LINES_GET_DOCUMENT_LINES = 'LINES_GET_DOCUMENT_LINES';

// Representation
export const REPRESENTATION_CHANGE_INPUT = 'REPRESENTATION_CHANGE_INPUT';
export const REPRESENTATION_GET_ITEMS = 'REPRESENTATION_GET_ITEMS';
export const REPRESENTATION_CHANGE_FIGURE_PARSER =
  'REPRESENTATION_CHANGE_FIGURE_PARSER';

// Notification
export const NOTIFICATIONS_GET_FOR_DOCUMENT = 'NOTIFICATIONS_GET_FOR_DOCUMENT';
export const NOTIFICATIONS_GET_FOR_USER = 'NOTIFICATIONS_GET_FOR_USER';
export const NOTIFICATIONS_NEW = 'NOTIFICATIONS_NEW';
export const NOTIFICATIONS_TOGGLE_SHOW_CLOSED =
  'NOTIFICATIONS_TOGGLE_SHOW_CLOSED';
export const NOTIFICATIONS_UPDATE_PAGINATION =
  'NOTIFICATIONS_UPDATE_PAGINATION';

// Anomalies
export const ANOMALIES_GET_FOR_DOCUMENT = 'ANOMALIES_GET_FOR_DOCUMENT';

// TABLE
export const REFRESH_TABLE = 'REFRESH_TABLE';
export const CHANGE_FIGURE_FILTER = 'CHANGE_FIGURE_FILTER';
export const HIGHLIGHT_TABLE = 'HIGHLIGHT_TABLE';
export const SELECT_TABLE = 'SELECT_TABLE';
export const CHANGE_TABLE = 'CHANGE_TABLE';
export const CHANGE_TABLE_NOT_SYNC = 'CHANGE_TABLE_NOT_SYNC';
export const TABLE_TOGGLE_TYPE = 'TABLE_TOGGLE_TYPE';
export const TABLE_TOGGLE_TYPE_NOT_SYNC = 'TABLE_TOGGLE_TYPE_NOT_SYNC';
export const CHANGE_TABLE_HEADER = 'CHANGE_TABLE_HEADER';
export const TABLE_CHANGE_SEARCH = 'TABLE_CHANGE_SEARCH';
export const TABLE_CHANGE_SEARCH_COLUMN = 'TABLE_CHANGE_SEARCH_COLUMN';
export const TABLE_OPEN_MODAL = 'TABLE_OPEN_MODAL';
export const TABLE_CLOSE_MODAL = 'TABLE_CLOSE_MODAL';

// Description
export const DESCRIPTION_TABLE_GET_DATA = 'DESCRIPTION_TABLE_GET_DATA';

// Item
export const ITEM_TABLE_GET_DATA = 'ITEM_TABLE_GET_DATA';
export const ITEM_TABLE_GET_REMARK_DATA = 'ITEM_TABLE_GET_REMARK_DATA';
export const ITEM_TABLE_TOGGLE_REMARK_ONLY = 'ITEM_TABLE_TOGGLE_REMARK_ONLY';
export const ITEM_TABLE_CHANGE_FILTER = 'ITEM_TABLE_CHANGE_FILTER';
export const ITEM_TABLE_GET_NHA_BKDN = 'ITEM_TABLE_GET_NHA_BKDN';
export const ITEM_TABLE_GET_EQUIPMENT_DISTRIBUTION =
  'ITEM_TABLE_GET_EQUIPMENT_DISTRIBUTION';

export const ITEM_EXTRACTION_NEW_RESULTS = 'ITEM_EXTRACTION_NEW_RESULTS';
export const ITEM_EXTRACTION_SAVED_RESULTS = 'ITEM_EXTRACTION_SAVED_RESULTS';

// Menu
export const MENU_TOGGLE = 'MENU_TOGGLE';

// Poc
export const CHANGE_CURRENT_POC = 'CHANGE_CURRENT_POC';
export const GET_POC_ITEMS = 'GET_POC_ITEMS';
export const GET_POC_RATE = 'GET_POC_RATE';
export const POC_SELECT_RATE_FILTER = 'POC_SELECT_RATE_FILTER';

// 3D Engine
export const ENGINE_LOAD_SCENE = 'ENGINE_LOAD_SCENE';
export const ENGINE_SELECT = 'ENGINE_SELECT';
export const ENGINE_LOAD_DOCUMENT = 'ENGINE_LOAD_DOCUMENT';
export const ENGINE_SELECT_AREA = 'ENGINE_SELECT_AREA';

// Figure SideBar
export const CLOSE_FIGURE_SIDEBAR = 'CLOSE_FIGURE_SIDEBAR';
export const SHOW_FIGURE_SIDEBAR = 'SHOW_FIGURE_SIDEBAR';
export const GET_FIGURE_WITH_BKDN = 'GET_FIGURE_WITH_BKDN';

// User choices
export const GET_SUBASSEMBLY_BY_SEMANTIC_GROUP =
  'GET_SUBASSEMBLY_BY_SEMANTIC_GROUP';
export const GET_LAYER_BY_SEMANTIC_GROUP = 'GET_LAYER_BY_SEMANTIC_GROUP';

// Category Editor
export const GET_LOCATION_CATEGORY_FOR_SEMANTIC_GROUP =
  'GET_LOCATION_CATEGORY_FOR_SEMANTIC_GROUP';
