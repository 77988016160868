import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

/**
 * Open modal for to create/edit locations
 */
export const openLocationEditorModal = () => dispatch => {
  dispatch({
    type: types.OPEN_LOCATION_EDITOR_MODAL,
  });
  dispatch(getLetterings());
};

/**
 * Get letterings
 * @param {string} letteringType letteringType to fetch (null = all)
 */
export const getLetterings = (letteringType = null) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.LocationGeneric.getLetterings);
    const letterings = await API.get(
      url,
      letteringType ? { letteringType } : null,
    );
    if (letterings)
      dispatch({
        type: types.GET_LETTERINGS,
        payload: letterings,
      });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Close locationEditor modal and clear locationEditor redux state
 */
export const closeLocationEditorModal = () => dispatch => {
  dispatch({
    type: types.CLOSE_LOCATION_EDITOR_MODAL,
  });
};

/**
 * Update side for all locations in locationEditor
 * @param {string} side side to set to all location
 */
export const updateSideLocationEditor = side => dispatch => {
  dispatch({
    type: types.UPDATE_SIDE_LOCATION_EDITOR,
    payload: side,
  });
};

/**
 * Update a record in locationEditor
 * @param {number} index index to update
 * @param {*} record new record value
 */
export const updateRecordLocationEditor = (index, record) => dispatch => {
  dispatch({
    type: types.UPDATE_RECORD_LOCATION_EDITOR,
    index,
    payload: record,
  });
};

/**
 * Delete a location record from locationEditor
 * @param {string} location location to remove
 */
export const deleteLocationEditorRecord = location => dispatch => {
  dispatch({
    type: types.DELETE_LOCATION_EDITOR_RECORD,
    payload: location,
  });
};

/**
 * Open modal for table and filled with record if id specified
 * @param {string} table
 * @param {?number} id id to fetch
 */
export const getLocationPreview = lettering => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.location.preview);
    const locations = await API.get(url, { lettering });
    locations &&
      dispatch({
        type: types.GET_TABLE_NOT_SYNC,
        table: 'LocationGeneric',
        payload: locations && locations.length ? locations : ['NO DATA'],
      });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Get side-letterings
 * @param {array} sideLetterings array of { side, lettering }
 */
export const getSideLetterings = (sideLetterings = []) => async dispatch => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.LocationGeneric.getSideLetterings,
    );
    const letterings = await API.post(url, { sideLetterings });
    if (letterings)
      dispatch({
        type: types.GET_TABLE_NOT_SYNC,
        table: 'LocationGeneric',
        payload: Array.isArray(letterings)
          ? letterings.length
            ? letterings
            : ['NO DATA']
          : letterings,
      });
  } catch (err) {
    console.error(err);
    return false;
  }
};
