import React from 'react';
import { Button } from 'semantic-ui-react';

import * as network from 'config/network';
import { store } from 'config/store';
import { notificationTime } from 'config/constants';
import * as types from 'config/types';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  enqueueSnackbar,
  updateSnackbar,
  closeSnackbar,
} from 'components/snackbar/snackbarActions';
import { updateStepProgress } from 'components/stepProgress/stepProgressActions';
import { checkForPushSockets } from 'components/utils/utilsActions';

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

let currentAuthToken = null;

/**
 * Keep user token in memory
 * @param {string} token user token
 */
export function setToken(token, dispatch) {
  currentAuthToken = token;
  if (token) subscribeToEvents(dispatch);
}

/**
 * Check user token validity
 * @param {string} token user token
 */
export const checkToken = async token => {
  try {
    const url = buildURL(network.ENDPOINTS.auth.check);
    const response = await post(url, {
      token,
    });
    if (response && response.expired === false) return true;
    return false;
  } catch (err) {
    return false;
  }
};

/**
 * Return result of a request
 * @param {string} url API URL to fetch
 * @param {string} options options used to fetch
 * @param {boolean} json convert response to an object
 */
export const request = (url, options, json = true) => {
  return fetch(url, options)
    .then(response => {
      if (response.status === 401)
        return store.dispatch({
          type: types.LOGOUT,
        });

      if (response.status > 300)
        store.dispatch(
          enqueueSnackbar({
            message: response.statusText,
            options: {
              variant: 'warning',
            },
          }),
        );

      if (json) return response.json();
      return response;
    })
    .catch(() => {});
};

/**
 * Return result of a get request
 * @param {string} url API URL to fetch
 * @param {*} parameters object to use as query in request
 * @param {boolean} json convert response to an object
 */
export const get = (_url, parameters = null, json = true) => {
  let url = _url;
  const headers = buildHeaders(currentAuthToken);
  const options = {
    method: 'GET',
    headers,
  };
  if (parameters) {
    const urlParams = new URLSearchParams(parameters);
    url = `${url}?${urlParams}`;
  }

  return request(url, options, json);
};

/**
 * Return result of a post request
 * @param {string} url API URL to fetch
 * @param {*} body object to use as body in request
 * @param {boolean} json convert response to an object
 */
export const post = (url, body = {}, json = true) => {
  const headers = buildHeaders(currentAuthToken);
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  return request(url, options, json);
};

/**
 * Return result of a put request
 * @param {string} url API URL to fetch
 * @param {*} body object to use as body in request
 * @param {boolean} json convert response to an object
 */
export const put = (url, body = {}, json = true) => {
  const headers = buildHeaders(currentAuthToken);
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers,
  };

  return request(url, options, json);
};

/**
 * Return result of a delete request
 * allcaps because reserved keyword in JS
 * @param {string} url API URL to fetch
 * @param {*} body object to use as body in request
 * @param {boolean} json convert response to an object
 */
export const DELETE = (url, body = {}, json = true) => {
  const headers = buildHeaders(currentAuthToken);
  const options = {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers,
  };

  return request(url, options, json);
};

/**
 * Return m3dian API URL
 * Use cases:
 * { table: 'XmlText', id: 1777 },
 * { table: 'XmlText', id: 1777, function: 'delete' },
 * { table: 'Document', function: 'get' },
 * { path: '/status', base: true },
 * '/Document/get'
 * @param {*} endpoint endpoint to fetch
 */
export const buildURL = endpoint => {
  if (endpoint.base) return `${process.env.API_URL}${endpoint.path}`;

  if (endpoint.id) {
    if (endpoint.function)
      return `${process.env.API_URL}/api/${endpoint.table}/${endpoint.id}/${endpoint.function}`;
    return `${process.env.API_URL}/api/${endpoint.table}/${endpoint.id}`;
  }

  if (endpoint.function)
    return `${process.env.API_URL}/api/${endpoint.table}/${endpoint.function}`;

  return `${process.env.API_URL}/api${endpoint}`;
};

/**
 * Return headers with token attached
 * @param {string} token user token
 */
export const buildHeaders = token => {
  const headers = {
    ...defaultHeaders,
  };
  if (token) headers.Authorization = `${token}`;
  return headers;
};

export const uploadFileToBucket = (file, url) =>
  request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    body: file,
  });

export const subscribeToEvents = async dispatch => {
  try {
    const token = await checkToken(currentAuthToken);
    if (!token) return;

    let source = new EventSourcePolyfill(
      buildURL(network.ENDPOINTS.events.pushEvents),
      {
        headers: buildHeaders(currentAuthToken),
      },
    );
    let connectionLost = null;
    let checkReconnect = null;

    source.addEventListener('error', event => {
      event.preventDefault();

      if (event.status === 200) return;

      if (!connectionLost) {
        connectionLost = enqueueSnackbar({
          message: (
            <div style={{ display: 'flex', alignItems: 'center', gap: 22 }}>
              API CONNECTION LOST
              <Button
                color="red"
                compact
                onClick={() => {
                  window.location.reload();
                }}
              >
                REFRESH
              </Button>
            </div>
          ),
          options: {
            variant: 'error',
            autoClose: false,
            closeButton: false,
            draggable: false,
            closeOnClick: false,
            className: 'default_cursor',
            toastId: 'api_connection_lost',
          },
        });
        dispatch(connectionLost);
        dispatch(
          updateStepProgress({
            data: { severity: 'Error', step: 'API CONNECTION LOST' },
          }),
        );
        // Try to reconnect after 5 seconds
        checkReconnect = setInterval(() => {
          dispatch(checkForPushSockets());
        }, 5000);
      }
    });
    source.onmessage = function(event) {
      if (event.type === 'error') {
        // all options request catch here
      } else if (event.type === 'open') {
        console.info('Connection open');
      } else {
        let response = JSON.parse(event.data);
        if (response.notification)
          dispatch({
            type: types.NOTIFICATIONS_NEW,
            payload: response.notification,
          });

        if (response.routingKey === 'reconnectCheck') {
          clearInterval(checkReconnect);

          connectionLost?.notification?.options?.toastId &&
            dispatch(
              closeSnackbar(connectionLost.notification.options.toastId),
            );
          connectionLost = null;
          return;
        }

        if (response.routingKey === 'itemExtractionResults') {
          return dispatch({
            type: types.ITEM_EXTRACTION_NEW_RESULTS,
            results: response,
          });
        }

        if (response.routingKey) dispatch(updateStepProgress(response));

        if (
          !response.notificationKey &&
          !response.routingKey &&
          response.message
        )
          dispatch(
            enqueueSnackbar({
              message: response.message,
              options: {
                variant: getResponseVariant(response),
              },
            }),
          );

        // Remove a notification in snackbar
        if (response.notificationKey) {
          if (!response.newMessage)
            return dispatch(
              updateSnackbar({
                message: response.message,
                options: {
                  toastId: parseFloat(response.notificationKey),
                  variant: getResponseVariant(response),
                  autoClose: notificationTime,
                  closeButton: null,
                  draggable: true,
                  progress: null,
                  closeOnClick: true,
                  isLoading: false,
                  className: '',
                },
              }),
            );

          dispatch(
            updateSnackbar({
              message: response.newMessage,
              options: {
                toastId: parseFloat(response.notificationKey),
                variant: 'info',
                progress:
                  response.value &&
                  response.total &&
                  response.value / response.total,
              },
            }),
          );
        }
      }
    };
  } catch (err) {
    console.error(err);
    console.error("Couldn't subscribe to event emitters");
  }
};

const getResponseVariant = response =>
  response.severity === 'warn'
    ? 'warning'
    : response.severity === 'Notice'
    ? 'success'
    : response.error || response.severity === 'critical'
    ? 'error'
    : response.severity === 'routine'
    ? 'info'
    : 'default';
