import produce from 'immer';

import * as types from 'config/types';
import * as network from 'config/network';

import { getFirstFigure } from 'lib/utils';

const initialState = {
  pocs: [
    {
      name: 'itemGroup-elasticSearch',
      type: 'itemGroup',
      label: 'DB itemGroup',
      result: 'itemGroup',
      displayedResult: 'itemGroupDescription',
      endpoint: network.ENDPOINTS.pocs.elasticSearchGetItemGroup,
    },
  ],
  currentPoc: null,
  currentFigure: null,
  items: ['NO DATA'],
  selectedFilter: null,
  rates: ['NO DATA'],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.currentFigure = initialState.currentFigure;
        draft.items = state.currentPoc ? [] : initialState.items;
        draft.selectedFilter = initialState.selectedFilter;
        draft.rates = state.currentPoc ? [] : initialState.rates;
        break;

      case types.CHANGE_CURRENT_POC:
        {
          const currentPoc = state.pocs.find(
            poc => poc.name === action.payload,
          );
          draft.currentPoc = currentPoc;
          draft.items = [];
          draft.rates = [];
        }
        break;

      case types.CHANGE_FIGURE_FILTER:
        if (action.table === 'pocs') draft.currentFigure = action.payload;
        draft.items = state.currentPoc ? [] : initialState.items;
        draft.rates = state.currentPoc ? [] : initialState.rates;
        break;

      case types.GET_TABLE:
        if (action.table === 'Figure' && !state.currentFigure)
          draft.currentFigure = getFirstFigure(
            action.payload,
            state.currentFigure,
          );
        break;

      case types.GET_POC_ITEMS:
        draft.items = action.payload;
        break;

      case types.GET_POC_RATE:
        draft.rates = action.payload;
        break;

      case types.POC_SELECT_RATE_FILTER:
        draft.selectedFilter =
          state.selectedFilter !== action.payload
            ? action.payload
            : initialState.selectedFilter;
        break;

      default:
        break;
    }
  });
