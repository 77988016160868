import produce from 'immer';
import * as types from 'config/types';
import { tables } from './TablesContent';

const initialState = {
  type: 'item',
  header: [],
  search: '',
  searchColumn: null,
  refresh: false,
  open: false,
  modalType: null,
  modalHeader: [],
  modalSearch: '',
  modalSearchColumn: null,
  counts: {},
};

const tablesCounts = tables
  .map(
    table =>
      table.counts &&
      table.counts.map(count =>
        count.endpoint ? count.name : count.table || count,
      ),
  )
  .flat();

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.counts = initialState.counts;
        break;

      case types.GET_DOCUMENT_COUNT:
        if (tablesCounts.includes(action.table))
          draft.counts[action.table] = action.payload.count;
        break;

      case types.CHANGE_TABLE:
        draft.type = action.payload;
        break;

      case types.CHANGE_TABLE_NOT_SYNC:
        draft.type = action.payload;
        break;

      case types.REFRESH_TABLE:
        draft.refresh = action.payload;
        break;

      case types.CHANGE_TABLE_HEADER:
        if (action.payload.modal) {
          draft.modalHeader = action.payload.header;
          // Keep selected column if still displayed
          draft.modalSearchColumn = action.payload.header.some(
            column => column.dataKey === state.modalSearchColumn,
          )
            ? state.modalSearchColumn
            : null;
        } else {
          draft.header = action.payload.header;
          // Keep selected column if still displayed
          draft.searchColumn = action.payload.header.some(
            column => column.dataKey === state.searchColumn,
          )
            ? state.searchColumn
            : null;
        }
        break;

      case types.TABLE_CHANGE_SEARCH:
        if (action.payload.modal) {
          draft.modalSearch = action.payload.search;
        } else {
          draft.search = action.payload.search;
        }
        break;

      case types.TABLE_CHANGE_SEARCH_COLUMN:
        if (action.payload.modal) {
          draft.modalSearchColumn = action.payload.column;
        } else {
          draft.searchColumn = action.payload.column;
        }
        break;

      case types.TABLE_OPEN_MODAL:
        draft.open = true;
        draft.modalType = action.payload;
        break;

      case types.TABLE_CLOSE_MODAL:
        draft.open = false;
        break;

      default:
        break;
    }
  });
