import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter as _withRouter } from 'react-router-dom';

import * as actions from 'actions';

export default (
  mapStateToProps = null,
  mapDispatchToProps = null,
  withRouter = false,
) => Comp => {
  const wrapper = class Wrapper extends Component {
    render() {
      if (process.env.NODE_ENV !== 'production')
        console.debug('Rendering', Comp.name);
      return <Comp {...this.props} />;
    }
  };
  wrapper.propTypes = {
    router: PropTypes.object,
    user: PropTypes.object,
    ...Comp.propTypes,
  };

  const mapDispatchToPropsAuto = dispatch => {
    const externalDispatchToProps = mapDispatchToProps
      ? mapDispatchToProps(dispatch)
      : {};
    return {
      dispatch,
      actions: bindActionCreators(actions, dispatch),
      ...externalDispatchToProps,
    };
  };

  const mapStateToPropsAuto = (state, props) => {
    const externalStateToProps = mapStateToProps
      ? mapStateToProps(state, props)
      : {};
    return {
      auth: state.auth,
      currentDocumentId: state.utils.currentDocument.id || null,
      ...externalStateToProps,
    };
  };
  return withRouter
    ? _withRouter(connect(mapStateToPropsAuto, mapDispatchToPropsAuto)(wrapper))
    : connect(mapStateToPropsAuto, mapDispatchToPropsAuto)(wrapper);
};
