import React from 'react';
import { Button as SemanticButton, Popup, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import classnames from 'classnames';

import connect from 'lib/reduxConnect';

const renderItem = (
  id,
  bkdn = false,
  nha = false,
  nhaBkdnElement,
  actions,
  table = 'itemTable',
) => (
  <Popup
    key={`${id}_${nhaBkdnElement.id}`}
    basic
    inverted
    content={
      nhaBkdnElement &&
      (nhaBkdnElement.descriptionFull ||
        nhaBkdnElement.descriptionDoc ||
        nhaBkdnElement.descriptionShort)
    }
    popper={{ style: { pointerEvents: 'none' } }}
    wide="very"
    trigger={
      <SemanticButton
        size="tiny"
        compact
        color="grey"
        circular
        style={bkdn ? styles.bkdnButton : null}
        icon={
          <Icon
            name={bkdn ? 'level down' : nha && 'level up'}
            flipped={nha ? 'horizontally' : null}
            style={styles.icon}
          />
        }
        labelPosition={bkdn ? 'right' : nha && 'left'}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          actions.highlightTable(
            table,
            [nhaBkdnElement.id],
            nhaBkdnElement.id,
            {
              figureDoc: nhaBkdnElement.figureDoc,
            },
          );
        }}
        content={
          nhaBkdnElement &&
          `Fig.${nhaBkdnElement.figureDoc} (${nhaBkdnElement.itemDoc})`
        }
      />
    }
  />
);

const Button = ({
  id,
  nhaBkdn = [],
  bkdn = false,
  nha = false,
  actions,
  table,
}) => {
  const btn = nhaBkdn.map(nhaBkdnElement => {
    return renderItem(id, bkdn, nha, nhaBkdnElement, actions, table);
  });

  return btn;
};

const NhaBkdnButton =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

NhaBkdnButton.propTypes = {
  bkdn: PropTypes.bool,
  id: PropTypes.number,
  nha: PropTypes.bool,
  nhaBkdn: PropTypes.array,
};

export const NhaBkdnButtons = React.memo(
  React.forwardRef(
    (
      {
        item = {},
        table = 'itemTable',
        index,
        onClick = () => {},
        isExtendable,
        isExtended,
      },
      ref,
    ) => {
      return item.BKDN || item.NHA ? (
        <div
          className={classnames('extension_arrow', {
            visible: isExtendable,
            open: isExtended,
          })}
          onClick={event => onClick(event, { item, index })}
          role="button"
          tabIndex="0"
          style={{ display: 'flex' }}
        >
          <div style={{ flexShrink: 0 }} />
          <div style={styles.buttonGroup} ref={ref}>
            {item.NHA && (
              <NhaBkdnButton
                nha
                id={item.id}
                nhaBkdn={item.NHA}
                table={table}
              />
            )}
            {item.NHA && item.BKDN ? <hr style={styles.hr} /> : null}
            {item.BKDN && (
              <NhaBkdnButton
                bkdn
                id={item.id}
                nhaBkdn={item.BKDN && [item.BKDN]}
                table={table}
              />
            )}
          </div>
        </div>
      ) : null;
    },
    (prevProps, nextProps) => {
      const isItemBKDNEqual = equal(nextProps.item?.BKDN, prevProps.item?.BKDN);
      const isItemNHAEqual = equal(nextProps.item?.NHA, prevProps.item?.NHA);

      return isItemNHAEqual && isItemBKDNEqual;
    },
  ),
);

NhaBkdnButtons.propTypes = {
  index: PropTypes.number,
  isExtendable: PropTypes.bool,
  isExtended: PropTypes.bool,
  item: PropTypes.object,
  onClick: PropTypes.func,
  table: PropTypes.string,
};

const styles = {
  buttonGroup: { display: 'flex', flexWrap: 'wrap', gap: 5 },
  icon: { background: 'none' },
  bkdnButton: {
    fontWeight: 700,
    boxShadow: '0 0 0 2px #767676 inset',
    color: '#767676',
    background: 'white',
    textShadow: 'none',
  },
  list: {
    marginTop: 4,
    padding: 5,
    marginRight: 7,
  },
  hr: {
    width: '100%',
    margin: 0,
    border: 'none',
  },
};
