import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Button = React.memo(({ section, color, actions }) => (
  <SemanticButton
    compact
    color={color}
    size="tiny"
    onClick={() => actions.changeSection(section)}
  >
    {section?.toUpperCase()}
  </SemanticButton>
));

const SectionButton =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

Button.propTypes = {
  color: PropTypes.string,
  section: PropTypes.string,
};

export default SectionButton;
