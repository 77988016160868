import produce from 'immer';
import { toObject } from 'ais-utils';

import * as types from 'config/types';
import { getFirstFigure } from 'lib/utils';

const initialState = {
  highlight: [],
  scrollTo: null,
  filter: null,
  figureFilter: null,
  counts: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.figureFilter = initialState.figureFilter;
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        draft.filter = initialState.filter;
        draft.counts = initialState.counts;
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'representationTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.CHANGE_FIGURE_FILTER:
        if (action.table === 'representationTable') {
          draft.figureFilter = action.payload;
          if (action.payload.documentPage)
            draft.documentPage = action.payload.documentPage;
        }
        break;

      case types.GET_TABLE:
        if (action.table === 'Figure' && !state.figureFilter)
          draft.figureFilter = getFirstFigure(
            action.payload,
            state.figureFilter,
          );
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (action.table === 'Figure' && !state.figureFilter)
          draft.figureFilter = getFirstFigure(
            action.payload,
            state.figureFilter,
          );
        break;

      case types.GET_COUNTS:
        if (action.table === 'ItemCoordinate')
          draft.counts = toObject(action.payload, 'id');
        break;

      default:
        break;
    }
  });
