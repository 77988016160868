import React from 'react';
import { Input as SemanticInput } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const Input = React.memo(
  ({ dataKey, value, rowData, rowIndex, disabled, onChange }) => {
    const type = typeof value;
    return (
      <SemanticInput
        value={value}
        type={type}
        disabled={disabled}
        fluid
        transparent
        onChange={(e, { value }) =>
          onChange(type, dataKey, value, rowData, rowIndex)
        }
      />
    );
  },
);

Input.propTypes = {
  dataKey: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  rowData: PropTypes.object,
  rowIndex: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Input;
