import React, { useState } from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Dropdown = ({
  rowData,
  cellData,
  tableRef,
  letterings,
  currentDocumentLetteringType,
  actions,
}) => {
  const [open, setOpen] = useState(false);

  const onDropDownOpen = () => {
    currentDocumentLetteringType &&
      actions.getLetterings(currentDocumentLetteringType);
  };

  return (
    <SemanticDropdown
      className="transparent"
      onOpen={() => {
        setOpen(true);
        onDropDownOpen();
        // Force update of the table
        tableRef.current.recomputeRowHeights();
      }}
      onClose={() => setOpen(false)}
      fluid
      upward={false}
      search
      selection
      clearable
      selectOnBlur={false}
      onChange={(e, { value }) => {
        const lettering = letterings.find(
          lettering => lettering.lettering === value,
        );
        if (lettering && lettering.lettering)
          return actions.updateRecord('Equipment', {
            ...rowData,
            lettering: lettering.lettering,
            side: lettering.side,
          });
        actions.updateRecord('Equipment', {
          ...rowData,
          lettering: null,
          side: null,
        });
        return setOpen(false);
      }}
      value={cellData}
      text={cellData}
      options={
        open
          ? letterings.map(lettering => ({
              value: lettering.lettering,
              key: lettering.id,
              text: lettering.lettering,
              side: lettering.side,
            }))
          : []
      }
      placeholder="Select a lettering"
    />
  );
};

Dropdown.propTypes = {
  cellData: PropTypes.string,
  currentDocumentLetteringType: PropTypes.string,
  letterings: PropTypes.array,
  rowData: PropTypes.object,
  tableRef: PropTypes.object,
  updateRecord: PropTypes.func,
};

const mapStateToProps = state => ({
  currentDocumentLetteringType:
    state.utils.currentDocument?.Family?.letteringType,
  letterings: state.locationEditor.letterings,
});

const LetteringDropdown =
  process.env.NODE_ENV !== 'test'
    ? connect(mapStateToProps)(Dropdown)
    : Dropdown;

export default LetteringDropdown;
