import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

export const getSemanticGroupSubAssembly = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.Item.getSubAssemblyBySemanticGroup,
    );

    const where = {
      semanticGroup: getState().utils.currentDocument.semanticGroup,
    };

    const semanticGroupSubAssembly = await API.get(url, where);
    dispatch({
      type: types.GET_SUBASSEMBLY_BY_SEMANTIC_GROUP,
      payload: semanticGroupSubAssembly?.length
        ? semanticGroupSubAssembly
        : ['NO DATA'],
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getSemanticGroupLayer = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getLayerBySemanticGroup);

    const where = {
      semanticGroup: getState().utils.currentDocument.semanticGroup,
    };

    const semanticGroupLayer = await API.get(url, where);

    dispatch({
      type: types.GET_LAYER_BY_SEMANTIC_GROUP,
      payload: semanticGroupLayer?.length ? semanticGroupLayer : ['NO DATA'],
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};
