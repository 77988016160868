import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { changeTable } from 'components/table/tableActions';

/**
 * Return Description table
 */
export const getDescriptionTable = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getDescriptions);
    const DocumentId = getState().utils.currentDocument.id;
    const where = {
      DocumentId,
      type: getState().descriptionTable.type,
    };

    const descriptions = DocumentId ? await API.get(url, where) : [];
    dispatch({
      type: types.DESCRIPTION_TABLE_GET_DATA,
      payload: descriptions.length ? descriptions : ['NO DATA'],
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Toggle description table type between descriptionDoc and descriptionShort
 */
export const toggleDescriptionTableType = (sync = true) => (
  dispatch,
  getState,
) => {
  dispatch(changeTable('description'));
  dispatch({
    type: sync ? types.TABLE_TOGGLE_TYPE : types.TABLE_TOGGLE_TYPE_NOT_SYNC,
    table: 'description',
    payload:
      getState().descriptionTable.type === 'descriptionShort'
        ? 'descriptionDoc'
        : 'descriptionShort',
  });
};
