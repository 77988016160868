import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  scene: null,
  document: [],
  selected: [],
  LayerId: null,
  currentArea: '',
  areas: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ENGINE_LOAD_SCENE:
        draft.scene = action.payload.scene;
        draft.currentArea = action.payload.currentArea;
        draft.areas = action.payload.areas;
        break;

      case types.ENGINE_LOAD_DOCUMENT:
        draft.document = action.payload.document;
        break;

      case types.ENGINE_SELECT_AREA:
        draft.currentArea = action.payload;
        break;

      case types.ENGINE_SELECT:
        draft.selected = action.payload;
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.scene = initialState.scene;
        draft.currentArea = initialState.currentArea;
        draft.areas = initialState.areas;
        draft.document = initialState.document;
        draft.selected = initialState.selected;
        break;

      case types.CHANGE_CURRENT_LOPA:
        draft.scene = initialState.scene;
        draft.selected = initialState.selected;
        break;

      default:
        break;
    }
  });
