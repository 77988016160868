import { forbidden, gears, checkmark, enter, nuke } from 'config/images';
import { isUlteriorStep } from 'lib/utils';

/**
 * Return true if step will be reset if stepToReset is reseted
 * @param {object} stepToReset Step record that will be reset
 * @param {object} step other Step record
 */
export const willStepBeReset = (stepToReset, step) => {
  const stepStatus = step?.progress?.status;
  const stepToResetStatus = stepToReset?.progress?.status;

  const shouldStepBeReset = !!step?.progress?.current?.progress;
  const canStepBeReset = !!step?.reset?.action;
  const isStepAvailable =
    stepStatus !== 'restricted' && stepStatus !== 'in_development';
  const isStepToResetAvailable =
    stepToResetStatus !== 'restricted' &&
    stepToResetStatus !== 'in_development';

  if (
    step &&
    stepToReset &&
    isStepToResetAvailable &&
    isStepAvailable &&
    isUlteriorStep(stepToReset, step) &&
    (stepToReset.orderInStep === 0 || canStepBeReset || shouldStepBeReset)
  )
    return true;
  return false;
};

/**
 * Return the icon to display depending on the hovered step
 * @param {object} step other Step record
 * @param {object} hoveredStep Step record that is hovered
 */
export const pickIcon = (step, hoveredStep) => {
  if (willStepBeReset(hoveredStep, step)) return { source: nuke, circle: true };

  const stepStatus = step.progress?.status;
  if (stepStatus === 'workable') return { source: enter };
  if (stepStatus === 'done') return { source: checkmark };
  if (stepStatus === 'restricted') return { source: forbidden };
  return { source: gears };
};

export const visibleState = (visible, steps, hoveredStep) => {
  return {
    ...steps.reduce((acc, step) => {
      acc[step.name] =
        step && willStepBeReset(hoveredStep, step)
          ? !visible[step.name]
          : visible[step.name];
      return acc;
    }, {}),
  };
};

export const routeStep = (step, history) => {
  step.action && history.push(step.action);
};
