import React from 'react';
import { Image, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './style.css';

export default ({
  id,
  text,
  icon,
  active,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
  to = '',
}) => {
  const TabButton = (
    <Button
      className={`tab_button ${active ? 'active' : ''}`}
      id={id}
      disabled={disabled}
      onClick={!to ? onClick : null}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
    >
      <span style={styles.capitalize} title={text.toUpperCase()}>
        {text}
      </span>
      {icon && icon.source && (
        <Image className="icon" src={icon.source} circular={icon.circle} />
      )}
    </Button>
  );
  return to && !disabled ? <Link to={to}>{TabButton}</Link> : TabButton;
};

const styles = {
  capitalize: {
    textTransform: 'capitalize',
  },
};
