import React, { Component } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import history from 'config/history';

import connect from 'lib/reduxConnect';
import { makeNavInfo } from 'config/navigation';
import { color } from 'config/constants';

import {
  DescriptionsTable,
  DescriptionsTableHeader,
} from './tables/description/DescriptionsTable';
import { ItemTable, ItemTableHeader } from './tables/item/ItemTable';
import { FigureTable } from './tables/figure/FigureTable';
import { EquipmentTable } from './tables/equipment/EquipmentTable';
import { PageTable } from './tables/page/PageTable';
import {
  RepresentationTable,
  RepresentationTableHeader,
} from './tables/representation/RepresentationTable';
import Line from './tables/line/LineTable';

export const tables = [
  {
    name: 'page',
    Component: PageTable,
    counts: [{ table: 'XmlPage', name: 'Page' }],
  },
  {
    name: 'line',
    Component: Line,
    counts: [{ table: 'XmlText', endpoint: 'getLineCount', name: 'Line' }],
  },
  {
    name: 'figure',
    Component: FigureTable,
    counts: ['Figure'],
  },
  {
    name: 'item',
    Header: ItemTableHeader,
    Component: ItemTable,
    counts: ['Item', 'Remark'],
  },
  {
    name: 'representation',
    Header: RepresentationTableHeader,
    Component: RepresentationTable,
    counts: [{ table: 'ItemCoordinate', name: 'Representation' }],
  },
  {
    name: 'description',
    Header: DescriptionsTableHeader,
    Component: DescriptionsTable,
    counts: [
      {
        table: 'Item',
        endpoint: 'getDescriptionDocCount',
        name: 'Description Doc',
      },
      {
        table: 'Item',
        endpoint: 'getDescriptionShortCount',
        name: 'Description Short',
      },
    ],
  },
  {
    name: 'equipment',
    Component: EquipmentTable,
    counts: [{ table: 'Equipment', name: 'Equipments' }],
  },
];

class TablesContent extends Component {
  prefix = this.props.match.url;

  navigationInfo = tables.map(table =>
    makeNavInfo(table.name, `${this.prefix}/${table.name}`, table.Component),
  );

  componentDidMount() {
    const currentPath = this.props.location.pathname.split('/').pop();

    const hasPathChanged =
      !this.props.modal &&
      currentPath !== 'tables' &&
      currentPath !== this.props.type;
    if (hasPathChanged) this.props.actions.changeTable(currentPath, false);

    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;

    if (!isUserConnected) return;

    this.props.actions.getAnomaliesForDocument(this.props.currentDocumentId);
  }

  componentDidUpdate(prevProps) {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;

    if (!isUserConnected) return;

    const hasAnomaly =
      this.props.anomalies ||
      (this.props.anomalies && this.props.anomalies.length);

    if (!hasAnomaly)
      this.props.actions.getAnomaliesForDocument(this.props.currentDocumentId);

    const hasTypeChanged = this.props.type !== prevProps.type;
    if (hasTypeChanged) history.push(this.props.type);

    const currentPath = this.props.location.pathname.split('/').pop();
    const hasPathChanged =
      !hasTypeChanged &&
      !this.props.modal &&
      currentPath !== 'tables' &&
      currentPath !== this.props.type;
    if (hasPathChanged) this.props.actions.changeTable(currentPath, false);
  }

  changeSearch = (e, { value }) => {
    this.props.actions.changeSearch(value, this.props.modal);
  };

  changeSearchColumn = (e, { value }) => {
    this.props.actions.changeSearchColumn(
      value !== 'all' ? value : null,
      this.props.modal,
    );
  };

  render() {
    const { header, modal, type, search, searchColumn } = this.props;
    const searchOptions = header.map(column => ({
      key: column.dataKey,
      text: column.label,
      value: column.searchColumn || column.dataKey,
    }));
    const table = tables.find(table => table.name === type);
    return table ? (
      <div
        style={{
          ...styles.container,
          paddingBottom: modal && '2.5em',
        }}
      >
        <div
          style={{
            ...styles.header,
            background: modal
              ? color.primary
              : `linear-gradient(90deg, ${color.primary}, ${color.hardPrimary}`,
            marginTop: !modal && 5,
          }}
        >
          {table.Header && (
            <div style={styles.specificHeader}>
              <table.Header modal={modal} />
            </div>
          )}
          <Input
            style={{ width: table.Header ? '60%' : '100%' }}
            className="search"
            icon="search"
            placeholder="Search..."
            onChange={this.changeSearch}
            value={search}
            label={
              <Dropdown
                className="margin"
                value={searchColumn || 'all'}
                options={[
                  { key: 'all', text: 'All', value: 'all' },
                  ...searchOptions,
                ]}
                onChange={this.changeSearchColumn}
                style={styles.searchBarOptions}
              />
            }
          />
        </div>
        <Route
          path={this.prefix}
          exact
          render={() => <Redirect to={`${this.prefix}/item`} />}
        />
        {this.props.modal ? (
          <table.Component modal={modal} />
        ) : (
          this.navigationInfo.map(nav => (
            <Route
              key={nav.path}
              exact={nav.options.exact}
              path={nav.path}
              component={nav.component}
            />
          ))
        )}
      </div>
    ) : (
      'LOADING...'
    );
  }
}

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'inherit',
  },
  header: {
    filter: 'drop-shadow(6px 6px 5px rgba(0,0,0,.5))',
    borderRadius: '.28571429rem',
    marginBottom: 10,
    zIndex: 100,
    flexShrink: 1,
    padding: 10,
    display: 'flex',
    color: 'white',
  },
  specificHeader: { marginRight: 5, display: 'flex', width: '40%' },
  searchBarOptions: { display: 'flex', alignItems: 'center' },
};

TablesContent.propTypes = {
  anomalies: PropTypes.array,
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  header: PropTypes.array,
  location: PropTypes.object,
  modal: PropTypes.bool,
  search: PropTypes.string,
  searchColumn: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  header: props.modal ? state.table.modalHeader : state.table.header,
  search: props.modal ? state.table.modalSearch : state.table.search,
  type: props.modal ? state.table.modalType : state.table.type,
  searchColumn: props.modal
    ? state.table.modalSearchColumn
    : state.table.searchColumn,
  anomalies: state.anomalies.currentDocumentAnomalies,
});

export default process.env.NODE_ENV !== 'test'
  ? connect(mapStateToProps, null, true)(TablesContent)
  : TablesContent;
