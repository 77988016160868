const StackTraceParser = require('stacktrace-parser');

/**
 * State sanitizer that can be used in composeWithDevTools() to restrict redux-devtools to a certain slice of the state
 * Takes an array of keys to whitelist and returns a ready-to-use stateSanitizer that you can pass to composeWithDevTools()
 * Usage example :
 * composeWithDevTools({ stateSanitizer: whiteListSanitizer(['request']) })
 */
export const whiteListSanitizer = keysWhitelist => state =>
  Object.keys(state).reduce((filteredState, key) => {
    if (keysWhitelist.includes(key)) {
      return { ...filteredState, [key]: state[key] };
    }
    return filteredState;
  }, {});

/**
 * Same as whiteListSanitizer but takes a detailed state slice as argument
 * all keys with a value which is different from undefined, null, or false, will be kept in output
 * For instance if the state structure is :
 * { foo: { foobar: { toto: 'a', tata: 'b' } }, bar : 'something' }
 * Filtered with the following whitelist structure :
 * { foo: { foobar: { tata: true} } }
 * Will output :
 * { foo: { foobar: { tata: 'b' } } }
 */
export const advancedWhiteListSanitizer = objWhiteList => state =>
  Object.keys(state).reduce((filteredState, key) => {
    if (objWhiteList[key] && objWhiteList[key] != null) {
      if (typeof objWhiteList[key] === 'object') {
        return {
          ...filteredState,
          [key]: advancedWhiteListSanitizer(objWhiteList[key])(state[key]),
        };
      }
      return { ...filteredState, [key]: state[key] };
    }
    return filteredState;
  }, {});

/**
 * Custom middleware to attach the location of the dispatch call to actions
 * Only available in development mode
 * The line and file where dispatch was called is available in the action object under the __debug__ key
 * Totally hacky to find the relevant line in the stacktrace
 */
export const whereWasItDispatched = () => next => action => {
  if (process.env.NODE_ENV !== 'development') {
    next(action);
  } else {
    const e = new Error();
    const parsedStack = StackTraceParser.parse(e.stack);
    let [, , , actualLine] = parsedStack; // That's where the call occurs in action creators
    if (actualLine.methodName.includes('Object.')) {
      // if there's a method name here, it's been dispatched as a container action
      [, , , , actualLine] = parsedStack; // Then the relevant line is #4
    }
    next({ ...action, __debug__: { dispatched_at: actualLine } });
  }
};
