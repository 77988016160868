import React from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Labels = React.memo(({ itemRepresentations, actions }) => {
  return (
    itemRepresentations && (
      <div
        style={styles.ellipsis}
        title={`•   ${itemRepresentations
          .map(item => item.text)
          .join('\n•   ')}`}
      >
        {itemRepresentations.map(item => (
          <Label
            key={item.id}
            size="tiny"
            style={styles.pointer}
            color="green"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              actions.viewerGoToPage(item.documentPage, [item.id]);
            }}
          >
            {item.text}
          </Label>
        ))}
      </div>
    )
  );
});

const ItemRepresentations =
  process.env.NODE_ENV !== 'test' ? connect()(Labels) : Labels;

const styles = {
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pointer: {
    cursor: 'pointer',
  },
};

Labels.propTypes = {
  itemRepresentations: PropTypes.array,
};

export default ItemRepresentations;
