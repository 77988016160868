/**
 * This is an unversioned file designed to allow devs to customize their debugging experience
 *
 * Change the value of default export with a custom implementation if you wish.
 * By default it comes with a sanitizer taking a whitelist of keys to keep in the state.
 * If you write a new sanitizer please version it in debugUtils to allow others to benefit from it.
 *
 * To debug the whole state use
 * export default state => state;
 */
import { whiteListSanitizer } from 'lib/debugUtils';

export default whiteListSanitizer([]);
