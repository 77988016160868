import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';
import IdButton from './IdButton';

const Button = React.memo(({ id, disabled, value, actions }) =>
  id ? (
    <IdButton table="LocationGeneric" id={id} />
  ) : (
    <SemanticButton
      circular
      disabled={disabled}
      color="red"
      size="mini"
      icon="trash alternate"
      onClick={() => actions.deleteLocationEditorRecord(value)}
    />
  ),
);

const IdButtonLocationEditor =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

Button.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number,
  value: PropTypes.string,
};

export default IdButtonLocationEditor;
