import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  stepProgress: null,
  resetInfo: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.GET_STEPS_PROGRESS:
        draft.stepProgress = action.payload.stepProgress;
        break;

      case types.GET_STEP_RESET_INFO:
        draft.resetInfo = action.payload.resetInfo;
        break;

      default:
        break;
    }
  });
