import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  toastIds: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SNACKBAR_ENQUEUE:
        draft.toastIds.push(action.notification.options.toastId);
        break;

      case types.SNACKBAR_CLOSE:
        draft.toastIds = state.toastIds.filter(
          toastId => toastId !== action.toastId,
        );
        break;

      default:
        break;
    }
  });
