export const checkmark =
  'https://storage.googleapis.com/ais_cdn/icons/green_checkmark.png';
export const gears = 'https://storage.googleapis.com/ais_cdn/icons/gears.gif';
export const forbidden =
  'https://storage.googleapis.com/ais_cdn/icons/forbidden.png';
export const enter = 'https://storage.googleapis.com/ais_cdn/icons/enter.png';
export const nuke = 'https://storage.googleapis.com/ais_cdn/icons/nuke.png';
export const info = 'https://storage.googleapis.com/ais_cdn/icons/info.png';
export const ohno = 'https://storage.googleapis.com/ais_cdn/icons/oh_no.png';
export const exclamation =
  'https://storage.googleapis.com/ais_cdn/icons/exclamation.png';
export const old = 'https://storage.googleapis.com/ais_cdn/icons/old.png';
