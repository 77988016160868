import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  currentDocumentNotifications: [],
  userNotifications: [],
  showClosedNotifications: false,
  activePage: 1,
  maxPaginationPerCategory: 100,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.NOTIFICATIONS_GET_FOR_DOCUMENT:
        draft.currentDocumentNotifications = action.payload.notifications;
        break;

      case types.NOTIFICATIONS_GET_FOR_USER:
        draft.userNotifications = action.payload.notifications;
        break;

      case types.NOTIFICATIONS_NEW:
        if (action.payload.UserId) {
          draft.userNotifications = [
            ...state.userNotifications,
            action.payload,
          ];
        } else {
          draft.currentDocumentNotifications = [
            ...state.currentDocumentNotifications,
            action.payload,
          ];
        }
        break;

      case types.NOTIFICATIONS_TOGGLE_SHOW_CLOSED:
        draft.showClosedNotifications = !state.currentDocumentNotifications;
        break;

      case types.NOTIFICATIONS_UPDATE_PAGINATION:
        draft.activePage = action.payload.activePage;
        break;

      case types.BATCH_UPDATE: {
        if (action.table === 'Notification') {
          const updatedDocumentNotifications = state.currentDocumentNotifications.map(
            notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, ...action.payload.values }
                : notification,
          );
          const updatedUserNotifications = state.userNotifications.map(
            notification =>
              action.payload.ids.includes(notification.id)
                ? { ...notification, ...action.payload.values }
                : notification,
          );
          draft.currentDocumentNotifications = updatedDocumentNotifications;
          draft.userNotifications = updatedUserNotifications;
        }
        break;
      }

      default:
        break;
    }
  });
