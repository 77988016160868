import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  open: false,
  path: '',
  title: null,
  resets: [],
  progress: {
    total: 0,
    value: 0,
    recap: {},
    running: false,
    percentage: false,
  },
  check: false,
  canClose: true,
  error: '',
  errorMessage: '',
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.UPDATE_STEP_PROGRESS:
        if (action.payload.data.severity === 'Error') {
          draft.error = action.payload.data.step;
          draft.canClose = true;
          if (action.payload.data.message)
            draft.errorMessage = action.payload.data.message;
          break;
        }

        if (action.payload.data.step === 'process') {
          if (action.payload.data.progress === 'start') {
            draft.open = true;
            draft.canClose = false;
            if (state.open && !action.payload.data.overwrite) return;
            draft.title = action.payload.routingKey;
            draft.path = action.payload.data.message;
            draft.resets = initialState.resets;
            draft.progress = initialState.progress;
            draft.check = initialState.check;
            draft.error = initialState.error;
            draft.errorMessage = initialState.errorMessage;
          }

          if (action.payload.data.progress === 'end') {
            draft.progress.running = false;
            draft.canClose = true;
          }
        }

        if (action.payload.data.step === 'reset') {
          if (action.payload.data.progress === 'start') {
            draft.resets = [
              ...state.resets,
              {
                title: action.payload.routingKey,
                label: action.payload.data.label,
                running: true,
                recap: [],
                current: '',
              },
            ];
          }

          if (action.payload.data.progress === 'current') {
            const resetIndex = state.resets.findIndex(
              reset => reset.title === action.payload.routingKey,
            );
            if (resetIndex !== -1) {
              draft.resets[resetIndex].current = action.payload.data.message;
              break;
            }
            draft.resets = [
              ...state.resets,
              {
                title: action.payload.routingKey,
                running: true,
                recap: [],
                current: action.payload.data.message,
              },
            ];
          }

          if (action.payload.data.progress === 'step') {
            const resetIndex = state.resets.findIndex(
              reset => reset.title === action.payload.routingKey,
            );
            const numbers = action.payload.data.message.match(/(\d+)/);
            if (numbers && numbers[0] && parseInt(numbers[0], 10) === 0) return;

            if (resetIndex !== -1) {
              draft.resets[resetIndex].current = '';
              draft.resets[resetIndex].recap = [
                ...draft.resets[resetIndex].recap,
                action.payload.data.message,
              ];
            }
          }

          if (action.payload.data.progress === 'end') {
            const resetIndex = state.resets.findIndex(
              reset => reset.title === action.payload.routingKey,
            );
            if (resetIndex !== -1) {
              draft.resets[resetIndex].running = false;
            }
          }
        }

        if (action.payload.data.step === 'apply') {
          if (action.payload.data.progress === 'start') {
            draft.progress.running = true;
            draft.progress.total = action.payload.data.message;
            draft.progress.percentage = action.payload.data.percentage || false;
          }

          if (typeof action.payload.data.progress === 'number') {
            draft.progress.value = action.payload.data.progress;
            draft.progress.recap = action.payload.data.message;
          }
        }

        if (action.payload.data.step === 'check') {
          if (action.payload.data.progress === 'start') {
            draft.check = true;
            if (state.progress.total === 0) {
              draft.progress.total = -1;
              draft.progress.value = -1;
            }
          }

          if (action.payload.data.progress === 'end') {
            draft.progress.recap = action.payload.data.message;
            draft.check = false;
          }
        }
        break;

      case types.CLOSE_STEP_PROGRESS:
        draft.open = false;
        draft.path = initialState.path;
        draft.title = initialState.title;
        draft.resets = initialState.resets;
        draft.progress = initialState.progress;
        draft.check = initialState.check;
        draft.canClose = initialState.canClose;
        draft.error = initialState.error;
        draft.errorMessage = initialState.errorMessage;
        break;

      default:
        break;
    }
  });
