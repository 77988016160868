import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';
import history from 'config/history';
import {
  startStep,
  endStep,
  resetProgressForStep,
} from 'integration/integrationNavigationActions';
import { updateRecord } from 'components/recordEditor/recordEditorActions';
import {
  changeCurrentDocument,
  getTable,
  updateDocumentStatus,
} from 'components/utils/utilsActions';
import {
  startInitialDocumentProcess,
  uploadDocument,
  shortenPDF,
  getExistingDocuments,
  copyPdfToDocumentFolder,
} from 'components/files/filesActions';
import { enqueueSnackbar } from 'components/snackbar/snackbarActions';

/**
 * handles the updating the Document on user returning to the step
 * @param {*} _form content from the document update inputs
 * @param {*} isDuplicate if the user creates by duplicating an existing document
 * @param {*} newFile the actual file if upload from user
 */

export const createDocument = (
  _form,
  newFile = null,
  isDuplicate = false,
) => async (dispatch, getState) => {
  const form = { ..._form };
  for (let [key, value] of Object.entries(form)) {
    if (value === '') delete form[key];
  }

  if (isDuplicate) {
    delete form.id;
  }

  form.settings = {
    shortened: !!form.minPage || !!form.maxPage,
    minPage: form.minPage || 1,
    maxPage: form.maxPage || -1,
  };
  delete form.minPage;
  delete form.maxPage;

  try {
    const url = API.buildURL({
      table: 'Document',
      function: 'create',
    });

    const create = await API.post(url, form);

    if (!create || create.id === undefined) return null;

    await dispatch(startStep(create.id, ['Import and initial settings']));

    dispatch(
      enqueueSnackbar({
        message: `<div>
            Document <b>${create.id}</b> created
          </div>`,
        options: {
          variant: 'success',
        },
      }),
    );
    dispatch({
      type: types.CREATE_RECORD,
      table: 'Document',
      payload: create,
    });
    dispatch(changeCurrentDocument(create));
    const success = await uploadAndProcessDocument(
      form,
      create.id,
      dispatch,
      newFile,
    );
    if (success) {
      await dispatch(endStep(create.id, ['Import and initial settings']));
      await dispatch(startStep(create.id, [getState().section.step]));
      history.push('/integration');
    }
    dispatch(getTable('Document'));
    dispatch({ type: types.DOCUMENT_CREATION_CLOSE });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * handles the updating the Document on user returning to the step
 * @param {*} _form content from the document update inputs
 * @param {*} currentDocument current state of the document
 */

export const updateDocument = (_form, currentDocument) => async dispatch => {
  const form = { ..._form };
  for (let [key, value] of Object.entries(form)) {
    if (value === '') delete form[key];
  }

  form.settings = {
    ...currentDocument.settings,
    shortened: !!form.minPage || !!form.maxPage,
    minPage: form.minPage || 1,
    maxPage: form.maxPage || -1,
  };
  delete form.minPage;
  delete form.maxPage;

  const isShorteningUpdated =
    (currentDocument.settings &&
      currentDocument.settings.minPage !== form.settings.minPage) ||
    (currentDocument.settings &&
      currentDocument.settings.maxPage !== form.settings.maxPage) ||
    ((!currentDocument.settings || !currentDocument.settings.minPage) &&
      form.settings.minPage) ||
    ((!currentDocument.settings || !currentDocument.settings.maxPage) &&
      form.settings.maxPage);

  try {
    const success = await dispatch(updateRecord('Document', form));
    if (success === false) return;

    /* 
       no need to 
       for other changes in form, they don't impact anything
       as of now
    */
    if (isShorteningUpdated) {
      await dispatch(
        resetProgressForStep('Import and initial settings', form.id),
      );
      await uploadAndProcessDocument(form, form.id, dispatch);
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * handles the .pdf related processing
 * upload pdf if needed,
 * then triggers shortening and json extraction
 * @param {*} form content from the document creation inputs
 * @param {*} DocumentId the related DocumentId
 * @param {*} newFile the actual file if upload from user
 */
const uploadAndProcessDocument = async (
  form,
  DocumentId,
  dispatch,
  newFile = null,
) => {
  try {
    if (newFile) await uploadDocument(newFile, DocumentId, dispatch);
    else await copyPdfToDocumentFolder(DocumentId, form.pdfFile);

    let useShorterDoc = false;
    if (form.settings.minPage || form.settings.maxPage) {
      useShorterDoc = true;
      await shortenPDF(
        form.pdfFile,
        form.settings.minPage,
        form.settings.maxPage,
        DocumentId,
      );
    }
    const process = await startInitialDocumentProcess(
      form.pdfFile,
      DocumentId,
      useShorterDoc,
    );
    if (process.success) {
      dispatch(
        enqueueSnackbar({
          message: `<div>
            Document <b>${DocumentId}</b> imported
          </div>`,
          options: {
            variant: 'success',
          },
        }),
      );
      dispatch(updateDocumentStatus(DocumentId, 'On Hold'));
    }
    dispatch(getTable('Document'));
    return true;
  } catch (err) {
    history.push('/documentImport');
    return false;
  }
};

/**
 * will delete the current document
 * use sparingly
 */
export const deleteDocument = () => async (dispatch, getState) => {
  try {
    const DocumentId = getState().utils.currentDocument.id;
    const url = API.buildURL(network.ENDPOINTS.files.deleteDocument);
    const result = await API.DELETE(url, {
      DocumentId,
    });

    if (result && result.success) {
      history.push('/');
      dispatch(changeCurrentDocument({}));
      dispatch(getExistingDocuments());
      dispatch(getTable('Document'));
      enqueueSnackbar({
        message: `<div>
            Document <b>${DocumentId}</b> deleted
          </div>`,
        options: {
          variant: 'success',
        },
      });
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Open the document creation form
 * @param {*} isUpdate indicates editing the step
 */
export const openDocumentCreation = (isUpdate = false) => async dispatch => {
  try {
    dispatch(getTable('Document'));
    dispatch({
      type: types.DOCUMENT_CREATION_OPEN,
      payload: {
        open: !isUpdate,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const closeDocument = (isUpdate = false) => (dispatch, getState) => {
  if (getState().files.uploading) return;
  dispatch({
    type: types.DOCUMENT_CREATION_CLOSE,
  });
  if (isUpdate) history.push('/integration');
};
