import produce from 'immer';
import { getFirstFigure } from 'lib/utils';

import * as types from 'config/types';

const initialState = {
  milestone: 'Subassemblies',
  step: 'Item settings',
  figureFilter: null,
  highlight: [],
  scrollTo: null,
  selected: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_FIGURE_FILTER:
        if (action.table === 'itemSettings') {
          draft.figureFilter = action.payload;
          draft.selected = initialState.selected;
        }
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.figureFilter = initialState.figureFilter;
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        draft.selected = initialState.selected;
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'itemSettings') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.GET_TABLE:
        if (action.table === 'Figure' && !state.figureFilter) {
          draft.figureFilter = getFirstFigure(
            action.payload,
            state.figureFilter,
          );
        }
        break;

      case types.SELECT_TABLE:
        if (action.table === 'itemSettings')
          draft.selected = action.payload.selected;
        break;

      default:
        break;
    }
  });
