import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Button = React.memo(({ documentPage, XmlTextIds, text, actions }) => (
  <SemanticButton
    compact
    circular
    color="grey"
    size="tiny"
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();
      actions.viewerGoToPage(documentPage, XmlTextIds);
    }}
  >
    {text}
  </SemanticButton>
));

const DocumentLineButton =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

Button.propTypes = {
  documentPage: PropTypes.number,
  text: PropTypes.number,
  XmlTextIds: PropTypes.array,
};

export default DocumentLineButton;
