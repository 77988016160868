import React, { Component } from 'react';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Table from 'react-virtualized/dist/commonjs/Table';
import Column from 'react-virtualized/dist/commonjs/Table/Column';
import PropTypes from 'prop-types';
import { Icon, Popup, Button } from 'semantic-ui-react';
import { difference, toPairs } from 'lodash';
import classnames from 'classnames';
import { groupBy, sortByFields, resolvePath } from 'ais-utils';
import equal from 'deep-equal';

import connect from 'lib/reduxConnect';
import { floatify } from 'lib/utils';

import { rowRenderer } from './containers/rowRenderer';
import {
  noDataRenderer,
  loadingRenderer,
} from 'components/table/containers/columnRenderers';

import {
  isGroupHeader,
  DEFAULT_ROW_HEIGHT,
  HEADER_HEIGHT,
  ROW_GAP,
  GROUP_HEADER_ROW_HEIGHT,
  GROUP_HEADER_HEIGHT_DIFF,
} from './utils';

import './style.css';
import ContextMenu from './containers/SettingsMenu';
import GroupHeaderRow from './containers/GroupHeaderRow';
import ScrollToTop from 'components/utils/ScrollToTop';

class M3dianTable extends Component {
  static defaultProps = {
    highlight: [],
    sortBy: [],
    selected: [],
    selectable: false,
    anomalies: [],
    multiple: true,
    isSortDisabled: false,
    modal: false,
    toggleExtend: true,
    isExtendEnable: true,
    isDefaultOpen: false,
    isGroupByEnable: false,
    groupedBy: false,
    groupHeaderStyle: {},
    getRowStyle: () => {},
    style: {},
  };

  state = {
    data: this.props.data,
    selectedIndex: [],
    shadowSelected: [],
    selectAnchor: null,
    shiftSelected: [],
    sortBy: this.initSortBy(),
    extendedRows: [],
    contextMenu: false,
    groupByKey: false,
    sticky: null,
    scrollTop: 0,
    startIndex: 0,
    stopIndex: 0,
  };

  extendedRowHashes = [];

  tableRef = React.createRef();
  contextRef = React.createRef();

  initSortBy() {
    return this.props.sortBy.reduce(
      (output, dataKey) => ({ ...output, [dataKey]: null }),
      {},
    );
  }

  componentDidMount() {
    // Add click handler for settings icon
    this.header = document.querySelector(
      `.${this.props.name} > .ReactVirtualized__Table__headerRow`,
    );
    if (this.header)
      this.header.addEventListener('click', this.onSettingsClick);

    const { tableRef } = this.props;
    if (tableRef) tableRef(this.tableRef);

    window.addEventListener('keydown', this.onKeyPress);
    window.addEventListener('keyup', this.onKeyPress);

    const { data } = this.props;
    if (data?.length) this.setData();
  }

  componentWillUnmount() {
    if (this.header)
      this.header.removeEventListener('click', this.onSettingsClick);

    const { tableRef } = this.props;
    if (tableRef) tableRef(undefined);

    window.removeEventListener('keydown', this.onKeyPress);
    window.removeEventListener('keyup', this.onKeyPress);
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollTo, isGroupByEnable, groupedBy } = this.props;

    const hasDataChanged = !equal(prevProps.data, this.props.data);
    const hasSelectedChanged = !equal(prevProps.selected, this.props.selected);
    const hasScrollToChanged = prevProps.scrollTo !== scrollTo && scrollTo;

    const hasExtendRowsChanged = difference(
      this.state.extendedRows,
      prevState.extendedRows,
    );
    const hasGroupByChanged = this.state.groupByKey !== prevState.groupByKey;

    if (hasExtendRowsChanged && this.tableRef.current)
      this.tableRef.current.recomputeRowHeights();

    if (hasDataChanged) this.setData();

    if (!hasDataChanged && hasSelectedChanged) {
      const selectedIndex = this.props.selected
        .map(value => this.getIndex(this.state.data, value))
        .filter(index => index >= 0);

      this.setState({
        selectedIndex,
      });
    }

    if (hasScrollToChanged)
      this.scrollToIndex(this.state.data, this.props.scrollTo);

    if (isGroupByEnable && !groupedBy && hasGroupByChanged) {
      let { data } = this.props;
      if (this.state.groupByKey)
        data = this.groupData(data, this.state.groupByKey);

      this.updateExtendedIndexes(data);
      this.resetSelect();
      this.setState({
        shadowSelected: [],
        selectAnchor: null,
        shiftSelected: [],
        data,
      });
      this.onRowsRendered({
        startIndex: this.state.startIndex,
        stopIndex: this.state.stopIndex,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isPropsEqual = equal(nextProps, this.props);

    const isDataEqual = equal(nextState.data, this.state.data);
    const isShadowSelectedEqual = equal(
      nextState.shadowSelected,
      this.state.shadowSelected,
    );
    const isSelectAnchorEqual =
      nextState.selectAnchor === this.state.selectAnchor;
    const isShiftSelectedEqual = equal(
      nextState.shiftSelected,
      this.state.shiftSelected,
    );
    const isExtendedRowsEqual = equal(
      nextState.extendedRows,
      this.state.extendedRows,
    );
    const isSortByEqual = equal(nextState.sortBy, this.state.sortBy);
    const isContextMenuEqual = nextState.contextMenu === this.state.contextMenu;
    const isStickyEqual = nextState.sticky === this.state.sticky;
    const isHeightEqual = nextState.heigh === this.state.height;
    const isWidthEqual = nextState.width === this.state.width;

    if (
      isPropsEqual &&
      isDataEqual &&
      isShadowSelectedEqual &&
      isSelectAnchorEqual &&
      isShiftSelectedEqual &&
      isExtendedRowsEqual &&
      isSortByEqual &&
      isContextMenuEqual &&
      isStickyEqual &&
      isHeightEqual &&
      isWidthEqual
    )
      return false;
    return true;
  }

  onKeyPress = event => {
    // Prevent text selection when selecting rows with shift key pressed
    document.onselectstart = () => {
      return event.type !== 'keydown' && !event.shiftKey;
    };
  };

  setData = () => {
    const {
      data,
      scrollTo,
      isDefaultOpen,
      isGroupByEnable,
      groupedBy,
      isRowExtendable,
    } = this.props;

    let sortedData = sortByFields(data, ...toPairs(this.state.sortBy), false);
    const selectedIndex = this.props.selected
      .map(value => this.getIndex(sortedData, value))
      .filter(index => index >= 0);
    if (isGroupByEnable && !groupedBy && this.state.groupByKey)
      sortedData = this.groupData(sortedData, this.state.groupByKey);

    this.updateExtendedIndexes(sortedData);
    this.setState({
      shadowSelected: [],
      selectAnchor: Math.min(...selectedIndex) || null,
      shiftSelected: this.state.shiftSelected.filter(shiftSelected =>
        data.some(datum => this.getSelectableValue(datum) === shiftSelected),
      ),
      data: sortedData,
      selectedIndex,
    });
    this.onRowsRendered({
      startIndex: this.state.startIndex,
      stopIndex: this.state.stopIndex,
    });
    if (scrollTo) {
      this.scrollToIndex(sortedData, this.props.scrollTo);
    }

    if (isDefaultOpen && isRowExtendable) {
      this.setState({
        extendedRows: sortedData
          .map((datum, index) => isRowExtendable(datum) && index)
          .filter(data => data !== false),
      });
    }
  };

  groupData = (data, dataKey) =>
    Object.entries(groupBy(data, rowData => resolvePath(rowData, dataKey)))
      .flat(2)
      // Prevent group by non existing field
      .filter(datum => datum !== 'undefined');

  getIndex = (data, scrollTo) => {
    return data.findIndex(row => {
      if (typeof scrollTo === 'number') return row.id === scrollTo;
      if (typeof scrollTo === 'string')
        return row.id === parseInt(scrollTo, 10);
      return Object.keys(scrollTo).reduce(
        (acc, key) => acc && row[key] === scrollTo[key],
        true,
      );
    });
  };

  getIndexOffset = index => {
    const offset = this.tableRef.current.getOffsetForRow({
      alignment: 'start',
      index,
    });
    // Prevent to be hidden by a sticky group header
    const scrollTop = this.state.sticky
      ? offset - GROUP_HEADER_ROW_HEIGHT - GROUP_HEADER_HEIGHT_DIFF
      : offset;

    return scrollTop;
  };

  scrollToIndex = (data, scrollTo) => {
    try {
      const index = this.getIndex(data, scrollTo);

      if (index === -1) return;

      setTimeout(() => {
        const scrollTop = this.getIndexOffset(index);

        this.setState({
          scrollTop,
        });
        // Timeout to make sure the table is loaded and we get the good offset
      }, 50);
    } catch (err) {
      console.error(err);
    }
  };

  scrollToSelectedAbove = () => {
    const selectedAbove = this.state.selectedIndex.filter(
      index => index < this.state.startIndex,
    );

    // Scroll to the first hidden above
    const lastHidden = Math.max(...selectedAbove);
    if (Number.isNaN(lastHidden)) return;

    const scrollTop = this.getIndexOffset(lastHidden);
    this.setState({
      scrollTop,
    });
  };

  scrollToSelectedBellow = () => {
    const selectedBellow = this.state.selectedIndex.filter(
      index => index > this.state.stopIndex,
    );

    // Scroll to the first hidden bellow
    const firstHidden = Math.min(...selectedBellow);
    if (Number.isNaN(firstHidden)) return;

    const scrollTop = this.getIndexOffset(firstHidden);
    this.setState({
      scrollTop,
    });
  };

  sort = ({ event, sortBy }) => {
    if (event.altKey) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    const sortDirection = {
      ...this.state.sortBy,
      ...{
        [sortBy]:
          this.state.sortBy[sortBy] === null
            ? 'ASC'
            : this.state.sortBy[sortBy] === 'ASC'
            ? 'DESC'
            : null,
      },
    };

    const data = Object.values(sortDirection).filter(Boolean).length
      ? sortByFields(this.state.data, ...toPairs(sortDirection), false)
      : this.props.data;

    this.updateExtendedIndexes(data);

    const selectedIndex = this.props.selected
      .map(value => this.getIndex(data, value))
      .filter(index => index >= 0);

    this.setState({
      sortBy: sortDirection,
      data,
      shadowSelected: [],
      selectAnchor: Math.min(...selectedIndex) || null,
      shiftSelected: this.state.shiftSelected.filter(shiftSelected =>
        data.some(datum => this.getSelectableValue(datum) === shiftSelected),
      ),
    });
  };

  headerRenderer = ({ label, dataKey, columnData }) => {
    const { isSortDisabled, groupedBy } = this.props;
    const showSortIndicator = this.state.sortBy[dataKey];
    return (
      <div style={styles.header}>
        <div style={styles.ellipsis} title={(label || dataKey).toUpperCase()}>
          {label || dataKey}
        </div>
        {!isSortDisabled && !groupedBy && !this.state.groupByKey ? (
          showSortIndicator === 'ASC' ? (
            <Icon name="sort up" />
          ) : showSortIndicator === 'DESC' ? (
            <Icon name="sort down" />
          ) : (
            showSortIndicator === null && <Icon name="sort" />
          )
        ) : null}
        {columnData.help ? (
          <Popup
            inverted
            size="mini"
            data-testid="helpMessage"
            basic
            content={columnData.help}
            trigger={
              <Icon
                style={styles.help}
                name="help circle"
                data-testid="helpIcon"
              />
            }
          />
        ) : null}
      </div>
    );
  };

  onHeaderClick = ({ event }) => {
    if (event.altKey) {
      event.preventDefault();
      event.stopPropagation();

      return this.setState({
        shadowSelected: [],
      });
    }
  };

  getSelectableValue = rowData =>
    this.props.selectable === true
      ? rowData.id
      : rowData[this.props.selectable];

  getSelectedIndexes = (selectedValues = this.props.selected) =>
    selectedValues.map(value =>
      this.state.data.findIndex(
        datum => this.getSelectableValue(datum) === value,
      ),
    );

  resetSelect = () => {
    this.props.actions.selectTable(this.props.name);
    this.props.actions.highlightTable(this.props.name);
    this.setState({ selectedIndex: [] });
  };

  onRowClick = ({ event, rowData, index }) => {
    if (this.props.isRowSelectable && !this.props.isRowSelectable(rowData))
      return;

    const {
      onRowClick,
      selectable,
      selected,
      name,
      multiple,
      actions,
    } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (event.altKey) {
      if (this.state.shadowSelected.includes(index))
        return this.setState({
          shadowSelected: this.state.shadowSelected.filter(
            selected => selected !== index,
          ),
        });
      return this.setState({
        shadowSelected: [...this.state.shadowSelected, index],
      });
    }

    if (!selectable) return onRowClick?.(rowData, false);

    const clicked = this.getSelectableValue(rowData);

    // Shift select
    if (multiple && event.shiftKey) {
      const shiftSelected = this.state.data
        .slice(
          ...[
            this.state.selectAnchor > index
              ? this.state.selectAnchor
              : this.state.selectAnchor + 1,
            this.state.selectAnchor > index ? index : index + 1,
          ].sort((a, b) => a - b),
        )
        .filter(row =>
          typeof row === 'string'
            ? false
            : this.props.isRowSelectable
            ? this.props.isRowSelectable(row)
            : true,
        )
        .map(row => this.getSelectableValue(row));

      const newSelect = [
        // Remove previous shift click selected rows
        ...selected.filter(value => !this.state.shiftSelected.includes(value)),
        ...shiftSelected,
      ];
      this.setState({ shiftSelected });

      if (onRowClick) onRowClick(this.state.data[index], newSelect);
      return actions.selectTable(name, newSelect);
    }

    // Select
    if (!selected.includes(clicked)) {
      // Move anchor
      this.setState({ selectAnchor: index, shiftSelected: [] });

      const newSelect =
        multiple && (event.ctrlKey || event.metaKey || multiple === 'always')
          ? [...selected, clicked]
          : [clicked];
      if (onRowClick) onRowClick(this.state.data[index], newSelect);
      return actions.selectTable(name, newSelect);
    }

    // Multi deselect
    if (event.ctrlKey || event.metaKey || multiple === 'always') {
      const newSelect = selected.filter(value => value !== clicked);
      // Move anchor to next selected row or last select row
      let selectAnchor = this.state.data.findIndex((datum, i) => {
        if (i < index) return false;
        return newSelect.includes(this.getSelectableValue(datum));
      });
      selectAnchor =
        selectAnchor === -1
          ? Math.max(...this.getSelectedIndexes(newSelect))
          : selectAnchor;
      this.setState({
        selectAnchor,
        shiftSelected: [],
      });
      // Set new selection
      if (onRowClick)
        onRowClick(this.state.data[selectAnchor] || null, newSelect);
      return actions.selectTable(name, newSelect);
    }

    // Single deselect
    // Move anchor
    const selectAnchor =
      selected.length === 1 && selected[0] === clicked ? null : index;
    this.setState({
      selectAnchor,
      shiftSelected: [],
    });
    // Set new selection
    const newSelect =
      selected.length === 1 && selected[0] === clicked ? [] : [clicked];
    if (onRowClick)
      onRowClick(this.state.data[selectAnchor] || null, newSelect);
    return actions.selectTable(name, newSelect);
  };

  // Toggle display of a row extension
  toggleExtendable = index => {
    // Reset previous extend rows
    if (this.extendedRowHashes.length) this.extendedRowHashes = [];

    let extendedRows = [];

    const isToggleExtendAndDifferentLine =
      this.props.toggleExtend && !this.state.extendedRows.includes(index);

    if (isToggleExtendAndDifferentLine) {
      extendedRows = [index];
    } else {
      extendedRows = this.state.extendedRows.includes(index)
        ? this.state.extendedRows.filter(event => event !== index)
        : [...this.state.extendedRows, index];
    }

    this.setState({
      extendedRows,
    });
  };

  updateExtendedIndexes = nextData => {
    if (this.state.data.length !== 0 && this.state.data[0] !== 'NO DATA')
      this.state.extendedRows.forEach(extendedRow => {
        const rowHash = JSON.stringify(this.state.data[extendedRow]);
        if (!this.extendedRowHashes.includes(rowHash))
          this.extendedRowHashes.push(rowHash);
      });

    const extendedRows = [];
    this.extendedRowHashes.forEach(rowHash => {
      const rowIndex = nextData.findIndex(
        datum => JSON.stringify(datum) === rowHash,
      );
      if (rowIndex !== -1) extendedRows.push(rowIndex);
    });

    this.setState({ extendedRows });
  };

  hasAnomaly = rowData => {
    if (!this.props.table) return false;
    const tableAnomaly = this.props.anomalies.filter(
      anomaly => !anomaly.processed && anomaly.action[this.props.table],
    );

    return tableAnomaly.some(anomaly => {
      const anomalyHasTableId =
        anomaly.action[this.props.table].id &&
        anomaly.action[this.props.table].id === rowData.id;

      const anomalyHasTableIds =
        anomaly.action[this.props.table].ids &&
        anomaly.action[this.props.table].ids === rowData.ids;

      const anomalyHasTableArray =
        Array.isArray(anomaly.action[this.props.table]) &&
        anomaly.action[this.props.table].some(
          record =>
            (record.id && record.id === rowData.id) ||
            (record.ids && record.ids.includes(rowData.id)),
        );

      return anomalyHasTableId || anomalyHasTableIds || anomalyHasTableArray;
    });
  };

  getScrollBarPadding = (tableHeight, availableHeight) => {
    const isScrollBarDisplayed =
      tableHeight && availableHeight
        ? tableHeight > availableHeight - HEADER_HEIGHT - ROW_GAP
        : (this?.tableRef?.current?.state?.scrollbarWidth || 0) !== 0;

    return isScrollBarDisplayed ? 15 : 0;
  };

  rowStyle = ({ index }) => {
    const isHeader = index === -1;

    if (isHeader) {
      return {
        paddingRight: 0,
        ...this.props.headerStyle,
      };
    }
  };

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    rowData,
    style,
  }) => {
    const {
      data,
      selectable,
      extensionRenderer,
      isExtendEnable,
      isRowExtendable,
      groupHeaderRenderer,
      groupHeaderStyle,
    } = this.props;
    const rowStyle = {
      ...style,
      width: style.width && style.width - this.getScrollBarPadding(),
    };

    if (isGroupHeader(rowData)) {
      const isFirstRowAGroupHeader = isGroupHeader(this.state.data[0]);
      const groupIndex = data.findIndex(datum => datum === rowData);
      let groupData = [];
      if (groupHeaderRenderer) {
        const lastIndex = data.findIndex(
          (datum, _index) => _index > groupIndex && typeof datum === 'string',
        );
        groupData = data.slice(
          groupIndex + 1,
          lastIndex !== -1 ? lastIndex : undefined,
        );
      }

      return (
        <GroupHeaderRow
          key={key}
          style={{
            ...rowStyle,
            zIndex: this.state.data.length - (index || 0),
            ...groupHeaderStyle,
          }}
          isFirstRowAGroup={isFirstRowAGroupHeader}
          rowData={rowData}
          scrollTop={this.state.scrollTop}
          groupData={groupData}
          renderer={groupHeaderRenderer}
        />
      );
    }

    const isHighlighted = this.isRowHighlighted(rowData);

    const isSelected = this.isRowSelected(rowData);

    const isShadowSelected = this.isRowShadowSelected(index);

    return rowRenderer({
      className,
      columns,
      index,
      isSelectAnchor: index === this.state.selectAnchor,
      key,
      onRowClick,
      rowData,
      clickable: this.props.isRowSelectable
        ? this.props.isRowSelectable(rowData) &&
          (selectable || this.props.onRowClick)
        : selectable || this.props.onRowClick,
      isSelected,
      isRowSelectable: this.props.isRowSelectable,
      isShadowSelected,
      isHighlighted,
      isGrouped: this.isGroupBy(),
      style: { ...rowStyle, ...(this.props.getRowStyle(rowData) || {}) },
      extensionStyle: this.props.getExtensionStyle?.(rowData) || {},
      isExtended: this.state.extendedRows.includes(index),
      isExtendEnable,
      extensionRenderer,
      toggleExtendable: this.toggleExtendable,
      getRowExtensionHeight: this.getRowExtensionHeight,
      getRowHeight: this.getRowHeight,
      hasAnomaly: this.hasAnomaly(rowData),
      tableSize: this.state.data.length || 0,
      data: this.state.data,
      isRowExtendable,
    });
  };

  isRowHighlighted = rowData =>
    this.props.highlight.some(highlight => {
      if (typeof highlight === 'number') return highlight === rowData.id;
      if (typeof highlight === 'string')
        return parseInt(highlight, 10) === rowData.id;
      return Object.keys(highlight).reduce(
        (acc, key) => acc && rowData[key] === highlight[key],
        true,
      );
    });

  isRowSelected = rowData =>
    (this.props.selectable === true &&
      this.props.selected.includes(rowData.id)) ||
    (this.props.selectable &&
      this.props.selected.includes(rowData[this.props.selectable]));

  isRowShadowSelected = index => this.state.shadowSelected.includes(index);

  dimensions = {
    height: this.props.rowHeight || DEFAULT_ROW_HEIGHT,
    gap: ROW_GAP,
  };

  getRowExtensionHeight = ({ index, width }) => {
    const rowData = this.state.data[index];
    if (this.state.extendedRows.includes(index)) {
      if (rowData && this.props.getRowExtensionHeight)
        return this.props.getRowExtensionHeight(rowData, {
          ...this.dimensions,
          width,
          extension: {
            // minus side paddings
            width: width - 20,
          },
        });
      return this.getRowHeight({ index, width }, false);
    }
    return 0;
  };

  getRowHeight = ({ index, width }, isExtensionInclude = true) => {
    const rowData = this.state.data[index];

    if (isGroupHeader(rowData)) return DEFAULT_ROW_HEIGHT;

    let { height: rowHeight } = this.dimensions;
    if (rowData && this.props.getRowHeight)
      rowHeight = this.props.getRowHeight(rowData, {
        ...this.dimensions,
        width,
      });

    if (!isExtensionInclude) return rowHeight;

    return rowHeight + this.getRowExtensionHeight({ index, width });
  };

  getGridHeight = width => {
    let height = 0;
    this.state.data.forEach((datum, index) => {
      height += this.getRowHeight({
        index,
        width,
      });
    });
    return height;
  };

  rowGetter = ({ index }) => this.state.data[index];

  changeGroupByDataKey = (event, { datakey }) => {
    this.setState({
      contextMenu: false,
      groupByKey: this.state.groupByKey !== datakey && datakey,
    });
  };

  // Open settings menu by icon
  onSettingsClick = event => {
    const hasSettings = this.props.isGroupByEnable;
    if (
      hasSettings &&
      event.offsetX > this.header.offsetWidth - 25 &&
      event.offsetX < this.header.offsetWidth - 5
    ) {
      this.contextRef.current = this.createContextFromEvent(event);
      this.setState({ contextMenu: true });
    }
  };

  // Open settings menu with right click
  // DISABLED
  onContextMenu = event => {
    if (
      !this.props.isGroupByEnable ||
      // Right click only on rows
      event.nativeEvent.path.some(div => div?.className?.includes('header'))
    )
      return;

    event.preventDefault();
    event.persist();
    this.setState({ contextMenu: false }, () => {
      this.contextRef.current = this.createContextFromEvent(event);
      this.setState({ contextMenu: true });
    });
  };

  // Close settings menu
  onContextClose = event => {
    event.stopPropagation();
    this.setState({ contextMenu: false });
  };

  // Create settings menu
  createContextFromEvent = event => {
    const { type } = event;
    const left = event.clientX - (type === 'click' ? 95 : 0);
    const top = event.clientY;
    const right = left + 1;
    const bottom = top + (type === 'click' ? 10 : 1);

    return {
      getBoundingClientRect: () => ({
        left,
        top,
        right,
        bottom,
        height: 0,
        width: 0,
      }),
    };
  };

  // Is current data grouped
  isGroupBy = () => this.state.groupByKey !== false || this.props.groupedBy;

  onScroll = ({ scrollTop }) => {
    this.setState({ scrollTop });
  };

  onRowsRendered = ({ startIndex, stopIndex }) => {
    const data = this.state.data.slice(0, startIndex + 1).reverse();
    const sticky = data.find(isGroupHeader);
    this.setState({
      startIndex: this.state.sticky ? startIndex + 1 : startIndex,
      stopIndex,
      sticky: sticky || null,
    });
  };

  onResize = ({ width, height }) => this.setState({ width, height });

  hasAnomalies = () => this.props.data.some(datum => this.hasAnomaly(datum));

  hasExtendableRows = () =>
    this.props.isExtendEnable &&
    this.props.isRowExtendable &&
    this.props.extensionRenderer &&
    this.props.data.some(datum => this.props.isRowExtendable(datum));

  getRowCount = () =>
    this.state.data.length
      ? this.props.data[0] === 'NO DATA'
        ? 0
        : this.state.data.length
      : 0;

  cellRenderer = (column, { rowWidth, width }) => {
    if (!column.cellRenderer) return ({ cellData }) => cellData;
    return data => {
      const cellRenderer = column.cellRenderer(
        {
          ...data,
          extendedRows: this.state.extendedRows,
          isHighlighted: this.isRowHighlighted(data.rowData),
          isSelected: this.isRowSelected(data.rowData),
          isShadowSelected: this.isRowShadowSelected(data.rowIndex),
        },
        {
          ...this.dimensions,
          height:
            this.getRowHeight(
              {
                index: data.rowIndex,
                width: rowWidth - 20,
              },
              false,
            ) - ROW_GAP,
          width,
          extension: {
            height: this.getRowExtensionHeight({
              index: data.rowIndex,
              width: rowWidth - 20,
            }),
            // minus side paddings
            width: rowWidth - 20,
          },
        },
      );
      if (cellRenderer !== 'LOADING') return cellRenderer;
      return (
        <div style={styles.columnLoading}>
          <Icon loading name="spinner" size="large" />
        </div>
      );
    };
  };

  // See https://github.com/bvaughn/react-virtualized/blob/HEAD/docs/Table.md for Table documentation
  // See https://github.com/bvaughn/react-virtualized/blob/HEAD/docs/Column.md for Column documentation
  render() {
    const {
      data,
      gridStyle,
      name,
      header,
      sortBy,
      isSortDisabled,
      modal,
      isGroupByEnable,
      groupedBy,
      style,
    } = this.props;
    if (process.env.NODE_ENV === 'development' && header.length) {
      const widths = floatify(header.reduce((acc, col) => acc + col.width, 0));
      if (widths !== 1)
        console.log(
          `%c${name} column widths should equal 1: ${widths}`,
          'color: red',
        );
    }

    const hasAnomalies = this.hasAnomalies();
    const hasExtendableRows = this.hasExtendableRows();
    const rowCount = this.getRowCount();

    const hasSelectedAbove = this.state.selectedIndex.some(
      index => index < this.state.startIndex,
    );
    const hasSelectedBellow = this.state.selectedIndex.some(
      index => index > this.state.stopIndex,
    );

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingRight: modal && 5,
          paddingLeft: modal && 5,
          backgroundColor: modal && 'rgb(240,240,240)',
          borderRadius: '.28571429rem',
          filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.1))',
          overflow: 'hidden',
          ...style,
        }}
      >
        <AutoSizer
          onResize={this.onResize}
          style={{
            height: (modal && 'fit-content') || '100%',
            width: (modal && 'fit-content') || '100%',
          }}
        >
          {({ width, height }) => {
            const tableHeight =
              this.getGridHeight(width) -
              ROW_GAP -
              (this.isGroupBy() ? GROUP_HEADER_HEIGHT_DIFF : 0);

            const rowWidth =
              width - this.getScrollBarPadding(tableHeight, height);

            return (
              <div
                style={{
                  height:
                    modal && this.state.data.length
                      ? tableHeight + HEADER_HEIGHT + ROW_GAP
                      : '100%',
                }}
                // onContextMenu={this.onContextMenu}
              >
                {hasSelectedAbove && (
                  <Button
                    secondary
                    size="mini"
                    className="shadow hard"
                    circular
                    style={{
                      ...styles.scrollToSelectedButton,
                      marginTop: HEADER_HEIGHT + 5,
                    }}
                    onClick={this.scrollToSelectedAbove}
                  >
                    <Icon name="arrow up" />
                    ROW SELECTED
                  </Button>
                )}
                {hasSelectedBellow && (
                  <Button
                    secondary
                    size="mini"
                    className="shadow hard"
                    circular
                    style={{
                      ...styles.scrollToSelectedButton,
                      bottom: 15 + ROW_GAP,
                    }}
                    onClick={this.scrollToSelectedBellow}
                  >
                    <Icon name="arrow down" />
                    ROW SELECTED
                  </Button>
                )}
                {this.state.sticky &&
                this.state.data.length &&
                this.isGroupBy() ? (
                  <div
                    style={{
                      ...styles.stickyRow,
                      width: rowWidth,
                      marginTop: HEADER_HEIGHT,
                      height: GROUP_HEADER_ROW_HEIGHT,
                    }}
                  >
                    {this.rowRenderer({
                      style: { zIndex: rowCount },
                      rowData: this.state.sticky,
                    })}
                  </div>
                ) : null}
                <Table
                  ref={this.tableRef}
                  isScrollingOptOut={true}
                  noRowsRenderer={() =>
                    data.length
                      ? noDataRenderer(this.props.rowHeight)
                      : loadingRenderer(height)
                  }
                  rowRenderer={this.rowRenderer}
                  gridStyle={{
                    ...styles.grid,
                    ...gridStyle,
                  }}
                  width={width}
                  onRowClick={this.onRowClick}
                  onHeaderClick={this.onHeaderClick}
                  onScroll={this.onScroll}
                  scrollToAlignment="start"
                  scrollTop={this.state.scrollTop}
                  className={classnames({
                    has_anomaly: hasAnomalies,
                    is_extendable: hasExtendableRows,
                    has_settings: isGroupByEnable && !groupedBy,
                    [name]: true,
                  })}
                  gridClassName={name}
                  containerStyle={{
                    height: tableHeight,
                    maxHeight: tableHeight,
                  }}
                  height={
                    height -
                    (this.getGridHeight(rowWidth) > height
                      ? ROW_GAP
                      : modal && this.isGroupBy()
                      ? -GROUP_HEADER_HEIGHT_DIFF
                      : 0) +
                    ROW_GAP
                  }
                  rowStyle={this.rowStyle}
                  onRowsRendered={this.onRowsRendered}
                  headerHeight={HEADER_HEIGHT}
                  rowHeight={({ index }) =>
                    this.getRowHeight({
                      index,
                      width: rowWidth,
                    })
                  }
                  rowCount={rowCount}
                  rowGetter={this.rowGetter}
                  sort={this.sort}
                >
                  {header.map(column => (
                    <Column
                      headerStyle={styles.columnHeader}
                      label={column.label}
                      columnData={{ help: column.help }}
                      key={column.dataKey}
                      dataKey={column.dataKey}
                      cellDataGetter={column.cellDataGetter}
                      width={width * column.width}
                      disableSort={
                        !sortBy.includes(column.dataKey) ||
                        isSortDisabled ||
                        this.isGroupBy()
                      }
                      headerRenderer={this.headerRenderer}
                      style={{
                        overflow: column.overflow ? 'visible' : 'hidden',
                        height: column.fullHeight && 'inherit',
                      }}
                      cellRenderer={this.cellRenderer(column, {
                        rowWidth,
                        width,
                      })}
                    />
                  ))}
                </Table>
                {name && <ScrollToTop source={name} />}
                <ContextMenu
                  open={!groupedBy && this.state.contextMenu}
                  groupByKey={this.state.groupByKey}
                  context={this.contextRef}
                  isGroupByEnable={isGroupByEnable}
                  header={header}
                  onClick={this.changeGroupByDataKey}
                  onClose={this.onContextClose}
                />
              </div>
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

const styles = {
  columnHeader: { outline: 'none', whiteSpace: 'nowrap' },
  header: { display: 'flex', alignItems: 'baseline' },
  ellipsis: { overflow: 'hidden', textOverflow: 'ellipsis' },
  help: { marginLeft: 'auto' },
  grid: {
    outline: 'none',
    borderBottomRightRadius: '.28571429rem',
    borderBottomLeftRadius: '.28571429rem',
  },
  stickyRow: {
    zIndex: 10,
    position: 'fixed',
    backgroundColor: 'rgb(240 240 240)',
  },
  columnLoading: {
    marginLeft: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  scrollToSelectedButton: {
    zIndex: 20,
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
};

M3dianTable.propTypes = {
  anomalies: PropTypes.array,
  data: PropTypes.array,
  extensionRenderer: PropTypes.func,
  getExtensionStyle: PropTypes.func,
  getRowExtensionHeight: PropTypes.func,
  getRowHeight: PropTypes.func,
  getRowStyle: PropTypes.func,
  gridStyle: PropTypes.object,
  groupedBy: PropTypes.bool,
  groupHeaderRenderer: PropTypes.func,
  groupHeaderStyle: PropTypes.object,
  header: PropTypes.array,
  headerStyle: PropTypes.object,
  highlight: PropTypes.array,
  isDefaultOpen: PropTypes.bool,
  isExtendEnable: PropTypes.bool,
  isGroupByEnable: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  isRowExtendable: PropTypes.func,
  isRowSelectable: PropTypes.func,
  isSortDisabled: PropTypes.bool,
  modal: PropTypes.bool,
  multiple: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selected: PropTypes.array,
  sortBy: PropTypes.array,
  style: PropTypes.object,
  table: PropTypes.string,
  tableRef: PropTypes.func,
  toggleExtend: PropTypes.bool,
};

const mapStateToProps = state => ({
  anomalies: state.anomalies.currentDocumentAnomalies,
});

export default process.env.NODE_ENV !== 'test'
  ? connect(mapStateToProps)(M3dianTable)
  : M3dianTable;
