import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { color } from 'config/constants';

import { shouldItemLocationBeIgnored } from 'lib/utils';

const EffectivityLocation = React.memo(
  ({ effectivityLocation, locationList, rowData }) => {
    if (shouldItemLocationBeIgnored(locationList)) return;
    // Loading
    if (effectivityLocation === false)
      return (
        <b style={styles.noEffLoc} title="Loading...">
          <Icon loading name="spinner" size="large" />
          Loading
        </b>
      );

    if (typeof effectivityLocation === 'string') return effectivityLocation;

    if (effectivityLocation) {
      const entries = Object.entries(effectivityLocation);

      const getStatusColor = status => {
        const effLocStatus = {
          heredity: color.primary,
          override: color.success,
          warning: color.warning,
        };
        return effLocStatus[status] ?? color.grey;
      };

      return (
        entries.length && (
          <div
            style={{
              ...styles.effLocationContainer,
              border: `1px solid ${getStatusColor(rowData.effLocStatus)}`,
            }}
            title={entries
              .map(([locations, effectivity]) =>
                effectivity
                  ? `•   ${effectivity.join('_')} : ${locations}`
                  : locations,
              )
              .join('\n')}
          >
            {entries.map(([locations, effectivity]) =>
              effectivity ? (
                <div
                  key={`${effectivity}${locations}`}
                  style={styles.effLocationContent}
                >
                  <b>• {effectivity.join('_')} : </b>
                  {locations}
                </div>
              ) : (
                locations
              ),
            )}
          </div>
        )
      );
    }
  },
);

const styles = {
  effLocationContainer: {
    padding: '0.5rem',
    borderRadius: '5px',
  },
  effLocationContent: { overflow: 'hidden', textOverflow: 'ellipsis' },
  noEffLoc: { overflow: 'hidden', textOverflow: 'ellipsis' },
};

EffectivityLocation.propTypes = {
  effectivityLocation: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
    PropTypes.string,
  ]),
  locationList: PropTypes.string,
  rowData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default EffectivityLocation;
