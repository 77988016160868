import * as API from 'lib/rest';
import * as network from 'config/network';
import * as types from 'config/types';
import { startStep } from 'integration/integrationNavigationActions';

export const sendExtractionRequest = (
  DocumentId,
  lineSensitivity,
  columnSensitivity,
  pageList = null,
) => async () => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.steps.formatting.itemExtraction.extractItems,
    );
    return await API.post(
      url,
      { DocumentId, lineSensitivity, columnSensitivity, pageList },
      true,
    );
  } catch (err) {
    console.error(err);
  }
};

export const saveItemExtraction = itemList => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.process);
    const results = await API.post(
      url,

      {
        DocumentId: getState().utils.currentDocument.id,
        milestone: getState().equipment.milestone,
        step: 'Populate item table',
        input: { itemList },
      },
      true,
    );
    if (results) {
      await dispatch(
        startStep(getState().utils.currentDocument.id, ['Populate item table']),
      );

      dispatch({
        type: types.ITEM_EXTRACTION_SAVED_RESULTS,
        payload: results,
      });
      //progress document
    }
  } catch (err) {
    console.error(err);
  }
};
