import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';
import { getTable, getInput } from 'components/utils/utilsActions';

/**
 * Change displayed section
 * @param {?string} section Section to display, all by default
 */
export const toggleViewerSection = (section = 'all') => dispatch => {
  dispatch({
    type: types.VIEWER_TOGGLE_SECTION,
    payload: section !== 'blank' ? section : null,
  });
};

/**
 * Toggle hide mode
 */
export const toggleViewerHideMode = () => dispatch => {
  dispatch({
    type: types.VIEWER_TOGGLE_HIDE_MODE,
  });
};

/**
 * Scroll viewer to page and highlight records
 * @param {?number} page documentPage to display
 * @param {?array} records Records to highlight
 */
export const viewerGoToPage = (page = null, records = null) => dispatch => {
  dispatch({
    type: types.VIEWER_GO_TO_PAGE,
    payload: { page, records },
  });
};

/**
 * Scroll viewer to item
 * @param {number or array} id Item id or array of item id
 */
export const viewerGoToItem = id => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getData);

    let where = {
      DocumentId: getState().utils.currentDocument.id,
      id,
    };

    const items = await API.get(url, where);
    items &&
      items.length &&
      dispatch(
        viewerGoToPage(
          items[0].documentPage,
          items.map(item => item.XmlTexts?.map(xmlText => xmlText.id)).flat(),
        ),
      );
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return XmlText records for current document and page number
 * @param {number} page documentPage
 */
export const getViewerPageData = page => async (dispatch, getState) => {
  dispatch(getTable('XmlText', { documentPage: page.documentPage }));

  if (page.section === 'figure' && !getState().utils.ItemCoordinate.length)
    dispatch(getTable('ItemCoordinate'));
};

/**
 * Open record editor for clicked record
 * @param {*} record record to display
 */
export const displayViewerRecord = record => dispatch => {
  dispatch({
    type: types.VIEWER_OPEN_EDITOR,
    table: 'XmlText',
    payload: {
      open: true,
      record: record ? record : {},
    },
  });
};

export const getFigureParser = (sync = true) => dispatch => {
  dispatch(getInput('Formatting', 'Item representation', 'figureParser', sync));
};

/**
 * Get editor attributes
 */
export const getViewerEditorAttributes = () => async dispatch => {
  try {
    const urlAttributes = API.buildURL({
      table: 'XmlText',
      function: 'getModelAttributes',
    });
    const attributes = await API.get(urlAttributes);

    dispatch({
      type: types.VIEWER_GET_EDITOR_ATTRIBUTES,
      payload: attributes ? attributes : [],
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};
