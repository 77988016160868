import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  milestone: 'Formatting',
  step: 'Section detection',
  figureKeyword: 'fig',
  itemKeyword: 'nomenclature,description',
  section: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SECTION_CHANGE_KEYWORD:
        if (action.payload.figureKeyword !== undefined)
          draft.figureKeyword = action.payload.figureKeyword;
        if (action.payload.itemKeyword !== undefined)
          draft.itemKeyword = action.payload.itemKeyword;
        break;

      case types.SECTION_CHANGE_SECTION:
        draft.section = action.payload;
        break;

      default:
        break;
    }
  });
