import * as types from 'config/types';
import * as API from 'lib/rest';

/**
 * Change current poc
 * @param {string} currentPoc poc selected
 */
export const changeCurrentPoc = currentPoc => dispatch => {
  dispatch({
    type: types.CHANGE_CURRENT_POC,
    payload: currentPoc,
  });
};

/**
 * Select rate filter
 * @param {string} filterValue selected filter
 */
export const selectRateFilter = filterValue => dispatch => {
  dispatch({
    type: types.POC_SELECT_RATE_FILTER,
    payload: filterValue,
  });
};

/**
 * Run selected poc
 * @param {Array of Number} DocumentId
 * @param {Array of string} figureDoc
 * @param {?string} type
 */
export const runPoc = (DocumentId, figureDoc, type = null, id = null) => async (
  dispatch,
  getState,
) => {
  try {
    const url = API.buildURL(getState().pocs.currentPoc.endpoint);

    let where = { DocumentId };
    if (figureDoc && figureDoc !== 'all') where = { ...where, figureDoc };
    if (type) where = { ...where, type };
    if (id) where = { ...where, id };

    const startTime = performance.now();
    let results = await API.get(url, where);
    const endTime = performance.now();

    const time = endTime - startTime;
    const averageTime =
      results.items &&
      results.items.length &&
      results.items.reduce(
        (acc, item) =>
          item.time ? { sum: acc.sum + item.time, count: acc.count + 1 } : acc,
        { sum: 0, count: 0 },
      );

    if (id)
      return `${(endTime - startTime).toFixed()}ms (${
        averageTime ? (averageTime.sum / averageTime.count).toFixed() : 0
      }ms) (Fetch: ${results.fetchTime.toFixed()}ms)`;

    dispatch({
      type: types.GET_POC_RATE,
      payload:
        results.items && results.items.length
          ? [
              {
                Time: `${(time / 1000).toFixed(2)}s`,
                averageTime:
                  averageTime &&
                  averageTime.count &&
                  `${(averageTime.sum / averageTime.count).toFixed()}ms`,
                fetchTime:
                  results.fetchTime && `${results.fetchTime.toFixed()}ms`,
                Success: results.goodFinds,
                Fail: results.wrongFinds,
                'First Match': results.firstHit,
                'Second Match': results.secondHit,
                'Third Match': results.thirdHit,
                'In top': results.inTop,
                'Except top three': results.otherHit,
                'Not Found': results.notFound,
                Ignored: `${results.ignored} / ${results.items.length} ${
                  averageTime.count
                    ? `(${
                        averageTime && averageTime.count
                          ? (
                              time -
                              results.fetchTime -
                              averageTime.sum
                            ).toFixed()
                          : 0
                      }ms)`
                    : ''
                }`,
              },
            ]
          : ['NO DATA'],
    });

    dispatch({
      type: types.GET_POC_ITEMS,
      payload:
        results.items && results.items.length ? results.items : ['NO DATA'],
    });
  } catch (err) {
    return false;
  }
};
