import * as API from 'lib/rest';
import * as network from 'config/network';
import * as types from 'config/types';

/**
 * Get lopa data
 * @param {number} LopaId LopaId
 * @param {?number} EquipmentId EquipmentId
 */
export const getZionEngineLopa = (
  LopaId,
  EquipmentId = null,
) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.ZionEngine.getLopa);
    const scene = await API.post(url, { LopaId, EquipmentId });
    if (!scene) return;

    const payload = { scene };

    const areas = [];
    Object.keys(scene.lopaEquipments).forEach(lopaSNId => {
      const equipment = scene.lopaEquipments[lopaSNId];

      if (equipment.area && !areas.includes(equipment.area))
        areas.push(equipment.area);
    });
    if (areas.length > 0) {
      const [currentArea] = areas;
      payload.currentArea = currentArea;
    }
    payload.areas = areas;

    dispatch({
      type: types.ENGINE_LOAD_SCENE,
      payload,
    });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get EquipmentSubAssemblies for Document
 * @param {number} DocumentId DocumentId
 */
export const getZionEngineDocument = DocumentId => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.ZionEngine.getDocument);
    const document = await API.post(url, { DocumentId });

    dispatch({
      type: types.ENGINE_LOAD_DOCUMENT,
      payload: { document },
    });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Select area
 * @param {string} area area
 */
export const selectEngineArea = area => dispatch => {
  dispatch({
    type: types.ENGINE_SELECT_AREA,
    payload: area,
  });
};

/**
 * Select elements
 * @param {array} select elements to select
 */
export const selectEngine = (select = []) => dispatch => {
  dispatch({
    type: types.ENGINE_SELECT,
    payload: select,
  });
};
