import React from 'react';
import PropTypes from 'prop-types';

const FigureDescription = React.memo(({ regex, description }) => {
  if (regex?.length && description) {
    try {
      return description.replace(new RegExp(regex), '').trim();
    } catch (error) {
      return description;
    }
  }
  return description;
});

FigureDescription.propTypes = {
  description: PropTypes.string,
  regex: PropTypes.string,
};

export default FigureDescription;
