import React from 'react';
import { Icon, Popup, Menu } from 'semantic-ui-react';

const SettingsMenu = ({
  open,
  groupByKey,
  context,
  isGroupByEnable,
  header,
  onClick,
  onClose,
}) => {
  const groupByList = (isGroupByEnable.length
    ? [...header, ...isGroupByEnable]
    : [...header]
  ).sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  return (
    <Popup
      basic
      context={context}
      onClose={onClose}
      open={open}
      inverted
      popper={{ className: 'table_context_menu' }}
      style={{ padding: 0 }}
      content={
        <Menu secondary vertical inverted compact>
          <Menu.Item style={{ marginBottom: 0 }}>
            <Menu.Header>
              <Icon name="object group" />
              Group By
            </Menu.Header>

            <Menu.Menu>
              {groupByList.map(column => {
                let value;
                if (typeof column === 'string') value = column;
                return (
                  <Menu.Item
                    key={`${value || column.dataKey}`}
                    name={value || column.label || column.dataKey}
                    datakey={value || column.dataKey}
                    active={(value || column.dataKey) === groupByKey}
                    onClick={onClick}
                  />
                );
              })}
            </Menu.Menu>
          </Menu.Item>
        </Menu>
      }
    />
  );
};

export default SettingsMenu;
