import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';

import connect from 'lib/reduxConnect';
import { color } from 'config/constants';

const Button = React.memo(
  ({ highlight = [], documentPage, actions }) =>
    documentPage ? (
      <Icon
        style={{
          cursor: 'pointer',
          backgroundColor: color.highlight,
        }}
        size="small"
        circular
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          actions.viewerGoToPage(documentPage, highlight);
        }}
        name="external share"
      />
    ) : null,
  (prevProps, nextProps) => {
    const isHighlightEqual = equal(nextProps.highlight, prevProps.highlight);
    const isDocumentPageEqual =
      nextProps.documentPage === prevProps.documentPage;

    return isHighlightEqual && isDocumentPageEqual;
  },
);

const ItemButton = process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

ItemButton.propTypes = {
  documentPage: PropTypes.number,
  highlight: PropTypes.array,
};

export default ItemButton;
