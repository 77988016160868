import produce from 'immer';
import * as types from 'config/types';

const initialState = { filteredInfo: {} };

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ADD_SELECTED_VALUE:
        if (action.selector === 'home') {
          draft.filteredInfo = { ...state.filteredInfo, ...action.payload };
        }
        break;

      case types.REMOVE_SELECTED_VALUE:
        if (action.selector === 'home') {
          const filter = { ...state.filteredInfo };
          delete filter[action.payload];
          draft.filteredInfo = filter;
        }
        break;

      default:
        break;
    }
  });
