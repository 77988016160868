import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';

import connect from 'lib/reduxConnect';

const Button = React.memo(
  ({ documentPage, highlight = [], actions }) =>
    documentPage ? (
      <SemanticButton
        compact
        circular
        color="grey"
        style={styles.button}
        title={documentPage}
        size="tiny"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          actions.viewerGoToPage(documentPage, highlight);
        }}
      >
        {documentPage}
      </SemanticButton>
    ) : null,
  (prevProps, nextProps) => {
    const isHighlightEqual = equal(nextProps.highlight, prevProps.highlight);
    const isDocumentPageEqual =
      nextProps.documentPage === prevProps.documentPage;

    return isHighlightEqual && isDocumentPageEqual;
  },
);

const PageButton = process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

PageButton.propTypes = {
  documentPage: PropTypes.number,
};

const styles = {
  button: { overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' },
};

export default PageButton;
