import React from 'react';
import TabButton from 'components/utils/TabButton';

export default ({
  first,
  second,
  firstActive,
  secondActive,
  firstDisabled,
  secondDisabled,
  onClick,
  style = {},
}) => (
  <div
    className="tab_buttons rounded inverted"
    style={{
      backgroundColor: 'rgba(0,0,0,0.2)',
      height: '100%',
      borderRadius: '.28571429rem',
      ...style,
    }}
  >
    <TabButton
      active={firstActive}
      disabled={firstDisabled}
      onClick={() => !firstActive && onClick()}
      text={first}
      style={{ height: '100%', width: 'calc(50% - 1px)' }}
    />
    <div
      style={{
        height: '-webkit-fill-available',
        width: 2,
        backgroundColor: 'white',
      }}
    />
    <TabButton
      active={!firstActive && secondActive}
      disabled={secondDisabled}
      onClick={() => !secondActive && onClick()}
      text={second}
      style={{ height: '100%', width: 'calc(50% - 1px)' }}
    />
  </div>
);
