import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

export const closeFigureSidebar = () => dispatch => {
  dispatch({
    type: types.CLOSE_FIGURE_SIDEBAR,
  });
};

export const showFigureSidebar = () => dispatch => {
  dispatch({
    type: types.SHOW_FIGURE_SIDEBAR,
  });
};

/**
 * Return BKDN item(s) by figureDoc for current document
 */
export const getFigureWithBkdn = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getFigureWithBkdn);

    const bkdnItems = await API.get(url, {
      DocumentId: getState().utils.currentDocument.id,
    });

    dispatch({
      type: types.GET_FIGURE_WITH_BKDN,
      payload: bkdnItems,
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};
