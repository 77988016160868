import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ScrollToTop extends Component {
  state = {
    isVisible: false,
    element: null,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.toggleVisibility, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleVisibility, true);
  }

  toggleVisibility = event => {
    if (
      event.srcElement &&
      event.srcElement.className &&
      event.srcElement.className.includes(this.props.source)
    ) {
      this.setState({
        element: event.srcElement,
      });
      event.srcElement.scrollTop > 300
        ? this.setState({
            isVisible: true,
          })
        : this.setState({
            isVisible: false,
          });
    }
  };

  scrollToTop() {
    this.state.element.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const { isVisible } = this.state;
    return (
      <React.Fragment>
        {isVisible && (
          <Button
            style={{
              position: 'absolute',
              bottom: 12,
              opacity: '50%',
              right: 20,
            }}
            className="scroll-to-top"
            type="button"
            circular
            color="blue"
            icon="arrow up"
            size="small"
            onClick={() => this.scrollToTop()}
          />
        )}
      </React.Fragment>
    );
  }
}

ScrollToTop.propTypes = {
  source: PropTypes.string,
};

export default ScrollToTop;
