import React from 'react';

import { NhaBkdnButtons } from './NhaBkdnButton';

export const extensionNhaBkdnRenderer = (
  { rowData, rowIndex },
  { table, isExtendable, isExtended, getRef, onClick },
) => (
  <div style={{ paddingLeft: '3.5%', overflow: 'hidden', height: '100%' }}>
    <NhaBkdnButtons
      index={rowIndex}
      item={rowData}
      table={table}
      isExtendable={isExtendable}
      isExtended={isExtended}
      onClick={onClick}
      ref={ref => getRef && getRef(ref)}
    />
  </div>
);
