import * as types from 'config/types';

export const closeStepProgress = () => ({
  type: types.CLOSE_STEP_PROGRESS,
});

export const updateStepProgress = payload => ({
  type: types.UPDATE_STEP_PROGRESS,
  payload,
});
