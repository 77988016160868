import React from 'react';
import PropTypes from 'prop-types';

const UsernameLabels = React.memo(
  ({ usernames, DocumentId }) =>
    usernames && (
      <div style={style}>
        {usernames.map(
          username =>
            username && <div key={`${DocumentId}${username}`}>{username}</div>,
        )}
      </div>
    ),
);

const style = {
  overflowY: 'auto',
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'normal',
  justifyContent: 'center',
};

UsernameLabels.propTypes = {
  DocumentId: PropTypes.number,
  usernames: PropTypes.array,
};

export default UsernameLabels;
