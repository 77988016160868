import produce from 'immer';

import * as types from 'config/types';

const initialState = {
  milestone: 'Formatting',
  step: 'Description formatting',
  init: false,
  rules: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.init = initialState.init;
        draft.rules = initialState.rules;
        break;

      case types.UPDATE_RECORD:
        if (action.table === 'Input')
          draft.rules = [
            ...state.rules.filter(rule => rule.id !== action.payload.id),
            action.payload,
          ];
        break;

      case types.CREATE_RECORD:
        if (action.table === 'Input')
          draft.rules = [...state.rules, action.payload];
        break;

      case types.DELETE_RECORD:
        if (action.table === 'Input')
          draft.rules = state.rules.filter(
            rule => rule.id !== action.payload.id,
          );
        break;

      case types.DESCRIPTION_GET_RULES:
        draft.rules = action.payload;
        break;

      case types.DESCRIPTION_SORT_RULES:
        draft.rules = [...state.rules]
          .sort((a, b) => a.value.sort - b.value.sort)
          .map(rule => {
            if (rule.DocumentId !== action.payload.DocumentId) return rule;
            if (rule.name !== action.payload.type) return rule;

            const index = rule.value.sort;
            const { source } = action.payload;
            const destination =
              action.payload.destination === -1
                ? state.rules.filter(rule => rule.name === action.payload.type)
                    .length - 1
                : action.payload.destination;

            let sort = index;
            if (index === source) sort = destination;
            if (source < destination && index > source && index <= destination)
              sort = index - 1;
            if (source > destination && index < source && index >= destination)
              sort = index + 1;
            return { ...rule, value: { ...rule.value, sort } };
          });

        break;

      case types.DESCRIPTION_INIT:
        draft.init = true;
        break;

      default:
        break;
    }
  });
