import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { getStepProgress } from 'integration/integrationNavigationActions';
import { encodeWhere } from 'lib/utils';
import { batchActions } from 'ais-utils/reduxBatch';

const tableNotSpecificToDocument = [
  'CameraPOV',
  'Document',
  'EquipmentRep',
  'EquipmentSubAssembly',
  'Family',
  'ItemLocation',
  'ItemNomenc',
  'Layer',
  'LocationGeneric',
  'Lopa',
  'LopaEquipment',
  'Mesh',
  'Milestone',
  'Step',
  'SubAssembly',
  'User',
  'DocumentSteps',
];

/**
 * Change current document
 * @param {*} document Document record
 */
export const changeCurrentDocument = document => dispatch => {
  dispatch({
    type: types.CHANGE_CURRENT_DOCUMENT,
    payload: document,
  });
};

/**
 * Change current lopa
 * @param {*} lopa Lopa record
 */
export const changeCurrentLopa = lopa => dispatch => {
  dispatch({
    type: types.CHANGE_CURRENT_LOPA,
    payload: lopa,
  });
};

/**
 * Return table records for current document
 * @param {string} table table to fetch
 * @param {?*} where where object
 */
export const getTable = (table, _where = null) => async (
  dispatch,
  getState,
) => {
  try {
    const where = encodeWhere(_where);

    const url = API.buildURL({ table, function: 'get' });
    const records = await API.get(
      url,
      tableNotSpecificToDocument.indexOf(table) === -1
        ? {
            DocumentId: getState().utils.currentDocument.id,
            ...where,
          }
        : where,
    );
    records &&
      dispatch({
        type: _where ? types.GET_TABLE_NOT_SYNC : types.GET_TABLE,
        table,
        payload: Array.isArray(records)
          ? records.length
            ? records
            : ['NO DATA']
          : records,
      });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return Table counts
 * @param {*} table table to fetch or object like { table: 'table name', endpoint: 'endpoint to use', name: 'optional replacement name'}
 * @param {?number} DocumentId Document id if for full document
 */
export const getTableCounts = (_table, DocumentId = false) => async (
  dispatch,
  getState,
) => {
  try {
    const table = _table.table || _table;

    const url = API.buildURL(
      _table.endpoint
        ? network.ENDPOINTS[table][_table.endpoint]
        : DocumentId
        ? {
            table,
            id: DocumentId,
            function: 'count',
          }
        : network.ENDPOINTS[table].getCounts,
    );
    const where = {
      DocumentId: getState().utils.currentDocument.id,
    };
    const counts = await API.get(url, where);
    counts &&
      dispatch({
        type: DocumentId ? types.GET_DOCUMENT_COUNT : types.GET_COUNTS,
        table: _table.endpoint ? _table.name : table,
        payload: counts,
      });
    return counts;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return Lopas for current document
 */
export const getLopaForDocument = () => async (dispatch, getState) => {
  try {
    const DocumentId = getState().utils.currentDocument.id;
    if (!DocumentId) return;

    const url = API.buildURL(network.ENDPOINTS.Lopa.getForDocument);
    const lopas = await API.get(url, { DocumentId });
    dispatch({
      type: types.GET_TABLE_NOT_SYNC,
      table: 'Lopa',
      payload: lopas && lopas.length ? lopas : ['NO DATA'],
    });
    return lopas;
  } catch (err) {
    console.error(err);
  }
};

export const checkForPushSockets = () => async (dispatch, getState) => {
  try {
    const token = await API.checkToken(getState().auth.token);
    if (!token) return;
    const url = API.buildURL(network.ENDPOINTS.events.checkReconnect);
    API.get(url);
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const shouldReconnectPushSockets = () => dispatch => {
  dispatch({
    type: types.SHOULD_RECONNECT_CHECK,
  });
};

export const reconnectPushSockets = () => async dispatch => {
  try {
    dispatch({
      type: types.RECONNECT_CHECK_OK,
    });
    API.subscribeToEvents(dispatch);
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Get input values for step
 * @param {string} milestone current milestone
 * @param {string} step to apply
 */
export const getInput = (milestone, step, name = null, sync = false) => async (
  dispatch,
  getState,
) => {
  try {
    const where = {
      DocumentId: getState().utils.currentDocument.id,
      milestone,
      step,
    };

    const url = API.buildURL(network.ENDPOINTS.Input.getStep);
    const inputs = await API.get(url, name ? { ...where, name } : where);

    inputs &&
      dispatch({
        type: sync ? types.GET_INPUT_SYNC : types.GET_INPUT,
        milestone,
        step,
        payload: inputs,
      });
    return inputs;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Get input default values for step
 * @param {string} milestone current milestone
 * @param {string} step to apply
 * @param {?*} options object of options
 */
export const getDefaultInput = (
  milestone,
  step,
  options = null,
) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Input.getDefault);
    const inputs = await API.get(url, {
      milestone,
      step,
      ...options,
    });
    dispatch({
      type: types.GET_DEFAULT_INPUT,
      milestone,
      step,
      payload: inputs,
    });
    return inputs;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const updateDocumentStatus = (DocumentId, status) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Document.updateStatus);
    const update = await API.get(url, {
      DocumentId,
      status,
    });
    if (update) dispatch(getTable('Document'));
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loadHomeData = () => async dispatch => {
  dispatch(
    batchActions([getTable('Document'), getTable('Family'), getStepProgress()]),
  );
};

export const addSelectedValue = (selector, payload) => dispatch => {
  dispatch({
    type: types.ADD_SELECTED_VALUE,
    selector,
    payload,
  });
};

export const removeSelectedValue = (selector, payload) => dispatch => {
  dispatch({
    type: types.REMOVE_SELECTED_VALUE,
    selector,
    payload,
  });
};

export const resetTable = table => dispatch => {
  dispatch({
    type: types.RESET_TABLE,
    table,
  });
};
