import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import history from 'config/history';
import connect from 'lib/reduxConnect';

const Button = React.memo(({ text, color, document, actions }) => (
  <SemanticButton
    content={text}
    color={color}
    style={style}
    title={text}
    size="tiny"
    onClick={() => {
      actions.changeCurrentDocument(document);
      history.push('/integration');
    }}
  />
));

const DocumentIdButton =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

const style = {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

Button.propTypes = {
  color: PropTypes.string,
  document: PropTypes.object,
  text: PropTypes.number,
};

export default DocumentIdButton;
