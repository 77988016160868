import produce from 'immer';
import { toObject } from 'ais-utils';

import * as types from 'config/types';

const initialState = {
  xmlTextCounts: {},
  highlight: [],
  scrollTo: null,
  filter: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        draft.filter = initialState.filter;
        draft.xmlTextCounts = initialState.xmlTextCounts;
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'pageTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.GET_COUNTS:
        if (action.table === 'XmlText')
          draft.xmlTextCounts = toObject(action.payload, 'id');
        break;

      default:
        break;
    }
  });
