import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  open: false,
  letterings: [],
  locations: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.OPEN_LOCATION_EDITOR_MODAL:
        draft.open = true;
        break;

      case types.GET_LETTERINGS:
        draft.letterings = action.payload;
        break;

      case types.CLOSE_LOCATION_EDITOR_MODAL:
        draft.open = initialState.open;
        draft.locations = initialState.locations;
        break;

      case types.DELETE_LOCATION_EDITOR_RECORD:
        draft.locations = state.locations.filter(
          location => location.location !== action.payload,
        );
        break;

      case types.UPDATE_SIDE_LOCATION_EDITOR:
        draft.locations = state.locations.map(location => ({
          ...location,
          side: action.payload,
        }));
        break;

      case types.UPDATE_RECORD:
        if (action.table === 'LocationGeneric')
          draft.locations = state.locations.map(location =>
            action.payload.id === location.id ? action.payload : location,
          );
        break;

      case types.UPDATE_RECORD_LOCATION_EDITOR:
        draft.locations = state.locations.map((location, index) =>
          action.index === index ? action.payload : location,
        );
        break;

      case types.CREATE_RECORDS:
        if (action.table === 'LocationGeneric')
          draft.locations = action.payload;
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.open = initialState.open;
        draft.locations = initialState.locations;
        draft.letterings = initialState.letterings;
        break;

      case types.GET_TABLE:
        if (action.table === 'LocationGeneric')
          draft.locations = action.payload;
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (action.table === 'LocationGeneric')
          draft.locations = action.payload;
        break;

      default:
        break;
    }
  });
