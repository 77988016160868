import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

export const getNotificationsForDocument = (
  DocumentId,
  getClosed = false,
  count = 100,
) => async (dispatch, getState) => {
  try {
    const token = await API.checkToken(getState().auth.token);
    if (!token) return;

    const url = API.buildURL(network.ENDPOINTS.Notification.getForDocument);
    const notifications = await API.get(url, {
      DocumentId: DocumentId ? DocumentId : getState().utils.currentDocument.id,
      getClosed,
      count,
    });
    dispatch({
      type: types.NOTIFICATIONS_GET_FOR_DOCUMENT,
      payload: {
        notifications,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const loadMoreNotifications = activePage => (dispatch, getState) => {
  const fetchCount =
    getState().notifications.maxPaginationPerCategory * activePage;
  dispatch(
    getNotificationsForDocument(
      getState().utils.currentDocument.id,
      getState().notifications.showClosedNotifications,
      fetchCount,
    ),
  );
  dispatch(
    getNotificationsForUser(
      getState().auth.user.id,
      getState().notifications.showClosedNotifications,
      fetchCount,
    ),
  );
  dispatch({
    type: types.NOTIFICATIONS_UPDATE_PAGINATION,
    payload: {
      activePage,
    },
  });
};

export const getNotificationsForUser = (
  UserId,
  getClosed = false,
  count = 100,
  token = false,
) => async (dispatch, getState) => {
  try {
    const valid = await API.checkToken(token ? token : getState().auth.token);
    if (!valid) return;

    const url = API.buildURL(network.ENDPOINTS.Notification.getForUser);
    const notifications = await API.get(url, {
      UserId: UserId ? UserId : getState().auth.user && getState().auth.user.id,
      getClosed,
      count,
    });
    dispatch({
      type: types.NOTIFICATIONS_GET_FOR_USER,
      payload: {
        notifications,
      },
    });
  } catch (err) {
    console.error(err);
  }
};
