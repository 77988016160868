import produce from 'immer';

import * as types from 'config/types';

const initialState = {
  visible: false,
  figuresWithBkdn: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.SHOW_FIGURE_SIDEBAR:
        draft.visible = true;
        break;

      case types.CLOSE_FIGURE_SIDEBAR:
        draft.visible = false;
        break;

      case types.GET_FIGURE_WITH_BKDN:
        draft.figuresWithBkdn = action.payload;
        break;

      default:
        break;
    }
  });
