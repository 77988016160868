import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDimmer = styled(Dimmer)`
  && {
    top: unset !important;
    left: unset !important;
    background-color: rgb(240, 240, 240) !important;
  }
`;

const Status = React.lazy(() =>
  import(/* webpackChunkName: "Status/Status" */ 'components/status/Status'),
);
const NotFound = React.lazy(() =>
  import(/* webpackChunkName: "NotFound" */ 'components/utils/NotFound'),
);
const Forbidden = React.lazy(() =>
  import(/* webpackChunkName: "Forbidden" */ 'components/utils/Forbidden'),
);
const IntegrationMenu = React.lazy(() =>
  import(
    /* webpackChunkName: "Integration/IntegrationNavigationMenu" */ 'integration/IntegrationNavigationMenu'
  ),
);
const Notifications = React.lazy(() =>
  import(
    /* webpackChunkName: "Notifications/NotificationContainer" */ 'components/notifications/NotificationContainer'
  ),
);
const DocumentImport = React.lazy(() =>
  import(
    /* webpackChunkName: "DocumentCreation/DocumentImport" */ 'integration/documentCreation/DocumentImport'
  ),
);
const SubAssembly = React.lazy(() =>
  import(
    /* webpackChunkName: "SubAssembly/SubAssembly" */ 'integration/subAssembly/SubAssembly'
  ),
);
const Pocs = React.lazy(() =>
  import(/* webpackChunkName: "Pocs/PocsHome" */ 'components/pocs/PocsHome'),
);
const Tables = React.lazy(() =>
  import(/* webpackChunkName: "Table/Tables" */ 'components/table/Tables'),
);
const Home = React.lazy(() =>
  import(/* webpackChunkName: "Home/Home" */ 'components/home/Home'),
);
const ZionEngine = React.lazy(() =>
  import(
    /* webpackChunkName: "ZionEngine/ZionEngine" */ 'components/zionEngine/ZionEngine'
  ),
);
const Formatting = React.lazy(() =>
  import(
    /* webpackChunkName: "Formatting/Formatting" */ 'integration/formatting/Formatting'
  ),
);
const Viewer = React.lazy(() =>
  import(/* webpackChunkName: "Viewer/Viewer" */ 'components/viewer/Viewer'),
);
const FigureViewer = React.lazy(() =>
  import(
    /* webpackChunkName: "Viewer/Viewer" */ 'components/viewer/FigureViewer'
  ),
);

export const menuOptions = [
  {
    name: 'Home',
    path: '/home',
    options: { icon: 'home', logout: true },
    applicationSection: 'home',
  },
  {
    name: 'Integration',
    path: '/integration',
    options: { icon: 'sitemap', document: true },
    applicationSection: 'integration',
    role: 'all',
  },
  {
    name: 'Formatting',
    path: '/formatting',
    options: { icon: 'pencil alternate', document: true },
    applicationSection: 'formatting',
    role: 'all',
  },
  {
    name: 'SubAssembly',
    path: '/subAssembly/itemSettings',
    options: { icon: 'retweet', document: true },
    applicationSection: 'subAssembly',
    role: 'all',
  },
  {
    name: 'Viewer',
    path: '/viewer',
    options: { icon: 'file alternate', target: '_blank', document: true },
    applicationSection: 'viewer',
    role: 'all',
  },
  {
    name: 'FigureViewer',
    path: '/figureViewer',
    options: { icon: 'file alternate', target: '_blank', document: true },
    applicationSection: 'figureViewer',
    role: 'all',
  },
  {
    name: 'Tables',
    path: '/tables',
    options: { icon: 'list', target: '_blank', document: true },
    applicationSection: 'tables',
    role: 'all',
  },
  {
    name: '3D',
    path: '/3D',
    options: { icon: 'computer', logout: true },
    applicationSection: 'ZionEngine',
    role: 'all',
  },
  {
    name: 'Notifications',
    path: '/notifications',
    options: { icon: 'info' },
    applicationSection: 'notifications',
    role: 'all',
  },
];
export const makeNavInfo = (
  name,
  path,
  component,
  options = { exact: false },
) => ({
  name,
  path,
  component,
  options,
});
const navigationInfo = [
  makeNavInfo('Home', '/home', Home),
  makeNavInfo('3D', '/3D', ZionEngine),
  makeNavInfo('Viewer', '/viewer', Viewer),
  makeNavInfo('Integration', '/integration', IntegrationMenu),
  makeNavInfo('Formatting', '/formatting', Formatting),
  makeNavInfo('Status', '/status', Status),
  makeNavInfo('Notifications', '/notifications/', Notifications),
  makeNavInfo('Document import', '/documentImport', DocumentImport),
  makeNavInfo('Tables', '/tables', Tables),
  makeNavInfo('SubAssembly', '/subAssembly/itemSettings', SubAssembly),
  makeNavInfo('Pocs', '/pocs', Pocs),
  makeNavInfo('Figure Viewer', '/figureViewer', FigureViewer),
];
/**
 * Create React Router Switch
 * refer to https://reacttraining.com/react-router/web/api/Switch
 */
export const makeRouter = () => {
  const routes = navigationInfo.map(nav => (
    <Route
      key={nav.path}
      exact={nav.options.exact}
      path={nav.path}
      component={nav.component}
    />
  ));
  routes.push(
    <Route key="root" exact path="/" render={() => <Redirect to="/home" />} />,
    <Route key="401" exact path="/forbidden" component={Forbidden} />,
    <Route key="404" component={NotFound} />,
  );
  return (
    <Switch>
      <Suspense
        fallback={
          <StyledDimmer active inverted>
            <Loader />
          </StyledDimmer>
        }
      >
        {routes}
      </Suspense>
    </Switch>
  );
};
