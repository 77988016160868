import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';
import { isEmpty } from 'lodash';

/**
 * Return XmlText records group by documentLine value
 */
export const getDocumentLines = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.XmlText.getDocumentLine);

    const documentLines = await API.get(url, {
      DocumentId: getState().utils.currentDocument.id,
    });
    dispatch({
      type: types.LINES_GET_DOCUMENT_LINES,
      payload: !isEmpty(documentLines) ? documentLines : { noData: 'NO DATA' },
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};
