import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';
import { makeGetFigureTableSearch } from 'selectors/tableSelector';

import {
  pageButtonRenderer,
  idButtonRenderer,
  figureSheetRenderer,
} from 'components/table/containers/columnRenderers';
import M3dianTable from 'components/table/Table';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [
        {
          label: 'ID',
          cellRenderer: this.idRenderer,
          dataKey: 'id',
          width: 1 / 18,
        },
        {
          label: 'Page',
          cellRenderer: pageButtonRenderer,
          dataKey: 'documentPage',
          width: 1 / 18,
        },
        {
          label: 'Figure',
          cellRenderer: figureSheetRenderer,
          dataKey: 'figureSheet',
          width: 1 / 9,
        },
        {
          label: 'Description',
          dataKey: 'description',
          width: 7 / 9,
        },
      ],
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;
    this.props.actions.getTable('Figure');
    this.props.actions.changeTableHeader(this.state.header, this.props.modal);
  }

  componentDidUpdate(prevProps) {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;

    // In case of access when disconnected
    if (prevProps.auth.token !== this.props.auth.token)
      this.props.actions.getTable('Figure');

    // Handle document change
    if (prevProps.currentDocumentId !== this.props.currentDocumentId)
      this.props.actions.getTable('Figure');

    if (this.props.refresh) {
      this.props.actions.getTable('Figure');
      this.props.actions.refreshTable(true);
    }
  }

  idRenderer = data => idButtonRenderer(data, 'Figure');

  render() {
    const { figures, highlight, scrollTo, modal, gridStyle } = this.props;
    return (
      <M3dianTable
        modal={modal}
        name="figureTable"
        table="Figure"
        sortBy={['id', 'documentPage', 'figureSheet']}
        header={this.state.header}
        data={figures}
        highlight={highlight}
        scrollTo={scrollTo}
        gridStyle={gridStyle}
      />
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  figures: PropTypes.array,
  gridStyle: PropTypes.object,
  highlight: PropTypes.array,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
};

const makeMapStateToProps = () => {
  const getFigureTableSearch = makeGetFigureTableSearch();
  const mapStateToProps = (state, props) => {
    return {
      figures: getFigureTableSearch(state, props.modal),
      scrollTo: state.figureTable.scrollTo,
      highlight: state.figureTable.highlight,
      refresh: state.table.refresh,
    };
  };
  return mapStateToProps;
};

export const FigureTable =
  process.env.NODE_ENV !== 'test'
    ? connect(makeMapStateToProps())(Table)
    : Table;
