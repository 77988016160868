import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';
import { makeGetEquipmentTableSearch } from 'selectors/tableSelector';
import {
  figureButtonRenderer,
  idButtonRenderer,
} from 'components/table/containers/columnRenderers';

import M3dianTable from 'components/table/Table';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [
        {
          label: 'ID',
          cellRenderer: this.idRenderer,
          dataKey: 'id',
          width: 1 / 18,
        },
        {
          label: 'Part Number',
          dataKey: 'partNumber',
          width: 2 / 18,
        },
        {
          label: 'Description',
          dataKey: 'description',
          width: 6 / 18,
        },
        {
          label: 'Figure',
          cellRenderer: figureButtonRenderer,
          dataKey: 'figureDoc',
          width: 1 / 18,
        },
        {
          label: 'Eff.',
          dataKey: 'effectivity',
          width: 1 / 18,
        },
        {
          label: 'Lettering',
          cellRenderer: this.props.letteringRenderer && this.letteringRenderer,
          dataKey: 'lettering',
          width: 2 / 18,
          overflow: true,
        },
        {
          label: 'Model',
          dataKey: 'EquipmentRep.description',
          cellDataGetter: ({ rowData }) => rowData?.EquipmentRep?.description,
          width: 5 / 18,
        },
      ],
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;
    this.initEquipment();
    this.props.actions.changeTableHeader(this.state.header, this.props.modal);
  }

  componentDidUpdate(prevProps) {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;

    // In case of access when disconnected
    if (prevProps.auth.token !== this.props.auth.token) this.initEquipment();

    // Handle document change
    if (prevProps.currentDocumentId !== this.props.currentDocumentId)
      this.initEquipment();

    if (this.props.refresh) {
      this.initEquipment();
      this.props.actions.refreshTable(true);
    }
  }

  initEquipment = () => {
    this.props.actions.loadEquipmentTable();
  };

  idRenderer = data => idButtonRenderer(data, 'Equipment');

  letteringRenderer = data => this.props.letteringRenderer(data, this.tableRef);

  render() {
    const {
      equipments,
      highlight,
      scrollTo,
      height,
      modal,
      gridStyle,
    } = this.props;
    return (
      <M3dianTable
        tableRef={ref => (this.tableRef = ref)}
        modal={modal}
        name="equipmentTable"
        table="Equipment"
        sortBy={[
          'id',
          'partNumber',
          'figure',
          'effectivity',
          'side',
          'lettering',
        ]}
        header={this.state.header}
        height={height}
        data={equipments}
        highlight={highlight}
        scrollTo={scrollTo}
        gridStyle={gridStyle}
      />
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  equipments: PropTypes.array,
  gridStyle: PropTypes.object,
  height: PropTypes.number,
  highlight: PropTypes.array,
  letteringRenderer: PropTypes.func,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
};

const makeMapStateToProps = () => {
  const getEquipmentTableSearch = makeGetEquipmentTableSearch();
  const mapStateToProps = (state, props) => {
    return {
      equipments:
        props.equipments || getEquipmentTableSearch(state, props.modal),
      scrollTo: state.equipmentTable.scrollTo,
      highlight: state.equipmentTable.highlight,
      refresh: state.table.refresh,
    };
  };
  return mapStateToProps;
};

export const EquipmentTable =
  process.env.NODE_ENV !== 'test'
    ? connect(makeMapStateToProps())(Table)
    : Table;
