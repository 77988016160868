import React from 'react';
import * as types from 'config/types';
import { toast } from 'react-toastify';
import { closeSnackbar } from 'components/snackbar/snackbarActions';

const enqueue = {
  info: toast.info,
  success: toast.success,
  warning: toast.warning,
  error: toast.error,
};

const isHTML = message => {
  let doc = new DOMParser().parseFromString(message, 'text/html');
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

const snackbarMiddleware = store => next => action => {
  switch (action.type) {
    case types.SNACKBAR_ENQUEUE:
      (enqueue[action.notification.options.variant] || toast)(
        isHTML(action.notification.message) ? (
          <div
            dangerouslySetInnerHTML={{ __html: action.notification.message }}
          />
        ) : (
          action.notification.message
        ),
        {
          ...action.notification.options,
          // Workaround https://github.com/fkhadra/react-toastify/issues/371
          // When a lot of toasts are open on multiple tabs
          // some toasts can only be opened when tab get the focus
          // leading to toasts being opened a long delay
          onOpen: () => {
            if (
              !store
                .getState()
                .snackbar.toastIds.includes(action.notification.options.toastId)
            )
              toast.dismiss(action.notification.options.toastId);
          },
          onClose: () =>
            store.dispatch(closeSnackbar(action.notification.options.toastId)),
        },
      );
      next(action);
      break;

    case types.SNACKBAR_CLOSE:
      if (action.dismissAll) return toast.dismiss();
      toast.dismiss(action.toastId);
      next(action);
      break;

    case types.SNACKBAR_UPDATE:
      toast.update(action.toastId, {
        render: isHTML(action.notification.message) ? (
          <div
            dangerouslySetInnerHTML={{ __html: action.notification.message }}
          />
        ) : (
          action.notification.message
        ),
        type:
          action.notification?.options?.variant &&
          toast.TYPE[action.notification.options.variant.toUpperCase()],
        ...action.notification.options,
      });
      break;

    default:
      next(action);
      break;
  }
};

export default snackbarMiddleware;
