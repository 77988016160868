import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from 'redux-state-sync';
import { persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { enableBatching } from 'ais-utils/reduxBatch';

import rootReducer from 'config/reducers';
import * as types from 'config/types';
import history from 'config/history';
import * as API from 'lib/rest';
import stateSanitizer from 'config/debugSetup';
import { whereWasItDispatched } from 'lib/debugUtils';
import { getNotificationsForUser } from 'components/notifications/notificationActions';
import snackbarMiddleware from 'components/snackbar/snackbarMiddleware';

let composeEnhancers;

// Redux chrome debugger setup
process.env.NODE_ENV === 'development'
  ? (composeEnhancers = composeWithDevTools({ stateSanitizer }))
  : (composeEnhancers = compose);

const saveAuthToken = store => next => action => {
  if (action.type === types.LOGIN) {
    API.setToken(action.payload.token, store.dispatch);
    store.dispatch(
      getNotificationsForUser(
        action.payload.user.id,
        undefined,
        undefined,
        action.payload.token,
      ),
    );
  }

  if (action.type === types.LOGOUT) API.setToken(null, store.dispatch);

  if (
    action.type === 'persist/REHYDRATE' &&
    action.payload &&
    action.payload.auth &&
    action.payload.auth.user
  ) {
    API.setToken(action.payload.auth.token, store.dispatch);
    store.dispatch(
      getNotificationsForUser(
        action.payload.auth.user.id,
        undefined,
        undefined,
        action.payload.auth.token,
      ),
    );
  }

  return next(action);
};

const middlewares =
  process.env.NODE_ENV === 'test'
    ? []
    : [
        thunk,
        whereWasItDispatched,
        routerMiddleware(history),
        createStateSyncMiddleware({
          initiateWithState: true,
          whitelist: [
            'LOGIN',
            'LOGOUT',
            'CHANGE_CURRENT_DOCUMENT',
            'UPDATE_RECORD',
            'BATCH_UPDATE',
            'CREATE_RECORD',
            'DELETE_RECORD',
            'GET_TABLE',
            'VIEWER_GO_TO_PAGE',
            'VIEWER_TOGGLE_SECTION',
            'VIEWER_HIGHLIGHT_RECORDS',
            'NOTIFICATIONS_READ',
            'SNACKBAR_ENQUEUE',
            'SNACKBAR_CLOSE',
            'SNACKBAR_UPDATE',
            'UPDATE_STEP_PROGRESS',
            'SHOULD_RECONNECT_CHECK',
            'RECONNECT_CHECK_OK',
            'ITEM_EXTRACTION_NEW_RESULTS',
            'ANOMALIES_GET_FOR_DOCUMENT',
            'CHANGE_TABLE',
            'HIGHLIGHT_TABLE',
            'CHANGE_FIGURE_FILTER',
            'TABLE_TOGGLE_TYPE',
            'REFRESH_TABLE',
            'SECTION_CHANGE_SECTION',
            'REPRESENTATION_CHANGE_FIGURE_PARSER',
            'GET_INPUT_SYNC',
            'SELECT_TABLE',
          ],
        }),
        saveAuthToken,
        snackbarMiddleware,
      ];

export const store = createStore(
  connectRouter(history)(enableBatching(rootReducer)),
  {},
  composeEnhancers(applyMiddleware(...middlewares)),
);
initStateWithPrevTab(store);

export const persistor = persistStore(store);
