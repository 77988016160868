import produce from 'immer';

import * as types from 'config/types';

const initialState = {
  subAssemblyBySemanticGroup: [],
  layerBySemanticGroup: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.subAssemblyBySemanticGroup =
          initialState.subAssemblyBySemanticGroup;
        draft.layerBySemanticGroup = initialState.layerBySemanticGroup;
        break;

      case types.GET_SUBASSEMBLY_BY_SEMANTIC_GROUP:
        draft.subAssemblyBySemanticGroup = action.payload;
        break;

      case types.GET_LAYER_BY_SEMANTIC_GROUP:
        draft.layerBySemanticGroup = action.payload;
        break;

      default:
        break;
    }
  });
