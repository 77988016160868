import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  api: true,
  database: true,
  elasticsearch: true,
  version: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHECK_API_STATUS:
        draft.api = action.payload.api;
        draft.database = action.payload.database;
        draft.elasticsearch = action.payload.elasticsearch;
        draft.version = action.payload.version;
        break;

      default:
        break;
    }
  });
