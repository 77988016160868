import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import history from 'config/history';
import notifications from 'components/notifications/notificationReducer';
import engine from 'components/zionEngine/zionEngineReducer';
import auth from 'components/authentication/authReducer';
import viewer from 'components/viewer/viewerReducer';
import status from 'components/status/statusReducer';
import recordEditor from 'components/recordEditor/recordEditorReducer';
import locationEditor from 'components/locationEditor/locationEditorReducer';
import files from 'components/files/filesReducer';
import utils from 'components/utils/utilsReducer';
import document from 'integration/documentCreation/documentReducer';
import anomalies from 'components/anomalies/anomalyReducer';
import table from 'components/table/tableReducer';
import itemTable from 'components/table/tables/item/itemReducer';
import itemSettings from 'components/integration/subAssembly/itemSettings/itemSettingsReducer';
import figureSidebar from 'components/figureSidebar/figureSidebarReducer';
import figureTable from 'components/table/tables/figure/figureReducer';
import lineTable from 'components/table/tables/line/lineReducer';
import representationTable from 'components/table/tables/representation/representationReducer';
import pageTable from 'components/table/tables/page/pageReducer';
import equipmentTable from 'components/table/tables/equipment/equipmentReducer';
import descriptionTable from 'components/table/tables/description/descriptionReducer';
import stepProgress from 'components/stepProgress/stepProgressReducer';
import home from 'components/home/homeReducer';
import pocs from 'components/pocs/pocsReducer';
import section from 'integration/formatting/section/sectionReducer';
import equipment from 'integration/formatting/equipment/equipmentReducer';
import description from 'integration/formatting/description/descriptionReducer';
import itemRepresentation from 'integration/formatting/itemRepresentation/itemRepresentationReducer';
import figure from 'integration/formatting/figure/figureReducer';
import itemExtraction from 'integration/formatting/itemExtraction/itemExtractionReducer';
import stepNavigation from 'integration/integrationNavigationReducer';
import userChoices from 'integration/subAssembly/itemSettings/userChoices/userChoicesReducer';
import snackbar from 'components/snackbar/snackbarReducer';
import categoryEditor from 'components/categoryEditor/categoryEditorReducer';

// redux-persist config
const utilsPersistConfig = {
  key: 'utils',
  storage,
  whitelist: ['currentDocument', 'Document', 'currentLopa', 'Lopa'],
};

const itemRepresentationPersistConfig = {
  key: 'itemRepresentation',
  storage,
  whitelist: ['figureParser'],
};

const tablePersistConfig = key => ({
  key,
  storage,
  whitelist: ['type', 'figureFilter', 'highlight', 'selected'],
});

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const pocsPersistConfig = {
  key: 'pocs',
  storage,
  whitelist: ['currentFigure'],
};

const appReducer = combineReducers({
  auth,
  viewer,
  status,
  recordEditor,
  locationEditor,
  engine,
  files,
  snackbar,
  section,
  itemSettings: persistReducer(
    tablePersistConfig('itemSettingsTable'),
    itemSettings,
  ),
  figureSidebar,
  lineTable: persistReducer(tablePersistConfig('lineTable'), lineTable),
  pocs: persistReducer(pocsPersistConfig, pocs),
  itemTable: persistReducer(tablePersistConfig('itemTable'), itemTable),
  descriptionTable: persistReducer(
    tablePersistConfig('descriptionTable'),
    descriptionTable,
  ),
  figureTable: persistReducer(tablePersistConfig('figureTable'), figureTable),
  representationTable: persistReducer(
    tablePersistConfig('representationTable'),
    representationTable,
  ),
  pageTable: persistReducer(tablePersistConfig('pageTable'), pageTable),
  equipmentTable: persistReducer(
    tablePersistConfig('equipmentTable'),
    equipmentTable,
  ),
  table: persistReducer(tablePersistConfig('table'), table),
  equipment,
  description,
  itemRepresentation: persistReducer(
    itemRepresentationPersistConfig,
    itemRepresentation,
  ),
  stepNavigation,
  stepProgress,
  document,
  figure,
  notifications,
  anomalies,
  itemExtraction,
  home,
  userChoices,
  categoryEditor,
  utils: persistReducer(utilsPersistConfig, utils),
  router: connectRouter(history),
});

export default persistReducer(rootPersistConfig, appReducer);
