import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

export const getAnomaliesForDocument = (
  DocumentId,
  milestone = null,
  step = null,
) => async (dispatch, getState) => {
  try {
    const where = step
      ? {
          DocumentId: DocumentId
            ? DocumentId
            : getState().utils.currentDocument.id,
          milestone,
          step,
        }
      : milestone
      ? {
          DocumentId: DocumentId
            ? DocumentId
            : getState().utils.currentDocument.id,
          milestone,
        }
      : {
          DocumentId: DocumentId
            ? DocumentId
            : getState().utils.currentDocument.id,
        };
    const url = API.buildURL(network.ENDPOINTS.Anomaly.getForDocument);
    const anomalies = await API.get(url, where);
    dispatch({
      type: types.ANOMALIES_GET_FOR_DOCUMENT,
      payload: anomalies,
    });
  } catch (err) {
    console.error(err);
  }
};
