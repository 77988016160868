import produce from 'immer';

import * as types from 'config/types';

const initialState = {
  type: 'descriptionDoc',
  descriptionsTable: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.descriptionsTable = initialState.descriptionsTable;
        break;

      case types.TABLE_OPEN_MODAL:
        if (action.payload === 'description')
          draft.descriptionsTable = initialState.descriptionsTable;
        break;

      case types.TABLE_TOGGLE_TYPE:
        if (action.table === 'description') {
          draft.type = action.payload;
          draft.descriptionsTable = initialState.descriptionsTable;
        }
        break;

      case types.TABLE_TOGGLE_TYPE_NOT_SYNC:
        if (action.table === 'description') {
          draft.type = action.payload;
          draft.descriptionsTable = initialState.descriptionsTable;
        }
        break;

      case types.DESCRIPTION_TABLE_GET_DATA:
        draft.descriptionsTable = action.payload;
        break;

      default:
        break;
    }
  });
