import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';
import {
  makeGetDocumentPageTableSearch,
  getXmlTextCountAverages,
} from 'selectors/tableSelector';
import {
  pageButtonRenderer,
  idButtonRenderer,
  countAverageRenderer,
  sectionRenderer,
} from 'components/table/containers/columnRenderers';
import M3dianTable from 'components/table/Table';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [
        {
          label: 'ID',
          cellRenderer: this.idRenderer,
          dataKey: 'id',
          width: 1 / 4,
        },
        {
          label: 'Page',
          cellRenderer: pageButtonRenderer,
          dataKey: 'documentPage',
          width: 1 / 4,
        },
        {
          label: 'Section',
          cellRenderer: sectionRenderer,
          dataKey: 'section',
          width: 1 / 4,
        },
        {
          label: 'Count',
          cellRenderer: this.countRenderer,
          dataKey: 'count',
          width: 1 / 4,
          help: `Green when the page count is in +/- 50% of the section average`,
        },
      ],
    };
  }

  AVERAGE_INCERTITUDE = 0.5;

  countRenderer = data =>
    countAverageRenderer(
      data,
      this.props.countAverages,
      this.AVERAGE_INCERTITUDE,
    );

  idRenderer = data => idButtonRenderer(data, 'XmlPage');

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;
    this.initTable();
    this.props.actions.changeTableHeader(this.state.header, this.props.modal);
  }

  componentDidUpdate(prevProps) {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;

    // In case of access when disconnected
    if (prevProps.auth.token !== this.props.auth.token) this.initTable();

    // Handle document change
    if (prevProps.currentDocumentId !== this.props.currentDocumentId)
      this.initTable();

    if (this.props.refresh) {
      this.initTable();
      this.refreshTable(true);
    }
  }

  initTable = () => {
    this.props.actions.getTable('XmlPage');
    this.props.actions.getTableCounts('XmlText');
  };

  render() {
    const {
      xmlPages,
      highlight,
      scrollTo,
      height,
      modal,
      gridStyle,
    } = this.props;
    return (
      <M3dianTable
        modal={modal}
        name="pageTable"
        height={height}
        sortBy={['id', 'documentPage', 'section', 'count']}
        header={this.state.header}
        data={xmlPages}
        highlight={highlight}
        scrollTo={scrollTo}
        gridStyle={gridStyle}
      />
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object,
  countAverages: PropTypes.object,
  currentDocumentId: PropTypes.number,
  gridStyle: PropTypes.object,
  height: PropTypes.number,
  highlight: PropTypes.array,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  xmlPages: PropTypes.array,
};

const makeMapStateToProps = () => {
  const getDocumentPageTableSearch = makeGetDocumentPageTableSearch();
  const mapStateToProps = (state, props) => {
    return {
      xmlPages:
        props.xmlPages || getDocumentPageTableSearch(state, props.modal),
      scrollTo: state.pageTable.scrollTo,
      highlight: state.pageTable.highlight,
      refresh: state.table.refresh,
      countAverages: getXmlTextCountAverages(state),
    };
  };
  return mapStateToProps;
};

export const PageTable =
  process.env.NODE_ENV !== 'test'
    ? connect(makeMapStateToProps())(Table)
    : Table;
