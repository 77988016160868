import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';
import { getTable } from 'components/utils/utilsActions';
import { getNotificationsForDocument } from 'components/notifications/notificationActions';
import { getAnomaliesForDocument } from 'components/anomalies/anomalyActions';
import { viewerGoToPage } from 'components/viewer/viewerActions';
import { getStepProgress } from 'integration/integrationNavigationActions';
import { refreshTable } from 'components/table/tableActions';

/**
 * Return figure descriptions XmlText records for current document
 */
export const getFigureDescriptions = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.XmlText.getArea);
    const { input, documentPage } = getState().figure;
    const figureDescriptions = await API.get(url, {
      DocumentId: getState().utils.currentDocument.id,
      positionTopMin: input.y,
      positionTopMax: input.y + input.height,
      positionLeftMin: input.x,
      positionLeftMax: input.x + input.width,
    });
    dispatch({
      type: types.FIGURE_GET_DESCRIPTIONS,
      payload:
        figureDescriptions && figureDescriptions.length
          ? figureDescriptions
          : ['NO DATA'],
    });

    const currentFigureDescription = figureDescriptions.filter(
      xmlText => xmlText.documentPage === documentPage,
    );
    dispatch(
      viewerGoToPage(
        null,
        currentFigureDescription
          ? currentFigureDescription.map(xmlText => xmlText.id)
          : [],
      ),
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Change figure input
 * @param {*} input new input value
 */
export const changeFigureInput = (input = {}) => dispatch => {
  dispatch({
    type: types.FIGURE_CHANGE_INPUT,
    payload: input,
  });
};

/**
 * Change figure keyword
 * @param {*} keyword figure keyword
 */
export const changeFigureKeyword = (keyword = '') => dispatch => {
  dispatch({
    type: types.FIGURE_CHANGE_KEYWORD,
    payload: keyword,
  });
};

/**
 * Change figure regex
 * @param {*} regex figure regular expression
 */
export const changeFigureRegex = (regex = '') => dispatch => {
  dispatch({
    type: types.FIGURE_CHANGE_REGEX,
    payload: regex,
  });
};

/**
 * Process figure step
 */
export const processFigure = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.process);
    const { input, keyword, regex } = getState().figure;
    const progress = await API.post(url, {
      DocumentId: getState().utils.currentDocument.id,
      milestone: getState().figure.milestone,
      step: getState().figure.step,
      input: {
        positionTopMin: input.y,
        positionTopMax: input.y + input.height,
        positionLeftMin: input.x,
        positionLeftMax: input.x + input.width,
        keyword,
        regex,
      },
    });

    progress &&
      progress.message &&
      dispatch(
        enqueueSnackbar({
          message: progress.message,
          options: {
            variant: progress.success ? 'success' : 'error',
          },
        }),
      );

    dispatch(getStepProgress(getState().utils.currentDocument.id));
    dispatch(refreshTable());
    dispatch(getTable('Figure'));
    dispatch(getNotificationsForDocument());
    dispatch(
      getAnomaliesForDocument(
        getState().utils.currentDocument.id,
        getState().figure.milestone,
        getState().figure.step,
      ),
    );
  } catch (err) {
    console.error(err);
  }
};
