import React from 'react';
import PropTypes from 'prop-types';

import { NhaBkdnButtons } from './NhaBkdnButton';
import { itemButtonRenderer } from './columnRenderers';

const ItemDescription = React.memo(
  ({ description, item, hasNhdBkdnButtons, hasLineButton, table }) =>
    description && (
      <div style={styles.description} title={description}>
        <div style={styles.ellipsis}>
          {hasLineButton &&
            item?.XmlTexts?.length &&
            itemButtonRenderer({ rowData: item })}
          {description}
        </div>
        {hasNhdBkdnButtons && item && (
          <NhaBkdnButtons item={item} table={table} />
        )}
      </div>
    ),
);

const styles = {
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

ItemDescription.propTypes = {
  description: PropTypes.string,
  hasLineButton: PropTypes.bool,
  hasNhdBkdnButtons: PropTypes.bool,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  table: PropTypes.string,
};

export default ItemDescription;
