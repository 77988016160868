import * as types from 'config/types';

/**
 * Display a toast notification
 * @param {*} notification notification to display (see: https://iamhosseindhv.com/notistack/api)
 */
export const enqueueSnackbar = notification => {
  const toastId = notification.options && notification.options.toastId;

  return {
    type: types.SNACKBAR_ENQUEUE,
    notification: {
      ...notification,
      options: {
        ...(notification.options || {}),
        toastId: toastId || new Date().getTime() + Math.random(),
      },
    },
  };
};

/**
 * Close all/a toast notification
 * @param {?string} toastId notification key
 */
export const closeSnackbar = toastId => ({
  type: types.SNACKBAR_CLOSE,
  dismissAll: !toastId, // dismiss all if no key has been defined
  toastId,
});

/**
 * Update a notification
 * @param {?string} toastId notification key
 */
export const updateSnackbar = notification =>
  notification?.options?.toastId && {
    type: types.SNACKBAR_UPDATE,
    toastId: notification?.options?.toastId,
    notification,
  };
