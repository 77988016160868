import React from 'react';
import PropTypes from 'prop-types';

import { color } from 'config/constants';

const Indentation = React.memo(
  ({
    id,
    indentation,
    height,
    isSelected,
    isShadowSelected,
    isHighlighted,
  }) => {
    const array = [...new Array(indentation)];

    let backgroundColor = color.default;
    if (isShadowSelected) backgroundColor = color.table.shadowSelected.border;
    if (isHighlighted) backgroundColor = color.table.highlighted.border;
    if (isSelected) backgroundColor = color.table.selected.anchor;

    return (
      <div style={{ ...styles.container, height }}>
        {array.map((_, index) => {
          return (
            <div
              key={`${id}-${index}`}
              style={{ ...styles.indent, backgroundColor }}
            />
          );
        })}
      </div>
    );
  },
);

Indentation.propTypes = {
  height: PropTypes.number,
  id: PropTypes.number,
  indentation: PropTypes.number,
  isHighlighted: PropTypes.bool,
  isSelected: PropTypes.bool,
  isShadowSelected: PropTypes.bool,
};

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '1px 0px',
  },
  indent: {
    width: '5px',
    height: '100%',
    marginLeft: '1px',
  },
  text: { width: '50%', display: 'flex', alignItems: 'center' },
};

export default Indentation;
