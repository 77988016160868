import React from 'react';
import { Progress } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const DocumentProgress = React.memo(({ progress }) => (
  <Progress
    percent={progress}
    progress
    size="large"
    indicating
    autoSuccess
    style={style}
  />
));

const style = { margin: 'auto' };

DocumentProgress.propTypes = {
  progress: PropTypes.number,
};

export default DocumentProgress;
