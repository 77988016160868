import React from 'react';
import {
  GROUP_HEADER_ROW_HEIGHT,
  GROUP_HEADER_HEIGHT_DIFF,
  ROW_GAP,
} from '../utils';

export default ({
  style = {},
  rowData,
  isFirstRowAGroup = false,
  scrollTop = 0,
  groupData = [],
  renderer,
}) => {
  const top =
    style.top &&
    style.top + (isFirstRowAGroup ? 0 : GROUP_HEADER_HEIGHT_DIFF - ROW_GAP);

  return (
    <div
      className="table_row_title"
      style={{
        ...styles.blocHeader,
        ...style,
        top,
        height: GROUP_HEADER_ROW_HEIGHT,
        display: top - 4 <= scrollTop ? 'none' : 'flex',
        boxShadow: '0 3px 5px rgba(182, 182, 182, 0.75)',
      }}
    >
      {renderer ? renderer(rowData, groupData) : rowData}
    </div>
  );
};

const styles = {
  blocHeader: {
    alignItems: 'center',
    borderTopRightRadius: '.28571429rem',
    borderTopLeftRadius: '.28571429rem',
    backgroundColor: '#D1D7DB',
    paddingLeft: 5,
    paddingRight: 5,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
};
