export const color = {
  primary: '#2185D0',
  hardPrimary: '#0A2A42',
  success: '#21BA45',
  error: '#DB2828',
  warning: '#F2711C',
  default: '#DEDEDE',
  odd: '#BDDCF5',
  even: '#FCE1CF',
  highlight: '#FFE866',
  modified: '#F0F8FF',
  viewer: {
    page: { figure: 'olive', item: 'blue' },
    selected: '#FF99CC',
    hidden: '#CC0000',
    figure: '#B5CC18',
  },
  table: {
    shadowSelected: {
      border: 'rgb(33, 133, 208)',
      background: 'rgba(190, 220, 241, 0.5)',
    },
    selected: {
      border: 'rgb(174, 225, 175)',
      background: 'rgba(174, 225, 175, 0.6)',
      anchor: 'rgb(128, 173, 129)',
    },
    highlighted: {
      border: 'rgb(251, 189, 8)',
      background: 'rgba(249, 249, 185,  0.8)',
    },
  },
  grey: '#767676',
  yellow: '#FBBD08',
};

export const notificationTime = 3000;

export const XmlTextParsers = {
  GoogleVision: 'g_vision',
};
