import produce from 'immer';

import * as types from 'config/types';

const initialState = {
  equipmentData: {},
  highlight: [],
  scrollTo: null,
  filter: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        draft.filter = initialState.filter;
        draft.equipmentData = initialState.equipmentData;
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'equipmentTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.EQUIPMENT_GET_DATA:
        draft.equipmentData = action.payload;
        break;

      default:
        break;
    }
  });
