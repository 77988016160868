import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';
import { encodeWhere } from 'lib/utils';
import { batchActions } from 'ais-utils/reduxBatch';
import { getTable } from 'components/utils/utilsActions';

/**
 * Return Item table
 * @param {number or array} figureDoc figure doc or array of figure doc
 * @param {number or array} id Item id or array of item id
 * @param {?array} excludedTables array of table name to not include
 */
export const getItemData = (
  figureDoc = null,
  id = null,
  excludedTables = [],
) => async (dispatch, getState) => {
  if (figureDoc === null && id === null) return;

  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getData);

    let where = {
      DocumentId: getState().utils.currentDocument.id,
      excludedTables,
    };
    if (figureDoc && figureDoc !== 'all') where = { ...where, figureDoc };
    if (id) where = { ...where, id };

    const itemTable = await API.get(url, where);

    dispatch({
      type: types.ITEM_TABLE_GET_DATA,
      payload: itemTable ? itemTable : {},
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return Remark table
 */
export const getRemarkData = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Remark.getData);

    const where = {
      DocumentId: getState().utils.currentDocument.id,
    };

    const remarkTable = await API.get(url, where);
    dispatch({
      type: types.ITEM_TABLE_GET_REMARK_DATA,
      payload: remarkTable ? remarkTable : {},
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Toggle item table type between classic and remark
 */
export const toggleItemTableType = (type = null, sync = true) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: sync ? types.TABLE_TOGGLE_TYPE : types.TABLE_TOGGLE_TYPE_NOT_SYNC,
    table: 'item',
    payload:
      type || getState().itemTable.type === 'classic' ? 'remark' : 'classic',
  });
};

/**
 * Toggle only item with remarks display
 */
export const toggleOnlyRemarks = () => dispatch => {
  dispatch({
    type: types.ITEM_TABLE_TOGGLE_REMARK_ONLY,
  });
};

/**
 * Change item table filter
 * @param {*} filter object to match
 */
export const changeItemTableFilter = (filter = null) => dispatch => {
  dispatch({
    type: types.ITEM_TABLE_CHANGE_FILTER,
    payload: filter,
  });
};

/**
 * Return descendant item ids
 * @param {array} ids array of item.id
 */
export const getItemDescendants = ids => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getItemDescendants);

    const where = {
      DocumentId: getState().utils.currentDocument.id,
      ids,
    };

    return API.post(url, where);
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return item table records with NHA/BKDN for current document
 * @param {number or array} figureDoc figure doc or array of figure doc
 * @param {number or array} id Item id or array of item id
 */
export const getNhaAndBkdn = (_where = null) => async (dispatch, getState) => {
  const table = 'Item';

  try {
    const url = API.buildURL(network.ENDPOINTS.Item.getNhaAndBkdn);

    const where = encodeWhere(_where);

    const nhaBkdn = await API.get(url, {
      DocumentId: getState().utils.currentDocument.id,
      ...where,
    });

    dispatch({
      type: types.ITEM_TABLE_GET_NHA_BKDN,
      table,
      payload: nhaBkdn,
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return Effectivity distribution
 */
export const getEffectivityDistribution = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.location.getEffectivityDistribution,
    );

    const where = {
      DocumentId: getState().utils.currentDocument.id,
    };

    const effectivityDistribution = await API.get(url, where);
    effectivityDistribution &&
      dispatch({
        type: types.ITEM_TABLE_GET_EQUIPMENT_DISTRIBUTION,
        payload: effectivityDistribution,
      });
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loadItemTable = (init = false) => async (dispatch, getState) => {
  const { type, filter, figureFilter } = getState().itemTable;

  const actions = [];

  const hasFigureFilter = filter === null && figureFilter !== null;
  const hasFilter = filter !== null;
  const isRemarkTable = type === 'remark';

  if (hasFigureFilter || hasFilter) {
    const where = figureFilter &&
      figureFilter.id !== 'all' && {
        figureDoc: figureFilter.figureDoc,
      };
    actions.push(
      getTable('Item', filter || where),
      getNhaAndBkdn(filter || where),
    );
  }
  if (hasFigureFilter) actions.push(getItemData(figureFilter.figureDoc));
  if (isRemarkTable) actions.push(getRemarkData());

  if (init) {
    actions.push(
      getEffectivityDistribution(),
      getTable('SubAssemblyCategory', {
        DocumentId: getState().utils.currentDocument.id,
      }),
    );
  }

  dispatch(batchActions(actions));
};
