import * as types from 'config/types';

export * from './tables/item/itemActions';
export * from './tables/description/descriptionActions';
export * from './tables/line/lineActions';
export * from './tables/equipment/equipmentActions';

/**
 * Select a record in a table
 * @param {string} table table name to select
 * @param {array} selected array of record to select
 */
export const selectTable = (table, selected = []) => dispatch => {
  dispatch({
    type: types.SELECT_TABLE,
    table,
    payload: { selected },
  });
};

/**
 * Change current figure
 * @param {Object} figure figure to display
 */
export const changeFigureFilter = (table, figure) => dispatch => {
  dispatch({
    type: types.CHANGE_FIGURE_FILTER,
    table,
    payload: figure,
  });
};

/**
 * Highlight and scroll to table record
 * @param {string} table table name to highlight
 * @param {array} highlight array of record to highlight
 * @param {*} scrollTo id or object to highlight
 * @param {*} figure figure filter value to set
 */
export const highlightTable = (
  table,
  highlight = [],
  scrollTo = null,
  figure = null,
) => dispatch => {
  dispatch({
    type: types.HIGHLIGHT_TABLE,
    table,
    payload: { highlight, scrollTo },
  });
  if (figure)
    dispatch({
      type: types.CHANGE_FIGURE_FILTER,
      table,
      payload: figure,
    });
};

/**
 * Change displayed table
 * @param {string} name table name to display
 */
export const changeTable = (name, sync = true) => dispatch => {
  dispatch({
    type: sync ? types.CHANGE_TABLE : types.CHANGE_TABLE_NOT_SYNC,
    payload: name,
  });
};

/**
 * Refresh displayed table
 * @param {bool} refreshed table has been refreshed
 */
export const refreshTable = (refreshed = false) => dispatch => {
  dispatch({
    type: types.REFRESH_TABLE,
    payload: !refreshed,
  });
};

/**
 * Change table header
 * @param {*} header header to use
 * @param {bool} modal is modal
 */
export const changeTableHeader = (header, modal = false) => dispatch => {
  dispatch({
    type: types.CHANGE_TABLE_HEADER,
    payload: { header, modal },
  });
};

/**
 * Change search value
 * @param {string} search value to search
 * @param {bool} modal is modal
 */
export const changeSearch = (search, modal = false) => dispatch => {
  dispatch({
    type: types.TABLE_CHANGE_SEARCH,
    payload: { search, modal },
  });
};

/**
 * Change search column
 * @param {string} column column name
 * @param {bool} modal is modal
 */
export const changeSearchColumn = (column, modal = false) => dispatch => {
  dispatch({
    type: types.TABLE_CHANGE_SEARCH_COLUMN,
    payload: { column, modal },
  });
};

/**
 * Open table modal
 * @param {string} type table to display
 */
export const openTableModal = type => dispatch => {
  dispatch({
    type: types.TABLE_OPEN_MODAL,
    payload: type,
  });
};

/**
 * Close table modal
 */
export const closeTableModal = () => dispatch => {
  dispatch({
    type: types.TABLE_CLOSE_MODAL,
  });
};
