import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { Chart, registerables } from 'chart.js';
import { Dimmer, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { color } from 'config/constants';

import 'react-virtualized/styles.css';
import './style.css';
import './animation.css';

import history from 'config/history';
import { store, persistor } from 'config/store';
import registerServiceWorker from 'config/serviceWorker';

if (process.env.NODE_ENV !== 'test') Chart.register(...registerables);

const StyledDimmer = styled(Dimmer)`
  && {
    top: unset !important;
    left: unset !important;
    background-color: rgb(240, 240, 240) !important;
    font-size: 3em;
    font-weight: 900;
  }
  .content {
    color: ${color.primary} !important;
  }
`;

const App = React.lazy(() => import(/* webpackChunkName: "App" */ 'App'));

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Suspense
          fallback={
            <StyledDimmer active>
              <Icon size="big" name="magic" />
              M3DIAN
            </StyledDimmer>
          }
        >
          <App />
        </Suspense>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
