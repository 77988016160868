import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';

import connect from 'lib/reduxConnect';

const Button = React.memo(
  ({ highlight = [], text, record, actions }) =>
    text && record?.Figure?.documentPage ? (
      <SemanticButton
        compact
        circular
        color={highlight.length > 0 ? 'green' : 'grey'}
        size="tiny"
        title={text}
        style={styles.button}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          actions.viewerGoToPage(record.Figure.documentPage, highlight);
        }}
      >
        {text}
      </SemanticButton>
    ) : record && record.figureDoc ? (
      record.figureDoc
    ) : null,
  (prevProps, nextProps) => {
    const isHighlightEqual = equal(nextProps.highlight, prevProps.highlight);
    const isTextEqual = nextProps.text === prevProps.text;

    return isHighlightEqual && isTextEqual;
  },
);

const FigureButton =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

FigureButton.propTypes = {
  highlight: PropTypes.array,
  record: PropTypes.object,
  text: PropTypes.string,
};

const styles = {
  button: { overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' },
};

export default FigureButton;
