import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import history from 'config/history';
import connect from 'lib/reduxConnect';
import { routeStep } from 'integration/utils';

const Button = React.memo(({ step, document, actions }) => (
  <div
    tabIndex={0}
    role="button"
    style={style}
    title={step?.name}
    onClick={() => {
      if (step) {
        actions.changeCurrentDocument(document);
        routeStep(step, history);
      }
    }}
  >
    {step?.name ? <Icon name="play circle" /> : null}
    {step?.name}
  </div>
));

const LastStepButton =
  process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

const style = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
};

Button.propTypes = {
  document: PropTypes.object,
  step: PropTypes.object,
};

export default LastStepButton;
