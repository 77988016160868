import produce from 'immer';
import { isEmpty } from 'lodash';

import * as types from 'config/types';

const initialState = {
  milestone: 'Formatting',
  step: 'Equipment extraction',
  selected: [],
  itemNumerics: [],
  figureDocs: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.figureDocs = initialState.figureDocs;
        draft.itemNumerics = initialState.itemNumerics;
        break;

      case types.SELECT_TABLE:
        if (action.table === 'equipmentItemTable')
          draft.selected = action.payload.selected;
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (action.table === 'Item') {
          draft.selected = state.selected.filter(value =>
            action.payload.some(item => item.id === value),
          );
        }
        break;

      case types.EQUIPMENT_CHANGE_FILTER:
        if (action.payload.itemNumerics)
          draft.itemNumerics = action.payload.itemNumerics;
        if (action.payload.figureDocs)
          draft.figureDocs = action.payload.figureDocs;
        break;

      case types.GET_INPUT:
        if (
          action.milestone === initialState.milestone &&
          action.step === initialState.step &&
          !isEmpty(action.payload)
        ) {
          if (action.payload.itemNumerics)
            draft.itemNumerics = action.payload.itemNumerics;
          if (action.payload.figureDocs)
            draft.figureDocs = action.payload.figureDocs;
          if (action.payload.excluded) draft.selected = action.payload.excluded;
        }
        break;

      case types.GET_DEFAULT_INPUT:
        if (
          action.milestone === initialState.milestone &&
          action.step === initialState.step &&
          !isEmpty(action.payload)
        ) {
          if (action.payload.itemNumerics) {
            draft.itemNumerics = action.payload.itemNumerics;
          }
          if (action.payload.figureDocs)
            draft.figureDocs = action.payload.figureDocs;
        }
        break;

      case types.ADD_SELECTED_VALUE:
        if (action.selector !== 'equipment') break;

        if (
          action.payload.figureDoc &&
          !state.figureDocs.includes(action.payload.figureDoc)
        ) {
          draft.figureDocs = [...state.figureDocs, action.payload.figureDoc];
        }

        if (
          action.payload.itemNumeric &&
          !state.itemNumerics.includes(action.payload.itemNumeric)
        ) {
          draft.itemNumerics = [
            ...state.itemNumerics,
            action.payload.itemNumeric,
          ];
        }
        break;

      case types.REMOVE_SELECTED_VALUE:
        if (action.payload.figureDoc) {
          const filteredFigures = [...state.figureDocs].filter(
            filteredFigure => filteredFigure !== action.payload.figureDoc,
          );
          draft.figureDocs = filteredFigures;
        }

        if (action.payload.itemNumeric) {
          const filteredItemNums = [...state.itemNumerics].filter(
            filteredItemNum => filteredItemNum !== action.payload.itemNumeric,
          );
          draft.itemNumerics = filteredItemNums;
        }
        break;

      default:
        break;
    }
  });
