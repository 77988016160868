import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';
import { getTable, updateDocumentStatus } from 'components/utils/utilsActions';
import { getStepProgress } from 'integration/integrationNavigationActions';
import { getNotificationsForDocument } from 'components/notifications/notificationActions';
import { toggleViewerSection } from 'components/viewer/viewerActions';
import { refreshTable } from 'components/table/tableActions';

/**
 * Change keywords
 * @param {*} keyword keyword
 */
export const changeSectionKeyword = keyword => dispatch => {
  dispatch({
    type: types.SECTION_CHANGE_KEYWORD,
    payload: keyword,
  });
};

/**
 * Change section
 * @param {*} section section
 */
export const changeSection = (section = null) => (dispatch, getState) => {
  const currentSection = getState().section.section;
  if (currentSection === section || section === false) {
    dispatch({
      type: types.SECTION_CHANGE_SECTION,
      payload: null,
    });
    return dispatch(toggleViewerSection());
  }
  dispatch({
    type: types.SECTION_CHANGE_SECTION,
    payload: section,
  });
  dispatch(toggleViewerSection(section));
};

/**
 * Process section step
 */
export const processSection = input => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.process);
    const progress = await API.post(url, {
      DocumentId: getState().utils.currentDocument.id,
      milestone: getState().section.milestone,
      step: getState().section.step,
      input,
    });

    progress &&
      progress.message &&
      dispatch(
        enqueueSnackbar({
          message: progress.message,
          options: {
            variant: progress.success ? 'success' : 'error',
          },
        }),
      );
    if (progress && progress.success)
      dispatch(
        updateDocumentStatus(
          getState().utils.currentDocument.id,
          'In Progress',
        ),
      );

    dispatch(getStepProgress(getState().utils.currentDocument.id));
    dispatch(refreshTable());
    dispatch(getTable('XmlPage'));
    dispatch(getNotificationsForDocument());
  } catch (err) {
    console.error(err);
  }
};
