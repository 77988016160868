import { createSelector } from 'reselect';
import { toObject, groupBy, objMap, sortByFields } from 'ais-utils';

import {
  formatDocumentLine,
  destructureEffectivity,
  destructureLocationList,
  shouldItemBeIgnored,
  getItemEligibleLocations,
  getItemLocations,
} from 'lib/utils';

import {
  getItems as _getItems,
  getEquipments as _getEquipments,
  getFigures as _getFigures,
  getXmlPages as _getXmlPages,
  getItemCoordinates as _getItemCoordinates,
  getSubAssemblyCategories as _getSubAssemblyCategories,
  getLocationCategory as _getLocationCategory,
} from './utilsSelector';

import { searchData } from 'components/table/utils';
/*
  Trick to prevent false circular dependency
  https://github.com/reduxjs/reselect/issues/169
*/
const getItems = state => _getItems(state);
const getLocationCategory = state => _getLocationCategory(state);
const getEquipments = state => _getEquipments(state);
const getFigures = state => _getFigures(state);
const getXmlPages = state => _getXmlPages(state);
const getItemCoordinates = state => _getItemCoordinates(state);
const getSubAssemblyCategories = state => _getSubAssemblyCategories(state);

export const getSearchColumn = (state, modal) =>
  modal ? state.table.modalSearchColumn : state.table.searchColumn;
export const getSearchValue = (state, modal) =>
  modal ? state.table.modalSearch : state.table.search;
export const getTableHeader = (state, modal) =>
  modal ? state.table.modalHeader : state.table.header;

export const getDescriptionTable = state =>
  state.descriptionTable.descriptionsTable;

export const getItemTableType = state => state.itemTable.type;
export const getItemFigureFilter = state => state.itemTable.figureFilter;
export const getItemTableFilter = state => state.itemTable.filter;
export const getItemsExtraData = state => state.itemTable.itemTable;
export const getItemsNhaBkdnLinks = state => state.itemTable.nhaBkdn;
export const getItemsOnlyWithRemarks = state => state.itemTable.onlyRemarks;
export const getRemarksExtraData = state => state.itemTable.remarkTable;
export const getEffectivityDistribution = state =>
  state.itemTable.effectivityDistribution;

export const getEquipmentsExtraData = state =>
  state.equipmentTable.equipmentData;

export const getXmlTextCounts = state => state.pageTable.xmlTextCounts;

export const getSection = state => state.section.section;

export const getRepresentationFigureFilter = state =>
  state.representationTable.figureFilter;

export const getDocumentLines = state => state.lineTable.documentLines;

export const getFilteredItems = dataFilter =>
  dataFilter
    ? createSelector(
        getItems,
        dataFilter,
        getItemTableFilter,
        (items = [], filter = null, itemFilters) => {
          const [noData] = items;
          return noData !== 'NO DATA'
            ? filter || itemFilters
              ? items.filter(item =>
                  itemFilters
                    ? Object.keys(itemFilters).every(
                        key => item[key] === itemFilters[key],
                      )
                    : filter.id === 'all' ||
                      item.figureDoc === filter.figureDoc,
                )
              : items
            : [noData];
        },
      )
    : getItems;

export const getItemTable = (filter, type = () => undefined) =>
  createSelector(
    type,
    getFilteredItems(filter),
    getItemsExtraData,
    getRemarksExtraData,
    getItemsOnlyWithRemarks,
    getItemsNhaBkdnLinks,
    getEffectivityDistribution,
    getSubAssemblyCategories,
    getLocationCategory,
    (
      type = 'classic',
      items = [],
      itemsExtraData = {},
      descriptionExtraData = {},
      onlyRemarks = false,
      nhaBkdnLinks = {},
      effectivityDistribution = false,
      subAssemblyCategories = {},
      _locationCategories = [],
    ) => {
      const locationCategories = toObject(_locationCategories, 'category');
      if (items.length === 0) return items;

      const itemDisplayed =
        type !== 'remark'
          ? items
          : items.filter(
              item => !onlyRemarks || descriptionExtraData[item.documentLine],
            );

      const [noData] = itemDisplayed;
      if (noData === 'NO DATA') return itemDisplayed;

      return itemDisplayed.length
        ? itemDisplayed.map(item => {
            const extraData = itemsExtraData[item.id] || {};
            const remark = descriptionExtraData[item.documentLine] || [];
            const nhaBkdnLink = nhaBkdnLinks[item.id] || {};

            const effectivityLocation = getLocationEffectivity(
              item,
              subAssemblyCategories,
              effectivityDistribution,
            );

            const formattedEffectivityLocation = formatEffectivityLocationValue(
              item,
              effectivityLocation,
              subAssemblyCategories,
              effectivityDistribution,
            );

            const isAttachingPart =
              item.itemNumeric % 1 !== 0 || item.itemDoc === null;

            return {
              ...item,
              isAttachingPart,
              effectivityLocation: formattedEffectivityLocation,
              locations: [
                ...new Set(Object.values(effectivityLocation).flat()),
              ].sort(),
              LocationCategory:
                locationCategories[extraData?.SubAssemblyCategory?.category] ||
                null,
              ...nhaBkdnLink,
              ...extraData,
              remark,
              remarkCount: remark.length,
            };
          })
        : ['NO DATA'];
    },
  );

/**
 * Return locations by effectivity for an item
 * @param {Object} item item record
 * @param {Object} subAssemblyCategories SubAssemblyCategory for current document
 * @param {Object} effectivityDistribution eligible locations by figureDoc-effectivity
 */
const getLocationEffectivity = (
  item,
  subAssemblyCategories,
  effectivityDistribution,
) => {
  const itemEffectivities = destructureEffectivity(item.effectivity);
  let itemLocations = destructureLocationList(item.locationList);

  // If no locationList set, use locations from category
  if (!itemLocations.length && subAssemblyCategories[item.SubAssemblyId])
    itemLocations = destructureLocationList(
      subAssemblyCategories[item.SubAssemblyId].LocationCategory?.locations,
    );

  const eligibleLocations = getItemEligibleLocations(
    item.figureDoc,
    itemEffectivities,
    effectivityDistribution,
  );

  // Get item locations grouped by effectivity value
  return getItemLocations(itemLocations, eligibleLocations);
};

/**
 * Format effectivity - location data to be displayed
 * @param {Object} item item record
 * @param {Object} effectivityLocations locations by effectivity for the item
 * @param {Object} subAssemblyCategories SubAssemblyCategory for current document
 * @param {Object} effectivityDistribution eligible locations by figureDoc-effectivity
 */
const formatEffectivityLocationValue = (
  item,
  effectivityLocations,
  subAssemblyCategories,
  effectivityDistribution,
) => {
  // Loading state
  if (effectivityDistribution === false) return false;
  if (
    !Object.keys(subAssemblyCategories).length ||
    !Object.keys(effectivityDistribution).length
  )
    return item.effectivity || '';

  const itemEffectivities = destructureEffectivity(item.effectivity);

  // Return effectivities for ignored items
  if (shouldItemBeIgnored(item)) return { NONE: itemEffectivities };

  // Group effectivityLocations by locations
  const groupedEffectivityLocations = groupBy(
    Object.entries(effectivityLocations).map(([key, value]) => ({
      effectivity: key,
      location: value,
    })),
    item => item.location.join(','),
  );

  const formattedEffectivityLocations = objMap(
    groupedEffectivityLocations,
    effectivityLocation => effectivityLocation.map(e => e.effectivity),
  );

  // Return effectivities for items with effectivity not linked to an equipment
  if (!Object.keys(formattedEffectivityLocations).length)
    return {
      'NOT LINKED': (itemEffectivities.length && itemEffectivities) || null,
    };

  return formattedEffectivityLocations;
};

export const makeGetItemTableSearch = (filter, type) => {
  return createSelector(
    getItemTable(filter, type),
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, header, searchColumn, search) => {
      return searchData(header, data, search, searchColumn);
    },
  );
};

export const makeGetDescriptionTableSearch = () => {
  return createSelector(
    getDescriptionTable,
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, header, searchColumn, search) => {
      return searchData(header, data, search, searchColumn);
    },
  );
};

export const makeGetFigureTableSearch = () => {
  return createSelector(
    getFigures,
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, header, searchColumn, search) => {
      return searchData(header, data, search, searchColumn);
    },
  );
};

export const getEquipmentTable = createSelector(
  getEquipments,
  getEquipmentsExtraData,
  (equipments = [], equipmentsExtraData = {}) => {
    const [noData] = equipments;
    return noData !== 'NO DATA'
      ? equipments.map(equipment => {
          const extraData = equipmentsExtraData[equipment.id];
          return {
            ...equipment,
            ...extraData,
          };
        })
      : [noData];
  },
);

export const makeGetEquipmentTableSearch = () => {
  return createSelector(
    getEquipmentTable,
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, header, searchColumn, search) => {
      return searchData(header, data, search, searchColumn);
    },
  );
};

export const getDocumentPageTable = createSelector(
  getXmlPages,
  getXmlTextCounts,
  (xmlPages = [], xmlTextCounts = {}) => {
    const [noData] = xmlPages;
    return noData === 'NO DATA'
      ? [noData]
      : xmlPages.map(xmlPage => {
          const count = xmlTextCounts[xmlPage.id];

          const data = {
            ...xmlPage,
            count: count && count.count ? count.count : 0,
          };
          // set section to blank if not figure or item
          return ['figure', 'item'].includes(xmlPage.section)
            ? data
            : { ...data, section: 'blank' };
        });
  },
);

export const getXmlTextCountAverages = createSelector(
  getDocumentPageTable,
  (xmlPages = []) => {
    const [noData] = xmlPages;
    if (noData === 'NO DATA') return {};
    return xmlPages.reduce((averages, xmlPage) => {
      averages[xmlPage.section] = averages[xmlPage.section] || {
        count: 0,
        quantity: 0,
      };
      averages[xmlPage.section].count += xmlPage.count;
      averages[xmlPage.section].quantity++;
      return averages;
    }, {});
  },
);

export const makeGetDocumentPageTableSearch = () => {
  return createSelector(
    getDocumentPageTable,
    getSection,
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, section, header, searchColumn, search) => {
      return searchData(
        header,
        data.filter(datum => (section ? datum.section === section : true)),
        search,
        searchColumn,
      );
    },
  );
};

export const filterItemCoordinates = (itemCoordinates = [], filter) => {
  const [noData] = itemCoordinates;
  return noData !== 'NO DATA'
    ? filter
      ? itemCoordinates.filter(
          itemCoordinate =>
            filter.id === 'all' || itemCoordinate.FigureId === filter.id,
        )
      : itemCoordinates
    : [noData];
};

export const getFilteredItemCoordinates = (dataFilter = null) =>
  dataFilter
    ? createSelector(getItemCoordinates, dataFilter, filterItemCoordinates)
    : getItemCoordinates;

export const getRepresentationTable = createSelector(
  getFilteredItemCoordinates(getRepresentationFigureFilter),
  getFigures,
  (itemCoordinates = [], figures = []) => {
    const [noData] = itemCoordinates;
    const figuresObject = toObject(figures, 'id');
    return noData !== 'NO DATA'
      ? figures.length
        ? sortByFields(
            itemCoordinates.map(itemCoordinate => {
              const figure = figuresObject[itemCoordinate.FigureId];

              return {
                ...itemCoordinate,
                ...{
                  figureDoc: figure.figureDoc,
                  figureSheet: figure.figureSheet,
                  figurePage: figure.documentPage,
                },
              };
            }),
            ['figureSheet', 'ASC'],
            ['itemNumeric', 'ASC'],
            ['itemDoc', 'ASC'],
            false,
          )
        : ['NO DATA']
      : [noData];
  },
);

export const makeGetRepresentationTableSearch = () => {
  return createSelector(
    getRepresentationTable,
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, header, searchColumn, search) => {
      return searchData(header, data, search, searchColumn);
    },
  );
};

export const getLineTable = createSelector(
  getDocumentLines,
  (documentLines = {}) => {
    const { noData } = documentLines;
    return noData === 'NO DATA'
      ? [noData]
      : formatDocumentLine('documentLine', documentLines);
  },
);

export const makeGetLineTableSearch = () => {
  return createSelector(
    getLineTable,
    getTableHeader,
    getSearchColumn,
    getSearchValue,
    (data, header, searchColumn, search) => {
      return searchData(header, data, search, searchColumn);
    },
  );
};
