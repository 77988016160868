import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  open: false,
  table: null,
  attributes: [],
  record: {},
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.OPEN_RECORD_MODAL:
        draft.open = action.payload.open;
        draft.table = action.table;
        draft.attributes = action.payload.attributes;
        draft.record = action.payload.record;
        break;

      case types.CLOSE_RECORD_MODAL:
        draft.open = initialState.open;
        draft.table = initialState.table;
        draft.attributes = initialState.attributes;
        draft.record = initialState.record;
        break;

      case types.UPDATE_RECORD:
        if (
          state.open === true &&
          state.table === action.table &&
          state.record.id === action.payload.id
        )
          draft.record = action.payload;
        break;

      case types.CREATE_RECORD:
        if (state.open === true && state.table === action.table)
          draft.record = action.payload;
        break;

      case types.DELETE_RECORD:
        if (
          state.open === true &&
          state.table === action.table &&
          state.record.id === action.payload.id
        ) {
          draft.record.id = undefined;
        }
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.open = initialState.open;
        draft.table = initialState.table;
        draft.attributes = initialState.attributes;
        draft.record = initialState.record;
        break;

      default:
        break;
    }
  });
