import produce from 'immer';
import { isEmpty } from 'lodash';

import { isNotNullish } from 'lib/utils';
import * as types from 'config/types';

const initialState = {
  milestone: 'Formatting',
  step: 'Item representation',
  input: {},
  documentPage: null,
  figureParser: undefined,
  itemsRepresentation: [],
  highlight: [],
  scrollTo: null,
};

const parseInputCoords = (draft, input) => {
  if (input.positionTopMin) draft.input.y = parseInt(input.positionTopMin, 10);
  if (input.positionTopMin && input.positionTopMax)
    draft.input.height = parseInt(
      input.positionTopMax - input.positionTopMin,
      10,
    );
  if (isNotNullish(input.positionLeftMin))
    draft.input.x = parseInt(input.positionLeftMin, 10);
  if (isNotNullish(input.positionLeftMin) && input.positionLeftMax)
    draft.input.width = parseInt(
      input.positionLeftMax - input.positionLeftMin,
      10,
    );
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.REPRESENTATION_CHANGE_INPUT:
        draft.input = action.payload;
        break;

      case types.REPRESENTATION_GET_ITEMS:
        draft.itemsRepresentation = action.payload;
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'representationResultTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.GET_INPUT:
        if (
          action.milestone === initialState.milestone &&
          action.step === initialState.step &&
          !isEmpty(action.payload)
        ) {
          parseInputCoords(draft, action.payload);
          if (action.payload.figureParser && state.figureParser === undefined)
            draft.figureParser = action.payload.figureParser;
        }
        break;

      case types.GET_INPUT_SYNC:
        if (
          action.milestone === initialState.milestone &&
          action.step === initialState.step &&
          action.payload.figureParser
        )
          draft.figureParser = action.payload.figureParser;

        break;

      case types.REPRESENTATION_CHANGE_FIGURE_PARSER:
        draft.figureParser =
          state.figureParser === action.payload ? null : action.payload;
        draft.itemsRepresentation = initialState.itemsRepresentation;
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.documentPage = initialState.documentPage;
        draft.input = initialState.input;
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        draft.itemsRepresentation = initialState.itemsRepresentation;
        draft.figureParser = initialState.figureParser;
        break;

      case types.GET_TABLE:
        if (
          action.table === 'XmlPage' &&
          state.documentPage === initialState.documentPage
        ) {
          const xmlPage = action.payload.find(
            xmlPage => xmlPage.section === 'figure',
          );
          draft.documentPage = xmlPage
            ? xmlPage.documentPage
            : initialState.documentPage;
        }
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (
          action.table === 'XmlPage' &&
          state.documentPage === initialState.documentPage
        ) {
          const xmlPage = action.payload.find(
            xmlPage => xmlPage.section === 'figure',
          );
          draft.documentPage = xmlPage
            ? xmlPage.documentPage
            : initialState.documentPage;
        }
        break;

      case types.VIEWER_GO_TO_PAGE:
        if (action.payload.page) draft.documentPage = action.payload.page;
        break;

      case types.CREATE_RECORD:
        if (action.table !== 'XmlText' || action.payload.visible === 0) break;

        if (
          action.payload.positionTop > state.input.y &&
          action.payload.positionTop < state.input.y + state.input.height &&
          action.payload.positionLeft > state.input.x &&
          action.payload.positionLeft < state.input.x + state.input.width
        )
          draft.itemsRepresentation = [
            ...state.itemsRepresentation,
            action.payload,
          ];

        break;

      case types.UPDATE_RECORD: {
        if (action.table !== 'XmlText') break;

        const itemsRepresentation = state.itemsRepresentation.filter(
          XmlText => XmlText.id !== action.payload.id,
        );

        if (action.payload.visible === 0) {
          draft.itemsRepresentation = itemsRepresentation;
          break;
        }

        draft.itemsRepresentation = [...itemsRepresentation, action.payload];
        break;
      }

      case types.DELETE_RECORD:
        if (action.table === 'XmlText')
          draft.itemsRepresentation = state.itemsRepresentation.filter(
            XmlText => XmlText.id !== action.payload.id,
          );
        break;

      default:
        break;
    }
  });
