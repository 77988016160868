import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  section: 'all',
  currentPage: 0,
  highlight: [],
  editor: { open: false, table: null, attributes: [], record: {} },
  hideMode: false,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.VIEWER_TOGGLE_SECTION:
        draft.section = action.payload;
        draft.currentPage = initialState.currentPage;
        draft.editor.open = initialState.editor.open;
        draft.editor.table = initialState.editor.table;
        draft.editor.record = initialState.editor.record;
        break;

      case types.VIEWER_TOGGLE_HIDE_MODE:
        draft.hideMode = !state.hideMode;
        draft.editor.open = initialState.editor.open;
        draft.editor.table = initialState.editor.table;
        draft.editor.record = initialState.editor.record;
        break;

      case types.VIEWER_GO_TO_PAGE:
        if (action.payload.records !== null)
          draft.highlight = action.payload.records;
        if (action.payload.page !== null)
          draft.currentPage = action.payload.page;
        break;

      case types.VIEWER_GET_EDITOR_ATTRIBUTES:
        draft.editor.attributes = action.payload;
        break;

      case types.VIEWER_OPEN_EDITOR:
        draft.editor.open = action.payload.open;
        draft.editor.table = action.table;
        draft.editor.record = action.payload.record;
        break;

      case types.UPDATE_RECORD:
        if (
          state.editor.open === true &&
          state.editor.table === action.table &&
          state.editor.record.id === action.payload.id
        )
          draft.editor.record = action.payload;
        break;

      case types.CREATE_RECORD:
        if (state.editor.open === true && state.editor.table === action.table)
          draft.editor.record = action.payload;
        break;

      case types.DELETE_RECORD:
        if (
          state.editor.open === true &&
          state.editor.table === action.table &&
          state.editor.record.id === action.payload.id
        ) {
          draft.editor.record.id = undefined;
        }
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.section = initialState.section;
        draft.currentPage = initialState.currentPage;
        draft.highlight = initialState.highlight;
        draft.editor.open = initialState.editor.open;
        draft.editor.table = initialState.editor.table;
        draft.editor.record = initialState.editor.record;
        break;

      default:
        break;
    }
  });
