import * as types from 'config/types';
import * as network from 'config/network';
import * as API from 'lib/rest';

/**
 * Check api status
 */
export const checkApiStatus = () => async dispatch => {
  try {
    let payload = {
      api: false,
      database: false,
      elasticsearch: false,
    };

    const url = API.buildURL(network.ENDPOINTS.status.get);
    const status = await API.get(url, null, false);

    if (status !== undefined && status.status === 200)
      payload = await status.json();

    dispatch({
      type: types.CHECK_API_STATUS,
      payload,
    });
  } catch (err) {
    return false;
  }
};

/**
 * Index item in Elasticsearch
 */
export const indexItem = () => () => {
  try {
    const url = API.buildURL(network.ENDPOINTS.elasticsearch.indexItem);
    API.post(url);
  } catch (err) {
    return false;
  }
};
