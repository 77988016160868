import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';
import history from 'config/history';
import pluralize from 'pluralize';

import {
  getStepNotifications,
  getNextAvailableSteps,
  isUlteriorStep,
} from 'lib/utils';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';
import { batchUpdate } from 'components/recordEditor/recordEditorActions';
import { getCurrentDocumentStepProgress } from 'selectors/stepNavigationSelector';

/**
 * Get step progress
 * @param {number} DocumentId id of the document
 */
export const getStepProgress = DocumentId => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.getProgress);

    const stepProgress = await API.get(
      url,
      DocumentId && {
        DocumentId,
      },
    );
    dispatch({
      type: types.GET_STEPS_PROGRESS,
      payload: {
        stepProgress: stepProgress ? stepProgress : {},
      },
    });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get step progress
 * @param {number} milestoneName Milestone.name
 * @param {number} stepName Step.name
 * @param {number} _redirect force redirection
 */
export const goToNextStep = (milestoneName, stepName, _redirect) => async (
  dispatch,
  getState,
) => {
  const DocumentId = getState().utils.currentDocument.id;
  const stepProgress = getCurrentDocumentStepProgress(getState());
  const currentStep = stepProgress.find(step => step.name === stepName);

  // End step
  if (currentStep && currentStep.progress.status !== 'done') {
    const hasStepNeverBeenStarted = !currentStep.progress.history.length;
    const success = await dispatch(
      endStep(DocumentId, [stepName], hasStepNeverBeenStarted),
    );
    if (!success) return;
  }

  // Processed corresponding notifications if all anomalies are processed
  const notificationIds = getStepNotifications(
    getState().notifications.currentDocumentNotifications,
  ).map(notification => notification.id);
  const allAnomaliesHaveProcessed =
    getState().anomalies.currentDocumentAnomalies.filter(
      anomaly => !anomaly.processed,
    ).length === 0;
  if (allAnomaliesHaveProcessed && notificationIds.length)
    dispatch(batchUpdate('Notification', notificationIds, { processed: 1 }));

  await dispatch(getStepProgress(DocumentId));
  const newStepProgress = getCurrentDocumentStepProgress(getState());

  const nextSteps = getNextAvailableSteps(newStepProgress, currentStep);
  // Redirect
  const redirect = nextSteps.find(
    step => step.MilestoneId === currentStep.MilestoneId,
  )?.action;
  history.push(_redirect || redirect || '/integration');

  // Start next steps if necessary
  if (nextSteps.some(step => !step.progress?.current))
    await dispatch(
      startStep(
        DocumentId,
        nextSteps
          .filter(step => isUlteriorStep(currentStep, step))
          .map(step => step.name),
      ),
    );
};

/**
 * Start step
 * @param {number} DocumentId id of the document
 * @param {array} steps array of Step.name to start
 * @param {boolean} force force start
 */
export const startStep = (
  DocumentId,
  steps,
  force = false,
) => async dispatch => {
  if (!steps.length) return;
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.start);
    const result = await API.post(
      url,
      {
        DocumentId,
        steps,
        force,
      },
      false,
    );

    const success = result.status === 200;
    success &&
      dispatch(
        enqueueSnackbar({
          message: `Start ${pluralize('step', steps.length)}: ${steps.join(
            ', ',
          )}`,
          options: {
            variant: 'info',
          },
        }),
      );

    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * End step
 * @param {number} DocumentId id of the document
 * @param {array} steps array of Step.name to end
 * @param {boolean} force force end
 */
export const endStep = (DocumentId, steps, force = false) => async dispatch => {
  if (!steps.length) return;

  try {
    const url = API.buildURL(network.ENDPOINTS.steps.end);
    const result = await API.post(
      url,
      {
        DocumentId,
        steps,
        force,
      },
      false,
    );

    const success = result.status === 200;
    success &&
      dispatch(
        enqueueSnackbar({
          message: `End ${pluralize('step', steps.length)}: ${steps.join(
            ', ',
          )}`,
          options: {
            variant: 'success',
          },
        }),
      );
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getResetInfoForStep = (step, DocumentId) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.stepResetInfo);
    const resetInfo = await API.get(url, {
      DocumentId,
      step,
    });
    resetInfo &&
      dispatch({
        type: types.GET_STEP_RESET_INFO,
        payload: {
          resetInfo,
        },
      });
  } catch (err) {
    console.error(err);
  }
};

export const resetProgressForStep = (step, DocumentId) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.resetProgress);
    await API.post(url, {
      step,
      DocumentId,
    });
    dispatch(
      enqueueSnackbar({
        message: `Progress updated`,
        options: {
          variant: 'info',
        },
      }),
    );
  } catch (err) {
    console.error(err);
  }
};

/**
 * Start a step atom
 * @param {number} DocumentId id of the document
 * @param {string} step Step.name
 * @param {string} atom atom to start
 * @param {number} total quantity of atom for the the document
 */
export const startAtom = (DocumentId, step, atom, total) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.startAtom);
    const result = await API.post(
      url,
      {
        DocumentId,
        step,
        atom,
        total,
      },
      false,
    );

    const success = result.status === 200;
    success &&
      dispatch(
        enqueueSnackbar({
          message: `Figure ${atom} started`,
          options: {
            variant: 'info',
          },
        }),
      );
    dispatch(getStepProgress(DocumentId));
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * End a step atom
 * @param {number} DocumentId id of the document
 * @param {string} step Step.name
 * @param {string} atom atom to start
 * @param {number} total quantity of atom for the the document
 */
export const endAtom = (DocumentId, step, atom, total) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.endAtom);
    const result = await API.post(
      url,
      {
        DocumentId,
        step,
        atom,
        total,
      },
      false,
    );

    const success = result.status === 200;
    success &&
      dispatch(
        enqueueSnackbar({
          message: `Figure ${atom} ended`,
          options: {
            variant: 'success',
          },
        }),
      );
    dispatch(getStepProgress(DocumentId));
    return success;
  } catch (err) {
    console.error(err);
    return false;
  }
};
