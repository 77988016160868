import { createSelector } from 'reselect';

import { getCurrentDocument as _getCurrentDocument } from 'selectors/utilsSelector';
const getCurrentDocument = state => _getCurrentDocument(state);

export const getStepProgress = state => state.stepNavigation.stepProgress || {};

export const getCurrentDocumentStepProgress = createSelector(
  getCurrentDocument,
  getStepProgress,
  (currentDocument, stepProgress) => stepProgress[currentDocument.id] || [],
);
