import produce from 'immer';
import { isEmpty, uniq } from 'lodash';

import { isNotNullish } from 'lib/utils';
import * as types from 'config/types';

const initialState = {
  milestone: 'Formatting',
  step: 'Populate figure table',
  input: {},
  keyword: '',
  keywordList: [
    'FIG',
    'Figure',
    'FIGURE',
    'IPC FIGURE',
    'IPL Figure',
    'IPL FIGURE',
    'IPL-FIGURE',
  ],
  regex: '',
  documentPage: null,
  figureDescriptions: [],
  highlight: [],
  scrollTo: null,
};

const parseInputCoords = (draft, input) => {
  if (input.positionTopMin) draft.input.y = parseInt(input.positionTopMin, 10);
  if (input.positionTopMin && input.positionTopMax)
    draft.input.height = parseInt(
      input.positionTopMax - input.positionTopMin,
      10,
    );
  if (isNotNullish(input.positionLeftMin))
    draft.input.x = parseInt(input.positionLeftMin, 10);
  if (isNotNullish(input.positionLeftMin) && input.positionLeftMax)
    draft.input.width = parseInt(
      input.positionLeftMax - input.positionLeftMin,
      10,
    );
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.FIGURE_GET_DESCRIPTIONS:
        draft.figureDescriptions = action.payload;
        break;

      case types.FIGURE_CHANGE_INPUT:
        draft.input = action.payload;
        break;

      case types.GET_INPUT:
        if (
          action.milestone === initialState.milestone &&
          action.step === initialState.step &&
          !isEmpty(action.payload)
        ) {
          parseInputCoords(draft, action.payload);
          if (action.payload.keyword) draft.keyword = action.payload.keyword;
          if (action.payload.regex) draft.regex = action.payload.regex;
        }
        break;

      case types.GET_TABLE:
        if (
          action.table === 'XmlPage' &&
          state.documentPage === initialState.documentPage
        ) {
          const xmlPage = action.payload.find(
            xmlPage => xmlPage.section === 'figure',
          );
          draft.documentPage = xmlPage
            ? xmlPage.documentPage
            : initialState.documentPage;
        }

        if (action.table === 'Figure') {
          draft.highlight = initialState.highlight;
          draft.scrollTo = initialState.scrollTo;
        }
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (
          action.table === 'XmlPage' &&
          state.documentPage === initialState.documentPage
        ) {
          const xmlPage = action.payload.find(
            xmlPage => xmlPage.section === 'figure',
          );
          draft.documentPage = xmlPage
            ? xmlPage.documentPage
            : initialState.documentPage;
        }
        break;

      case types.GET_DEFAULT_INPUT:
        if (
          action.milestone === initialState.milestone &&
          action.step === initialState.step
        ) {
          parseInputCoords(draft, action.payload);
          if (action.payload.keywords)
            draft.keywordList = uniq([
              ...state.keywordList,
              ...action.payload.keywords,
            ]);
        }
        break;

      case types.VIEWER_GO_TO_PAGE:
        if (action.payload.page) draft.documentPage = action.payload.page;
        break;

      case types.FIGURE_CHANGE_KEYWORD:
        draft.keyword = action.payload;
        break;

      case types.FIGURE_CHANGE_REGEX:
        draft.regex = action.payload;
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.input = initialState.input;
        draft.keyword = initialState.keyword;
        draft.regex = initialState.regex;
        draft.documentPage = initialState.documentPage;
        draft.figureDescriptions = initialState.figureDescriptions;
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'figureTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.CREATE_RECORD:
        if (action.table !== 'XmlText' || action.payload.visible === 0) break;

        if (
          action.payload.positionTop > state.input.y &&
          action.payload.positionTop < state.input.y + state.input.height &&
          action.payload.positionLeft > state.input.x &&
          action.payload.positionLeft < state.input.x + state.input.width
        )
          draft.figureDescriptions = [
            ...state.figureDescriptions,
            action.payload,
          ];

        break;

      case types.UPDATE_RECORD: {
        if (action.table !== 'XmlText') break;

        const figureDescriptions = state.figureDescriptions.filter(
          XmlText => XmlText.id !== action.payload.id,
        );

        if (action.payload.visible === 0) {
          draft.figureDescriptions = figureDescriptions;
          break;
        }

        draft.figureDescriptions = [...figureDescriptions, action.payload];
        break;
      }

      case types.DELETE_RECORD:
        if (action.table === 'XmlText')
          draft.figureDescriptions = state.figureDescriptions.filter(
            XmlText => XmlText.id !== action.payload.id,
          );
        break;

      default:
        break;
    }
  });
