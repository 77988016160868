import produce from 'immer';
import { uniqBy } from 'lodash';

import * as types from 'config/types';

const initialState = {
  currentDocument: {},
  currentLopa: {},
  Document: [],
  DocumentSteps: {},
  XmlPage: [],
  XmlText: [],
  Figure: [],
  Item: [],
  Equipment: [],
  ItemCoordinate: [],
  Layer: [],
  SubAssembly: [],
  EquipmentRep: [],
  Family: {},
  Mesh: [],
  Lopa: [],
  Step: [],
  Milestone: [],
  shouldReconnect: false,
  User: {},
  SubAssemblyCategory: {},
  LocationCategory: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.currentDocument = action.payload;
        draft.XmlPage = initialState.XmlPage;
        draft.XmlText = initialState.XmlText;
        draft.Figure = initialState.Figure;
        draft.Item = initialState.Item;
        draft.Equipment = initialState.Equipment;
        draft.ItemCoordinate = initialState.ItemCoordinate;
        draft.LocationCategory = initialState.LocationCategory;
        draft.SubAssemblyCategory = initialState.SubAssemblyCategory;
        draft.currentLopa = initialState.currentLopa;
        break;

      case types.CHANGE_CURRENT_LOPA:
        draft.currentLopa = action.payload;
        break;

      case types.TABLE_OPEN_MODAL:
        if (action.payload === 'item') draft.Item = initialState.Item;
        break;

      case types.GET_TABLE:
        if (!Object.keys(initialState).find(table => table === action.table))
          break;

        if (action.table === 'Document') {
          draft.currentDocument =
            action.payload.find(
              document => document.id === state.currentDocument.id,
            ) || initialState.currentDocument;

          draft.Document = action.payload;
          break;
        }

        if (action.table === 'Family')
          draft.currentDocument.Family =
            action.payload[state.currentDocument.FamilyId];

        if (!Array.isArray(action.payload)) {
          draft[action.table] = action.payload;
          break;
        }

        draft[action.table] =
          state[action.table][0] !== 'NO DATA'
            ? uniqBy([...action.payload, ...state[action.table]], 'id')
            : action.payload;

        break;

      case types.RESET_TABLE:
        draft[action.table] = initialState[action.table];
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (!Object.keys(initialState).find(table => table === action.table))
          break;

        if (!Array.isArray(action.payload)) {
          draft[action.table] = action.payload;
          break;
        }

        draft[action.table] =
          state[action.table][0] !== 'NO DATA'
            ? uniqBy([...action.payload, ...state[action.table]], 'id')
            : action.payload;
        break;

      case types.CHANGE_FIGURE_FILTER:
        if (
          action.table === 'itemTable' ||
          action.table === 'remarkTable' ||
          action.table === 'itemSettings'
        )
          draft.Item = initialState.Item;
        break;

      case types.UPDATE_RECORD:
        if (!Object.keys(initialState).find(table => table === action.table))
          break;
        draft[action.table][
          state[action.table].findIndex(
            record => record.id === action.payload.id,
          )
        ] = action.payload;

        if (
          action.table === 'Document' &&
          action.payload.id === state.currentDocument.id
        )
          draft.currentDocument = action.payload;
        break;

      case types.BATCH_UPDATE: {
        if (!Object.keys(initialState).find(table => table === action.table))
          break;
        draft[action.table] = state[action.table].map(record =>
          action.payload.ids.includes(record.id)
            ? { ...record, ...action.payload.values }
            : record,
        );
        break;
      }

      case types.CREATE_RECORD:
        if (!Object.keys(initialState).find(table => table === action.table))
          break;
        draft[action.table] = [...state[action.table], action.payload].sort(
          (a, b) => a.documentPage - b.documentPage,
        );
        break;

      case types.DELETE_RECORD:
        if (!Object.keys(initialState).find(table => table === action.table))
          break;
        draft[action.table] = state[action.table].filter(
          record => record.id !== action.payload.id,
        );
        break;

      case types.SHOULD_RECONNECT_CHECK:
        draft.shouldReconnect = true;
        break;

      case types.RECONNECT_CHECK_OK:
        draft.shouldReconnect = false;
        break;

      default:
        break;
    }
  });
