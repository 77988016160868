import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';
import { getTable } from 'components/utils/utilsActions';
import { getNotificationsForDocument } from 'components/notifications/notificationActions';
import { viewerGoToPage } from 'components/viewer/viewerActions';
import { getAnomaliesForDocument } from 'components/anomalies/anomalyActions';
import { getStepProgress } from 'integration/integrationNavigationActions';
import { refreshTable } from 'components/table/tableActions';

/**
 * Return item representation XmlText records for current document
 */
export const getItemRepresentations = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.XmlText.getArea);
    const { input, documentPage, figureParser } = getState().itemRepresentation;
    if (!documentPage) return;

    const itemsRepresentation = await API.get(url, {
      DocumentId: getState().utils.currentDocument.id,
      positionTopMin: input.y,
      positionTopMax: input.y + input.height,
      positionLeftMin: input.x,
      positionLeftMax: input.x + input.width,
      figureParser,
      filter: 'itemDoc',
    });

    dispatch({
      type: types.REPRESENTATION_GET_ITEMS,
      payload:
        itemsRepresentation && itemsRepresentation.length
          ? itemsRepresentation
          : ['NO DATA'],
    });

    const currentItemRepresentations = itemsRepresentation.filter(
      xmlText => xmlText.documentPage === documentPage,
    );
    dispatch(
      viewerGoToPage(
        null,
        currentItemRepresentations
          ? currentItemRepresentations.map(xmlText => xmlText.id)
          : [],
      ),
    );
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Change item representation input
 * @param {*} input new input value
 */
export const changeRepresentationInput = (input = {}) => dispatch => {
  dispatch({
    type: types.REPRESENTATION_CHANGE_INPUT,
    payload: input,
  });
};

/**
 * Process item representation step
 */
export const processRepresentation = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.process);
    const DocumentId = getState().utils.currentDocument.id;
    const {
      step,
      milestone,
      input,
      figureParser,
      documentPage,
    } = getState().itemRepresentation;

    const progress = await API.post(url, {
      DocumentId,
      milestone,
      step,
      input: {
        positionTopMin: input.y,
        positionTopMax: input.y + input.height,
        positionLeftMin: input.x,
        positionLeftMax: input.x + input.width,
        figureParser,
      },
    });
    progress &&
      progress.message &&
      dispatch(
        enqueueSnackbar({
          message: progress.message,
          options: {
            variant: progress.success ? 'success' : 'error',
          },
        }),
      );

    dispatch(getStepProgress(DocumentId));
    dispatch(getItemRepresentations());
    dispatch(getTable('XmlText', { documentPage }));
    dispatch(getTable('ItemCoordinate'));
    dispatch(refreshTable());
    dispatch(getNotificationsForDocument());
    dispatch(getAnomaliesForDocument(DocumentId, milestone, step));
  } catch (err) {
    console.error(err);
  }
};

/**
 * Change figure parser
 * @param {*} figureParser parser
 */
export const changeFigureParser = (figureParser = null) => dispatch => {
  dispatch({
    type: types.REPRESENTATION_CHANGE_FIGURE_PARSER,
    payload: figureParser,
  });
};
