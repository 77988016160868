export const ENDPOINTS = {
  status: {
    get: { path: '/status', base: true },
  },
  auth: {
    login: { path: '/auth/login', base: true },
    check: { path: '/auth/check', base: true },
  },
  files: {
    listDocuments: '/files/getPDFs',
    generateUploadUrl: '/files/generateUploadUrl',
    generateReadUrl: '/files/generateReadUrl',
    shortenPDF: '/files/shortenPDF',
    initialDocumentProcess: '/files/initialDocumentProcess',
    deleteDocument: '/files/deleteDocument',
    extractJpgs: '/files/extractJpgs',
    copyPdfToDocumentFolder: '/files/copyPdfToDocumentFolder',
  },
  location: {
    preview: '/location/preview',
    getEffectivityDistribution: '/location/getEffectivityDistribution',
  },
  events: {
    pushEvents: '/push/subscribe',
    checkReconnect: '/push/check',
  },
  steps: {
    getProgress: '/steps/progress',
    start: '/steps/start',
    end: '/steps/end',
    reset: '/steps/reset',
    startAtom: '/steps/startAtom',
    endAtom: '/steps/endAtom',
    process: '/steps/process',
    stepResetInfo: '/steps/stepResetInfo',
    resetProgress: '/steps/resetProgress',
    formatting: {
      itemExtraction: {
        extractItems: '/steps/formatting/itemExtraction/extract',
        saveItems: '/steps/formatting/itemExtraction/save',
      },
      description: {
        sortDescriptionRules:
          '/steps/formatting/description/sortDescriptionRules',
        createDescriptionRule:
          '/steps/formatting/description/createDescriptionRule',
        initDescriptionStep:
          '/steps/formatting/description/initDescriptionStep',
      },
    },
    subAssembly: {
      itemSettings: {
        setLSA: '/steps/subAssembly/itemSettings/setLSA',
      },
    },
  },
  Document: {
    getFiles: '/Document/getFiles',
    getStatusCounts: '/Document/getStatusCounts',
    updateStatus: '/Document/updateStatus',
  },
  History: {
    getDocumentStatus: '/History/getDocumentStatus',
  },
  XmlText: {
    getPage: '/XmlText/getPage',
    getDocumentLine: '/XmlText/getDocumentLine',
    getArea: '/XmlText/getArea',
    getCounts: '/XmlPage/getXmlTextCounts',
    getLineCount: '/XmlText/getLineCount',
  },
  Item: {
    getData: '/Item/getData',
    getCounts: '/Item/getCounts',
    getDescriptions: '/Item/getDescriptions',
    getDescriptionDocCount: '/Item/getDescriptionDocCount',
    getDescriptionShortCount: '/Item/getDescriptionShortCount',
    getItemDescendants: '/Item/getItemDescendants',
    getNhaAndBkdn: '/Item/getNhaAndBkdn',
    getFigureWithBkdn: '/Item/getFigureWithBkdn',
    getForItemSettings: '/Item/getForItemSettings',
    getSubAssemblyBySemanticGroup: '/Item/getSubAssemblyBySemanticGroup',
    getLayerBySemanticGroup: '/Item/getLayerBySemanticGroup',
  },
  Equipment: {
    getData: '/Equipment/getData',
  },
  pocs: {
    elasticSearchGetItemGroup: '/pocs/elasticSearchGetItemGroup',
  },
  Remark: {
    getData: '/Remark/getData',
    getCounts: '/Remark/getCounts',
  },
  ItemCoordinate: {
    getCounts: '/ItemCoordinate/getCounts',
  },
  Notification: {
    getForDocument: '/Notification/getForDocument',
    getForUser: '/Notification/getForUser',
  },
  Anomaly: {
    getForDocument: '/Anomaly/getForDocument',
  },
  Input: {
    getStep: '/Input/getStep',
    getDefault: '/Input/getDefault',
  },
  LocationGeneric: {
    getLetterings: '/LocationGeneric/getLetterings',
    getSideLetterings: '/LocationGeneric/getSideLetterings',
  },
  LocationCategory: { getSemanticGroup: '/LocationCategory/getSemanticGroup' },
  Lopa: {
    getForDocument: '/Lopa/getForDocument',
  },
  ZionEngine: {
    getDocument: '/ZionEngine/getDocument',
    getLopa: '/ZionEngine/getLopa',
    getMesh: '/ZionEngine/getMesh',
  },
  elasticsearch: {
    indexItem: '/elasticsearch/indexItem',
  },
};
