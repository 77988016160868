import React from 'react';
import { Label as SemanticLabel } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Label = React.memo(
  ({ content, circular, size, color, onClick, dispatch }) => (
    <SemanticLabel
      content={content}
      circular={circular}
      size={size}
      color={color}
      onClick={() => onClick()?.map(action => dispatch(action))}
      style={
        onClick && {
          cursor: 'pointer',
        }
      }
    />
  ),
  (prevProps, nextProps) =>
    prevProps.content === nextProps.content &&
    prevProps.color === nextProps.color,
);

Label.propTypes = {
  circular: PropTypes.bool,
  color: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dispatch: PropTypes.func,
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default process.env.NODE_ENV !== 'test' ? connect()(Label) : Label;
