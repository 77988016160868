import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import equal from 'deep-equal';

import SwitchButton from 'components/utils/SwitchButton';
import connect from 'lib/reduxConnect';
import { makeGetDescriptionTableSearch } from 'selectors/tableSelector';

import { countRenderer } from 'components/table/containers/columnRenderers';
import M3dianTable from 'components/table/Table';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = { header: [] };
  }

  componentDidMount() {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;

    if (!isUserConnected) return;

    this.props.actions.getDescriptionTable();
    this.updateHeader(this.props.type);
  }

  componentDidUpdate(prevProps) {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;

    if (!isUserConnected) return;

    const hasUserConnected = prevProps.auth.token !== this.props.auth.token;
    const hasDocumentChanged =
      prevProps.currentDocumentId !== this.props.currentDocumentId;
    const hasTypeChanged = prevProps.type !== this.props.type;
    const hasDescriptionsChanged = !equal(prevProps.items, this.props.items);

    if (
      hasUserConnected ||
      hasDocumentChanged ||
      hasTypeChanged ||
      hasDescriptionsChanged
    )
      this.props.actions.getDescriptionTable();

    if (hasTypeChanged) this.updateHeader(this.props.type);

    // Handle table refresh
    if (this.props.refresh) {
      this.props.actions.getDescriptionTable();
      this.props.actions.refreshTable(true);
    }
  }

  countRenderer = data => countRenderer(data, this.props.type);

  updateHeader = type => {
    this.setState(
      {
        header: [
          {
            label: startCase(type),
            dataKey: type,
            width: 9 / 10,
          },
          {
            label: 'Count',
            dataKey: 'quantity',
            width: 1 / 20,
            cellRenderer: this.countRenderer,
          },
          {
            label: 'Length',
            dataKey: 'length',
            width: 1 / 20,
          },
        ],
      },
      () =>
        this.props.actions.changeTableHeader(
          this.state.header,
          this.props.modal,
        ),
    );
  };

  render() {
    const { descriptions, modal, gridStyle } = this.props;
    return (
      <M3dianTable
        modal={modal}
        name="descriptionsTable"
        sortBy={['length', 'descriptionDoc', 'descriptionShort', 'quantity']}
        header={this.state.header}
        data={descriptions}
        gridStyle={gridStyle}
      />
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  descriptions: PropTypes.array,
  gridStyle: PropTypes.object,
  items: PropTypes.array,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  type: PropTypes.string,
};

const makeMapStateToProps = () => {
  const getDescriptionTableSearch = makeGetDescriptionTableSearch();
  const mapStateToProps = (state, props) => {
    return {
      items: state.utils.Item,
      type: state.descriptionTable.type,
      descriptions: getDescriptionTableSearch(state, props.modal),
      refresh: state.table.refresh,
    };
  };
  return mapStateToProps;
};

export const DescriptionsTable =
  process.env.NODE_ENV !== 'test'
    ? connect(makeMapStateToProps())(Table)
    : Table;

const Header = ({ type, actions }) => (
  <SwitchButton
    first="Description Doc"
    second="Description Short"
    firstActive={type === 'descriptionDoc'}
    secondActive={type === 'descriptionShort'}
    onClick={() => actions.toggleDescriptionTableType(false)}
    style={styles.switch}
  />
);

const styles = { switch: { width: '100%' } };

Header.propTypes = {
  type: PropTypes.string,
};

const mapStateToProps = state => ({
  type: state.descriptionTable.type,
});

export const DescriptionsTableHeader =
  process.env.NODE_ENV !== 'test' ? connect(mapStateToProps)(Header) : Header;
