import * as network from 'config/network';
import * as API from 'lib/rest';
import * as types from 'config/types';
import { generateUploadUrl } from 'lib/utils';

import {
  enqueueSnackbar,
  closeSnackbar,
} from 'components/snackbar/snackbarActions';

/**
 * Get all .pdf file in bucket
 */
export const getExistingDocuments = () => async dispatch => {
  try {
    const bucketUrl = API.buildURL(network.ENDPOINTS.files.listDocuments);
    const dbUrl = API.buildURL(network.ENDPOINTS.Document.getFiles);

    let [documents, dbFiles] = await Promise.all([
      API.get(bucketUrl),
      API.get(dbUrl),
    ]);
    if (documents !== undefined) {
      documents = documents.map(document => document.replace('documents/', ''));

      dispatch({
        type: types.GET_DOCUMENTS_FROM_BUCKET,
        payload: documents,
      });
      dispatch({
        type: types.GET_FILES_FROM_DB,
        payload: dbFiles,
      });
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Update file picked by user
 * @param {*} file new file
 */
export const updateFilePicker = file => dispatch => {
  dispatch({
    type: types.PICK_FILE,
    payload: file,
  });
};

/**
 * Upload file in bucket
 * @param {*} file file to upload
 * @param {string} DocumentId id of the Document
 */
export const uploadDocument = async (file, DocumentId, dispatch) => {
  try {
    if (!file) return null;
    const notificationAction = enqueueSnackbar({
      message: `<div>
          Uploading <b>${file.name}</b> ....
        </div>`,
      options: {
        autoClose: false,
        closeButton: false,
        draggable: false,
        closeOnClick: false,
        isLoading: true,
        className: 'default_cursor',
        variant: 'warning',
      },
    });
    dispatch(notificationAction);

    const url = await generateUploadUrl(DocumentId, file.name);
    await API.uploadFileToBucket(file, url); // google doesn't send an HTTP response
    notificationAction?.notification?.options?.toastId &&
      dispatch(closeSnackbar(notificationAction.notification.options.toastId));
    return dispatch({
      type: types.FILE_UPLOADED,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const startInitialDocumentProcess = async (
  filename,
  DocumentId,
  useShorterDoc = false,
) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.files.initialDocumentProcess);
    return API.post(url, { filename, DocumentId, useShorterDoc }, true);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const shortenPDF = async (filename, minPage, maxPage, DocumentId) => {
  const url = API.buildURL(network.ENDPOINTS.files.shortenPDF);
  const response = await API.post(url, {
    filename,
    minPage,
    maxPage,
    DocumentId,
  });

  if (!response.success) throw new Error(response.message);
  return response;
};

/**
 * Extract image from figure pages and save in bucket
 */
export const extractJpgs = () => (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.files.extractJpgs);
    const notificationAction = enqueueSnackbar({
      message: `<div>
          Extracting figure images for Document <b>${
            getState().utils.currentDocument.id
          }</b> ....
        </div>`,
      options: {
        autoClose: false,
        closeButton: false,
        draggable: false,
        closeOnClick: false,
        isLoading: true,
        className: 'default_cursor',
        variant: 'info',
      },
    });
    dispatch(notificationAction);
    API.get(url, {
      DocumentId: getState().utils.currentDocument.id,
      UserId: getState().auth.user.id,
      notificationKey: notificationAction?.notification?.options?.toastId,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const copyPdfToDocumentFolder = async (DocumentId, filename) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.files.copyPdfToDocumentFolder);

    return API.post(url, {
      filename,
      DocumentId,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};
