import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';
import { getTable } from 'components/utils/utilsActions';
import { getStepProgress } from 'integration/integrationNavigationActions';
import { refreshTable } from 'components/table/tableActions';

/**
 * Change equipment filters
 * @param {*} filters filters
 */
export const changeEquipmentFilters = filters => dispatch => {
  dispatch({
    type: types.EQUIPMENT_CHANGE_FILTER,
    payload: filters,
  });
};

/**
 * Process equipment step
 * @param {array} figureDocs figureDocs
 * @param {array} itemNumerics itemNumerics
 * @param {?array} excluded excluded
 */
export const processEquipment = (
  figureDocs,
  itemNumerics,
  excluded = [],
) => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.process);
    const progress = await API.post(url, {
      DocumentId: getState().utils.currentDocument.id,
      milestone: getState().equipment.milestone,
      step: getState().equipment.step,
      input: { figureDocs, itemNumerics, excluded },
    });

    progress &&
      progress.message &&
      dispatch(
        enqueueSnackbar({
          message: progress.message,
          options: {
            variant: progress.success ? 'success' : 'error',
          },
        }),
      );
    dispatch(getStepProgress(getState().utils.currentDocument.id));
    dispatch(refreshTable());
    dispatch(getTable('Equipment'));
  } catch (err) {
    console.error(err);
    return false;
  }
};
