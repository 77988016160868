import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  open: false,
  table: null,
  attributes: [],
  fields: [
    {
      field: 'type',
      allowNull: false,
      type: 'text',
      name: 'Document Type',
      tooltip: 'The nature of this document',
    },
    {
      field: 'manufacturer',
      allowNull: false,
      type: 'text',
      name: 'Manufacturer',
      tooltip: 'The manufacturer who created this document',
    },
    {
      field: 'ata',
      allowNull: false,
      type: 'text',
      name: 'ATA',
      tooltip: 'Technical Data for this document',
    },
    {
      field: 'revision',
      allowNull: false,
      type: 'text',
      name: 'Revision',
      tooltip: 'The revision # for this document',
    },
    {
      field: 'FamilyId',
      allowNull: false,
      type: 'number',
      name: 'Family',
      tooltip: 'The family to which this document belongs',
    },
    {
      field: 'customer',
      type: 'text',
      allowNull: true,
      name: 'Customer',
      tooltip: 'The customer using this document',
    },
    {
      field: 'fleet',
      type: 'text',
      allowNull: true,
      name: 'Fleets',
      tooltip: 'The fleet to which this document belongs',
    },
    {
      field: 'semanticGroup',
      allowNull: false,
      type: 'text',
      name: 'Semantic Group',
      tooltip: 'The logical category to which the equipment belongs',
    },
  ],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.DOCUMENT_CREATION_OPEN:
        draft.open = action.payload.open;
        break;

      case types.DOCUMENT_CREATION_CLOSE:
        draft.open = false;
        break;

      default:
        break;
    }
  });
