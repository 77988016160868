import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';
import { makeGetRepresentationTableSearch } from 'selectors/tableSelector';

import {
  figureButtonRenderer,
  idButtonRenderer,
} from 'components/table/containers/columnRenderers';
import FigureFilter from 'components/table/containers/FigureFilter';
import M3dianTable from 'components/table/Table';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [
        {
          label: 'ID',
          cellRenderer: this.idRenderer,
          dataKey: 'id',
          width: 1 / 3,
        },
        {
          label: 'Figure',
          cellRenderer: figureButtonRenderer,
          dataKey: 'figureSheet',
          width: 1 / 3,
        },
        {
          label: 'Item',
          dataKey: 'itemDoc',
          width: 1 / 3,
        },
      ],
    };
  }

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;
    this.initRepresentation(true);
    this.props.actions.changeTableHeader(this.state.header, this.props.modal);
  }

  componentDidUpdate(prevProps) {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;

    // In case of access when disconnected
    if (prevProps.auth.token !== this.props.auth.token)
      this.initRepresentation(true);

    // Handle document change
    if (prevProps.currentDocumentId !== this.props.currentDocumentId)
      this.initRepresentation(true);

    // Handle figure change
    if (prevProps.figureFilter !== this.props.figureFilter)
      this.initRepresentation();

    if (this.props.refresh) {
      this.props.actions.getTable('Figure');
      this.props.actions.refreshTable(true);
    }
  }

  idRenderer = data => idButtonRenderer(data, 'ItemCoordinate');

  initRepresentation = (init = false) => {
    if (this.props.figureFilter !== null) {
      const where = this.props.figureFilter &&
        this.props.figureFilter.id !== 'all' && {
          FigureId: this.props.figureFilter.id,
        };
      this.props.actions.getTable('ItemCoordinate', where);
      this.props.actions.viewerGoToPage(this.props.figureFilter.documentPage);
    }

    if (init) {
      this.props.actions.getTableCounts('ItemCoordinate');
    }
  };

  render() {
    const {
      representations,
      highlight,
      scrollTo,
      height,
      modal,
      gridStyle,
    } = this.props;
    return (
      <M3dianTable
        modal={modal}
        name="representationTable"
        table="Figure"
        height={height}
        sortBy={['id', 'itemDoc', 'figureSheet']}
        header={this.state.header}
        data={representations}
        highlight={highlight}
        scrollTo={scrollTo}
        gridStyle={gridStyle}
      />
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  figureFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  gridStyle: PropTypes.object,
  height: PropTypes.number,
  highlight: PropTypes.array,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  representations: PropTypes.array,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
};

const makeMapStateToProps = () => {
  const getRepresentationTableSearch = makeGetRepresentationTableSearch();
  const mapStateToProps = (state, props) => {
    return {
      representations: getRepresentationTableSearch(state, props.modal),
      scrollTo: state.representationTable.scrollTo,
      highlight: state.representationTable.highlight,
      refresh: state.table.refresh,
      figureFilter: state.representationTable.figureFilter,
    };
  };
  return mapStateToProps;
};

export const RepresentationTable =
  process.env.NODE_ENV !== 'test'
    ? connect(makeMapStateToProps())(Table)
    : Table;

const Header = ({ figureFilter, counts }) => {
  return figureFilter !== 'NO DATA' ? (
    <FigureFilter
      table="representationTable"
      value={figureFilter}
      descriptions={counts}
    />
  ) : null;
};

Header.propTypes = {
  counts: PropTypes.object,
  figureFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const mapStateToProps = state => ({
  figureFilter: state.representationTable.figureFilter,
  counts: state.representationTable.counts,
});

export const RepresentationTableHeader =
  process.env.NODE_ENV !== 'test' ? connect(mapStateToProps)(Header) : Header;
