import React from 'react';
import PropTypes from 'prop-types';

const POCTime = React.memo(
  ({ time, averageTime, fetchTime }) =>
    time && (
      <React.Fragment>
        <li>
          {time}{' '}
          {averageTime ? (
            <React.Fragment>(AVG: {averageTime})</React.Fragment>
          ) : null}
        </li>
        {fetchTime && <li>Fetch: {fetchTime}</li>}
      </React.Fragment>
    ),
);

POCTime.propTypes = {
  averageTime: PropTypes.string,
  fetchTime: PropTypes.string,
  time: PropTypes.string,
};

export default POCTime;
