import { batchActions } from 'ais-utils/reduxBatch';
import { getTable } from 'components/utils/utilsActions';

import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

/**
 * Return Equipment data
 */
export const getEquipmentData = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.Equipment.getData);

    const where = {
      DocumentId: getState().utils.currentDocument.id,
    };

    const equipmentTable = await API.get(url, where);
    dispatch({
      type: types.EQUIPMENT_GET_DATA,
      payload: equipmentTable ? equipmentTable : {},
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loadEquipmentTable = () => async dispatch => {
  dispatch(batchActions([getTable('Equipment'), getEquipmentData()]));
};
