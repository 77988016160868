import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';
import { encodeWhere } from 'lib/utils';
import { batchActions } from 'ais-utils/reduxBatch';
import { getTable } from 'components/utils/utilsActions';
import {
  getEffectivityDistribution,
  getItemData,
  getNhaAndBkdn,
} from 'components/table/tables/item/itemActions';
import { getCurrentFigure } from 'selectors/subAssemblySelector';
import {
  enqueueSnackbar,
  updateSnackbar,
} from 'components/snackbar/snackbarActions';
import { createRecord } from 'components/recordEditor/recordEditorActions';
import { notificationTime } from 'config/constants';

export const getItemsForItemSettings = _where => async (dispatch, getState) => {
  try {
    let where = { DocumentId: getState().utils.currentDocument.id };
    if (_where) where = { ...where, ...encodeWhere(_where) };

    const url = API.buildURL(network.ENDPOINTS.Item.getForItemSettings);

    const records = await API.get(url, where);

    if (records)
      dispatch({
        type: types.GET_TABLE_NOT_SYNC,
        table: 'Item',
        payload: Array.isArray(records)
          ? records.length
            ? records
            : ['NO DATA']
          : records,
      });
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const loadItemSettingsTable = () => async (dispatch, getState) => {
  const actions = [
    getEffectivityDistribution(),
    getTable('SubAssemblyCategory', {
      DocumentId: getState().utils.currentDocument.id,
    }),
  ];
  const currentFigure = getCurrentFigure(getState());

  if (currentFigure) {
    // Get only displayed data
    const where = currentFigure &&
      currentFigure.id !== 'all' && {
        figureDoc: currentFigure.figureDoc,
      };
    actions.push(getItemsForItemSettings(where));
    actions.push(getItemData(currentFigure.figureDoc, null, ['XmlText']));
    actions.push(getNhaAndBkdn(where));
  }

  return dispatch(batchActions(actions));
};

export const setLSA = (
  ids,
  type,
  _value,
  source,
  forceCreate = false,
) => async (dispatch, getState) => {
  const toastId = new Date().getTime() + Math.random();
  const table = type.replace('Id', '');
  let value = _value;

  try {
    dispatch(
      enqueueSnackbar({
        message: `Setting <b>${table}</b>`,
        options: {
          autoClose: false,
          closeButton: false,
          draggable: false,
          closeOnClick: false,
          isLoading: true,
          className: 'default_cursor',
          toastId,
        },
      }),
    );

    if (forceCreate)
      value = await dispatch(
        createRecord(table, {
          description: _value,
        }),
      );

    const url = API.buildURL(
      network.ENDPOINTS.steps.subAssembly.itemSettings.setLSA,
    );

    const response = await API.post(url, {
      DocumentId: getState().utils.currentDocument.id,
      ids,
      type,
      value,
      source,
    });

    if (!response) throw new Error();

    if (response.updated) await dispatch(loadItemSettingsTable());

    dispatch(
      updateSnackbar({
        message: `<b>${response.updated}</b> items have been updated`,
        options: {
          toastId,
          variant: 'success',
          autoClose: notificationTime,
          closeButton: null,
          draggable: true,
          progress: null,
          closeOnClick: true,
          isLoading: false,
          className: '',
        },
      }),
    );
  } catch (err) {
    dispatch(
      updateSnackbar({
        message: `Cannot set <b>${type.replace('Id', '')}</b>`,
        options: {
          toastId,
          variant: 'error',
          autoClose: notificationTime,
          closeButton: null,
          draggable: true,
          progress: null,
          closeOnClick: true,
          isLoading: false,
          className: '',
        },
      }),
    );
    return false;
  }
};
