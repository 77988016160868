import { formatDistanceToNow } from 'date-fns';
import { difference } from 'lodash';
import { groupBy, sortByFields } from 'ais-utils';

import { info, exclamation, ohno, old } from 'config/images';

import * as network from 'config/network';
import * as API from 'lib/rest';

/**
 * Suspend execution
 * @param {number} ms time in ms
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Return html type for a SQL type
 * @param {string} type SQL type to interpret
 */
export const getSQLType = type => {
  if (type.includes('(')) type = type.slice(0, type.indexOf('('));

  switch (type) {
    case 'INTEGER':
      return 'number';

    case 'FLOAT':
      return 'number';

    case 'VARCHAR':
      return 'text';

    case 'TEXT':
      return 'text';

    default:
      return type;
  }
};

/**
 * Return required attributes
 * @param {array} attributes model attributes
 */
export const getRequiredFields = attributes =>
  attributes
    .map(attribute => {
      if (attribute.field === 'id') return null;

      if (!attribute.allowNull) {
        return attribute.field;
      }
      return null;
    })
    .filter(field => field !== null);

/**
 * Return DocumentId for a file
 * @param {string} file file name
 */
export const extractDocumentIdFromFile = file =>
  file.value.substring(0, file.value.indexOf('_')) * 1;

/**
 * Sort an array of items
 * @param {array} items array of items to sort
 */
export const sortItems = items =>
  [...items].sort((a, b) => {
    const differentDocuments = a.DocumentId - b.DocumentId;
    if (differentDocuments !== 0) return differentDocuments;

    const differentFigureNumeric = a.figureNumeric - b.figureNumeric;
    if (differentFigureNumeric !== 0) return differentFigureNumeric;

    // force figureDoc and itemDoc to string
    if (`${a.figureDoc}` < `${b.figureDoc}`) return -1;
    if (`${a.figureDoc}` > `${b.figureDoc}`) return 1;

    const differentItemNumeric = a.itemNumeric - b.itemNumeric;
    if (differentItemNumeric !== 0 || !a.itemDoc || !b.itemDoc)
      return differentItemNumeric;

    const aItemDoc = `${a.itemDoc}`.replace('-', '');
    const bItemDoc = `${b.itemDoc}`.replace('-', '');

    if (aItemDoc < bItemDoc) return -1;
    if (aItemDoc > bItemDoc) return 1;
    return 0;
  });

/**
 * Check if string is JSON
 * @param {string} str string to validate
 */
export const isValidJSON = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Return first figure
 * @param {array} figures Figure records
 * @param {*} value current value
 */
export const getFirstFigure = (figures, value = null) => {
  const [noData] = figures;
  if (noData === 'NO DATA') return null;

  if (value && value.id) {
    const current = figures.find(figure => figure.id === value.id);
    if (current) return current;
  }

  return figures.reduce((previous, current) =>
    previous.figureSheet < current.figureSheet ? previous : current,
  );
};

/**
 * Return XmlText records as line
 * @param {string} groupBy group condition
 * @param {*} data XmlText records group by groupBy value
 */
export const formatDocumentLine = (groupBy, data) => {
  const table = [];
  for (const [value, xmlTexts] of Object.entries(data)) {
    const line = sortByFields(
      xmlTexts,
      ['positionTop', 'ASC'],
      ['positionLeft', 'ASC'],
    ).reduce(
      (line, xmlText) => {
        line.text = `${line.text}${line.text.length > 0 ? ' ' : ''}${
          xmlText.text
        }`;
        line.XmlTextIds.push(xmlText.id);
        if (line.documentPage < xmlText.documentPage)
          line.documentPage = xmlText.documentPage;
        return line;
      },
      {
        [groupBy]: parseInt(value, 10),
        text: '',
        documentPage: 0,
        XmlTextIds: [],
      },
    );
    table.push({
      ...line,
      size: line.text.length,
    });
  }
  return table;
};

/**
 * Return passed time
 * @param {*} date origin date
 */
export const getElapsedTime = date => {
  return formatDistanceToNow(new Date(date));
};

/**
 * Generate upload URL to the bucket
 * @param {string} DocumentId id of the Document
 * @param {string} filename filename
 */
export const generateUploadUrl = async (DocumentId, filename) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.files.generateUploadUrl);
    return await API.post(url, { DocumentId, filename }, true);
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Generate read URL to the bucket
 * @param {*} where how to find filename
 */
export const generateReadUrl = async where => {
  try {
    const url = API.buildURL(network.ENDPOINTS.files.generateReadUrl);
    return await API.post(url, where, true);
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return index of a complete word
 * @param {string} str string to search into
 * @param {string} search word to find
 */
export const wordIndex = (str, search) => {
  const words = search.split(' ');
  const text = str.split(' ');
  return words.length === 0
    ? text.indexOf(search)
    : difference(words, text).length === 0
    ? text.indexOf(words[0])
    : -1;
};

/**
 * Split a string into an array
 * @param {number} begin index to split
 * @param {?number} end last index to split
 * @param {string} str string to split
 */
export const splitAt = (begin, end) => str =>
  end
    ? [
        str.slice(0, begin).trim(),
        str.slice(begin, end).trim(),
        str.slice(end).trim(),
      ]
    : [str.slice(0, begin).trim(), str.slice(begin).trim()];

export const wordRegex = word =>
  new RegExp(`(\\b${word ? word : '[^s]+'}\\b)`, 'g');

/**
 * Test if it is PaxSeat lettering
 * [Equipment-W, W-X, W-X-Y, W-X-Y-Z]
 * @param {string} lettering lettering
 */
export const validateSeatPaxLettering = lettering => {
  const regex = new RegExp(/^(([A-Z]-){1,3}[A-Z])$|^(Equipment-[A-Z])$/, 'g');
  return regex.test(lettering);
};

const orderOfCriticity = ['routine', 'warn', 'critical'];

/**
 * Return status icon
 * @param {string} status status
 */
export const pickCriticityIcon = status => {
  const iconList = {
    routine: info,
    critical: exclamation,
    warn: ohno,
    closed: old,
  };
  return iconList[status] || exclamation;
};

/**
 * Sort array by criticity (critical -> warn -> routine), date and id
 * @param {array} data array to sort
 */
export const sortByCriticity = data => {
  return [...data].sort((a, b) => {
    if (
      orderOfCriticity.indexOf(a.criticity) >
      orderOfCriticity.indexOf(b.criticity)
    )
      return -1;
    if (
      orderOfCriticity.indexOf(a.criticity) <
      orderOfCriticity.indexOf(b.criticity)
    )
      return 1;

    if (a.datetime && b.datetime) {
      const sort = new Date(a.datetime) - new Date(b.datetime);
      if (sort) return sort < 0 ? -1 : 1;
    }
    if (a.datetime && !b.datetime) return -1;
    if (b.datetime && !a.datetime) return 1;

    return a.id - b.id;
  });
};

/**
 * Return hightest criticity
 * @param {array} data
 */
export const getMostCritical = data => {
  return data
    ? data.reduce((mostCritical, datum) => {
        if (
          orderOfCriticity.indexOf(datum.criticity) >
          orderOfCriticity.indexOf(mostCritical)
        ) {
          return datum.criticity;
        }
        return mostCritical;
      }, null)
    : null;
};

export const formatDocumentName = document =>
  document?.id
    ? `${document.id} - ${document.type} ${
        document.semanticGroup ? `- ${document.semanticGroup} ` : ''
      }- ${document.manufacturer} ${document.ata}`
    : '';

export const formatDocumentsForDisplay = (documents, allData = true) =>
  documents.map(document => {
    const display = {
      value: document.id,
      text: formatDocumentName(document),
      label: {
        color:
          document.status === 'Production'
            ? 'green'
            : document.status === 'In Progress'
            ? 'blue'
            : document.status === 'Revision'
            ? 'red'
            : document.status === 'Inactive'
            ? 'grey'
            : document.status === 'On Hold'
            ? 'yellow'
            : null,
        children: document.status && document.status.toUpperCase(),
      },
      description: document.Family && document.Family.name,
    };
    return allData
      ? {
          ...document,
          ...display,
        }
      : display;
  });

/**
 * Return document progress percentage
 * @param {array} endedSteps array of ended Step
 * @param {array} steps all steps
 */
const computeDocumentPercentage = (endedSteps, steps) => {
  let percentage = 0;
  const endedStepsByMilestone = groupBy(endedSteps, 'MilestoneId');
  const stepsByMilestone = groupBy(steps, 'MilestoneId');

  /*
      Per specs:
      20% progress allocated to the content of milestones 1 + 2
      40% progress for the content of milestone 3
      20% for milestone 4
    */
  percentage =
    getMilestonePercentage(
      stepsByMilestone,
      endedStepsByMilestone,
      [1, 2],
      20,
    ) +
    getMilestonePercentage(stepsByMilestone, endedStepsByMilestone, [3], 40) +
    getMilestonePercentage(stepsByMilestone, endedStepsByMilestone, [4], 20);

  return Math.ceil(percentage) || 0;
};

/**
 * Return milestone percentage
 * @param {object} stepsByMilestone steps grouped by MilestoneId
 * @param {object} endedStepsByMilestone ended steps grouped by MilestoneId
 * @param {array} milestoneIds array of Milestone.id
 * @param {number} ratio weight of the milestones in the document progress
 */
export const getMilestonePercentage = (
  stepsByMilestone = {},
  endedStepsByMilestone = {},
  milestoneIds = [],
  ratio = 0,
) => {
  const counts = milestoneIds.reduce(
    (acc, MilestoneId) => ({
      endedStepCount:
        acc.endedStepCount + (endedStepsByMilestone[MilestoneId] || []).length,
      stepCount: acc.stepCount + (stepsByMilestone[MilestoneId] || []).length,
    }),
    {
      endedStepCount: 0,
      stepCount: 0,
    },
  );

  return (counts.endedStepCount / counts.stepCount || 0) * ratio;
};

/**
 * Compute document percentage
 * @param {array} stepProgress all steps in with their progress
 */
export const getDocumentPercentage = stepProgress => {
  if (!stepProgress) return null;

  const doneSteps = stepProgress.filter(
    step => step.progress.status === 'done',
  );

  const done = computeDocumentPercentage(doneSteps, stepProgress);
  return done;
};

export const floatify = number => parseFloat(number.toFixed(10));

export const isNotNullish = value => {
  return value !== null && value !== undefined;
};

/**
 * Destructure string effectivity
 * @param {string} effectivity string effectivity
 */
export const destructureEffectivity = effectivity =>
  (effectivity && effectivity.split('_')) || [];

/**
 * An item should be ignored when it's effectivity is ZZ
 * @param {Object} item item record
 */
export const shouldItemBeIgnored = item =>
  item ? item.effectivity === 'ZZ' : true;

/**
 * An item location should be ignored when it's NIL
 * @param {Object} item item record
 */
export const shouldItemLocationBeIgnored = locationList =>
  locationList === 'NIL';

/**
 * Destructure string effectivity
 * @param {string} effectivity string effectivity
 */
export const destructureLocationList = locationList =>
  (locationList && locationList.split(',')) || [];

/**
 * Return eligible location by effectivity for an item
 * @param {Array} itemLocations item locationList destructured in an array
 * @param {Object} eligibleLocations eligible locations for the item
 */
export const getItemLocations = (itemLocations, eligibleLocations) => {
  const effectivityLocation = {};
  if (!eligibleLocations || shouldItemLocationBeIgnored(itemLocations[0]))
    return effectivityLocation;

  // Return all eligible locations if none is set on the item
  if (!itemLocations.length) return eligibleLocations;

  itemLocations.forEach(location =>
    Object.entries(eligibleLocations).forEach(
      ([effectivity, eligibleLocation]) => {
        // Add eligible locations to item effectivity-location
        if (eligibleLocation.includes(location))
          effectivityLocation[effectivity] = effectivityLocation[effectivity]
            ? [...effectivityLocation[effectivity], location]
            : [location];
      },
    ),
  );

  return effectivityLocation;
};

/**
 * Return eligible locations for a figureDoc-effectivity
 * @param {string} figureDoc item figureDoc value
 * @param {Array} itemEffectivities item effectivities destructured in an array
 * @param {Object} effectivityDistribution eligible locations by figureDoc-effectivity
 */
export const getItemEligibleLocations = (
  figureDoc,
  itemEffectivities,
  effectivityDistribution,
) =>
  effectivityDistribution?.[figureDoc] &&
  Object.entries(effectivityDistribution[figureDoc]).reduce(
    (acc, [effectivity, locations]) => {
      if (!itemEffectivities.length || itemEffectivities.includes(effectivity))
        acc[effectivity] = locations;
      return acc;
    },
    {},
  );

/**
 * Return the figure description
 * @param {Object} value item figure value
 * @param {Array} figures all the figures
 * @param {string} groupBy
 */
export const renderFigureText = (value, figures, groupBy) => {
  if (!value) return null;
  if (value && value.id === 'all') return 'ALL';

  let currentFigure = figures.find(
    figure => figure.id === value.id || figure[groupBy] === value[groupBy],
  );
  if (!currentFigure)
    currentFigure = figures.find(
      figure => figure.figureDoc === value.figureDoc,
    );

  return currentFigure ? currentFigure.description : 'Fig.';
};

/**
 * Sort steps in the same milestone
 * @param {object} stepA step
 * @param {object} stepB step
 */
export const sortStepInMilestone = (stepA, stepB) => {
  const stepSort = stepA.milestoneStep - stepB.milestoneStep;
  if (stepSort !== 0) return stepSort;
  const sortInStep = stepA.orderInStep - stepB.orderInStep;
  return sortInStep;
};

/**
 * Return true if stepB is ulterior of stepA
 * @param {object} stepA step
 * @param {object} stepB step
 */
export const isUlteriorStep = (stepA, stepB) => {
  if (stepA.Milestone.rank < stepB.Milestone.rank) return true;
  if (
    stepA.Milestone.rank === stepB.Milestone.rank &&
    stepA.milestoneStep < stepB.milestoneStep &&
    stepA.orderInStep <= stepB.orderInStep
  )
    return true;
  return false;
};

/**
 * Get the next steps available
 * @param {array} stepProgress
 */
export const getNextAvailableSteps = stepProgress => {
  if (!stepProgress?.length) return null;

  return stepProgress.filter(step => step.progress.status === 'workable');
};

/**
 * Return username
 * @param {object} user user record
 */
export const buildUsername = ({ firstName, lastName } = {}) => {
  if (firstName && lastName) return `${firstName.substr(0, 1)}.${lastName}`;
  // Only last name
  if (!firstName?.length && lastName) return lastName;
  // Only first name
  if (!lastName?.length && firstName) return firstName;

  return '';
};

/**
 * Get the usernames that have worked on a Document
 * @param {array} documentSteps DocumentSteps records for a Document
 */
export const getDocumentUsernames = documentSteps => {
  if (!documentSteps) return null;
  const usernames = [];
  documentSteps &&
    documentSteps.forEach(step => {
      const { EndUser, StartUser } = step.progress?.current || {};
      if (StartUser) {
        const username = buildUsername(StartUser);
        if (!usernames.includes(username)) usernames.push(username);
      }

      if (EndUser) {
        const username = buildUsername(EndUser);
        if (!usernames.includes(username)) usernames.push(username);
      }
    });
  return usernames;
};

/**
 * Get notification linked to step
 * @param {array} notifications Document notifications
 * @param {string} milestoneName Milestone.name
 * @param {string} stepName Step.name
 */
export const getStepNotifications = (notifications, milestoneName, stepName) =>
  notifications?.length
    ? notifications.filter(
        notification =>
          !notification.processed &&
          notification.milestone === milestoneName &&
          notification.step === stepName,
      )
    : [];

/**
 * Return a value in percentage
 * @param {number} value
 * @param {number} total
 * @param {?number} digits number of digits to display - 1 by default
 */
export const toPercentage = (value, total, digits = 1) =>
  ((value / total) * 100).toFixed(digits);

/**
 * Encode where for get request
 * @param {Object} _where object to encode
 */
export const encodeWhere = _where => {
  const where = {};
  _where &&
    Object.keys(_where).forEach(
      key =>
        (where[key] = Array.isArray(_where[key])
          ? _where[key].map(value => encodeURIComponent(value))
          : encodeURIComponent(_where[key])),
    );
  return where;
};
