import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  documentLines: {},
  highlight: [],
  scrollTo: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LINES_GET_DOCUMENT_LINES:
        draft.documentLines = action.payload;
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.documentLines = initialState.documentLines;
        break;

      case types.UPDATE_RECORD:
        if (
          action.table === 'XmlText' &&
          state.documentLines[action.payload.documentLine]
        ) {
          const record = action.payload;
          if (record.documentLine === 0) break;

          const documentLineRecord = {
            documentLine: record.documentLine,
            documentPage: record.documentPage,
            text: record.text,
            id: record.id,
            positionTop: record.positionTop,
            positionLeft: record.positionLeft,
          };
          const findDocumentLine = Object.keys(state.documentLines)
            .map(documentLine => parseInt(documentLine, 10))
            .includes(record.documentLine);
          const recordIndex = findDocumentLine
            ? state.documentLines[record.documentLine].findIndex(
                xmlText => xmlText.id === record.id,
              )
            : -1;

          // change documentLine value
          // remove record in previous documentLine
          for (const [documentLine, xmlTexts] of Object.entries(
            state.documentLines,
          )) {
            const lineDeleted = xmlTexts.filter(
              xmlText => xmlText.id !== record.id,
            );
            lineDeleted.length > 0
              ? (draft.documentLines[documentLine] = lineDeleted)
              : delete draft.documentLines[documentLine];
          }

          // just remove record if visible is set to 0
          if (record.visible === 0) break;

          // create record in new documentLine
          if (recordIndex === -1) {
            draft.documentLines[record.documentLine] = findDocumentLine
              ? [
                  ...state.documentLines[record.documentLine],
                  documentLineRecord,
                ]
              : [documentLineRecord];
            break;
          }

          draft.documentLines[record.documentLine][
            recordIndex
          ] = documentLineRecord;
        }
        break;

      case types.CREATE_RECORD:
        if (
          action.table === 'XmlText' &&
          state.documentLines[action.payload.documentLine] &&
          action.payload.visible !== 0
        ) {
          const record = action.payload;
          const documentLineRecord = {
            documentLine: record.documentLine,
            documentPage: record.documentPage,
            text: record.text,
            id: record.id,
            positionTop: record.positionTop,
            positionLeft: record.positionLeft,
          };
          const findDocumentLine = Object.keys(state.documentLines)
            .map(documentLine => parseInt(documentLine, 10))
            .includes(record.documentLine);

          draft.documentLines[record.documentLine] = findDocumentLine
            ? [...state.documentLines[record.documentLine], documentLineRecord]
            : [documentLineRecord];
        }
        break;

      case types.DELETE_RECORD:
        if (
          action.table === 'XmlText' &&
          state.documentLines[action.payload.documentLine]
        ) {
          const record = action.payload;

          const lineDeleted = state.documentLines[record.documentLine].filter(
            xmlText => xmlText.id !== record.id,
          );
          lineDeleted.length > 0
            ? (draft.documentLines[record.documentLine] = lineDeleted)
            : delete draft.documentLines[record.documentLine];
        }
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'lineTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      default:
        break;
    }
  });
