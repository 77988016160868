import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Labels = React.memo(
  ({ remarks, actions }) =>
    remarks && (
      <div
        style={style}
        title={`•   ${remarks.map(remark => remark.remark).join('\n•   ')}`}
      >
        {remarks.map(remark => (
          <Label
            size="small"
            color="green"
            key={remark.id}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              actions.openRecordEditorModal('Remark', remark.id);
            }}
          >
            {remark.remark}
            <Icon
              name="delete"
              onClick={event => {
                event.stopPropagation();
                actions.deleteRecord('Remark', remark);
              }}
            />
          </Label>
        ))}
      </div>
    ),
);

const style = { overflow: 'hidden', textOverflow: 'ellipsis' };

Labels.propTypes = {
  remarks: PropTypes.array,
};

const Remarks = process.env.NODE_ENV !== 'test' ? connect()(Labels) : Labels;

export default Remarks;
