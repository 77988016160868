import pluralize from 'pluralize';

import * as types from 'config/types';
import * as API from 'lib/rest';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';

export const tablesWithHistory = ['Item'];

/**
 * Open modal for table and filled with record if id specified
 * @param {string} table
 * @param {?number} id id to fetch
 */
export const openRecordEditorModal = (table, id = null) => async dispatch => {
  try {
    let record = {};
    if (id !== null) {
      const urlRecord = API.buildURL({ table, id });
      record = await API.get(urlRecord);
    }

    const urlAttributes = API.buildURL({
      table,
      function: 'getModelAttributes',
    });
    const attributes = await API.get(urlAttributes);
    if (id !== null && record === undefined)
      dispatch(
        enqueueSnackbar({
          message: `<div>
              ${table} <b>${id}</b> not found
            </div>`,
          options: {
            variant: 'warning',
          },
        }),
      );

    const open = record !== undefined && attributes !== undefined;

    dispatch({
      type: types.OPEN_RECORD_MODAL,
      table,
      payload: {
        open,
        record: record ? record : {},
        attributes: attributes ? attributes : [],
      },
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Close record modal and clear record redux state
 */
export const closeRecordEditorModal = () => dispatch => {
  dispatch({
    type: types.CLOSE_RECORD_MODAL,
  });
};

/**
 * Update record in table
 * @param {string} table
 * @param {*} record record to update
 * @param {?boolean} createHistory create a history of this update, false by default
 * @param {?string} source source of the update
 */
export const updateRecord = (
  table,
  record,
  createHistory = false,
  source,
) => async dispatch => {
  try {
    if (!record.id) return false;

    const url = API.buildURL({
      table,
      function: 'update',
    });
    const body = {
      where: {
        id: record.id,
      },
      values: record,
      createHistory,
      source,
    };
    const update = await API.post(url, body, false);
    const failure = update.status >= 400;
    const [updatedRecord] = await update.json();
    if (failure) return false;
    dispatch(
      enqueueSnackbar({
        message: `<div>
            ${table} <b>${record.id}</b> updated
          </div>`,
        options: {
          variant: 'info',
        },
      }),
    );

    dispatch({
      type: types.UPDATE_RECORD,
      table,
      payload: updatedRecord,
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Update record in table
 * @param {string} table
 * @param {array} ids ids to update
 * @param {*} values object of value
 * @param {?boolean} notify display notification in snackbar
 * @param {?string} source source of the update
 */
export const batchUpdate = (
  table,
  ids,
  values,
  notify = true,
  source,
) => async dispatch => {
  try {
    if (!ids || !ids.length) return false;

    const url = API.buildURL({
      table,
      function: 'update',
    });
    const update = await API.post(
      url,
      {
        where: {
          id: ids,
        },
        values,
        source,
      },
      false,
    );

    const failure = update.status >= 400;
    if (failure) return false;

    if (notify)
      dispatch(
        enqueueSnackbar({
          message: `<div>
              <b>${ids.length}</b> ${table} ${pluralize(
            'records',
            ids.length,
          )} updated
            </div>`,
          options: {
            variant: 'info',
          },
        }),
      );

    dispatch({
      type: types.BATCH_UPDATE,
      table,
      payload: { ids, values },
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Create record in table
 * @param {string} table
 * @param {*} record record to create
 */
export const createRecord = (table, record) => async dispatch => {
  try {
    const url = API.buildURL({
      table,
      function: 'create',
    });
    const create = await API.post(url, record);

    if (create && create.id !== undefined) {
      dispatch(
        enqueueSnackbar({
          message: `<div>
              ${table} <b>${create.id}</b> created
            </div>`,
          options: {
            variant: 'success',
          },
        }),
      );

      dispatch({
        type: types.CREATE_RECORD,
        table,
        payload: create,
      });

      return create.id;
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Create records in table
 * @param {string} table
 * @param {array} records records to create
 */
export const bulkCreateRecord = (table, records) => async dispatch => {
  try {
    const url = API.buildURL({
      table,
      function: 'bulkCreate',
    });
    const create = await API.post(url, records);

    if (create && create.length) {
      dispatch(
        enqueueSnackbar({
          message: `<div>
              <b>${create.length}</b> ${table} created
            </div>`,
          options: {
            variant: 'success',
          },
        }),
      );

      dispatch({
        type: types.CREATE_RECORDS,
        table,
        payload: create,
      });
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Delete record in table
 * @param {string} table
 * @param {number} record record to delete
 */
export const deleteRecord = (table, record) => async dispatch => {
  try {
    if (!record.id) return false;

    const url = API.buildURL({
      table,
      id: record.id,
      function: 'delete',
    });
    const deleted = await API.get(url, null, false);
    const failure = deleted.status >= 400;
    if (!failure) {
      dispatch(
        enqueueSnackbar({
          message: `<div>
              ${table} <b>${record.id}</b> deleted
            </div>`,
          options: {
            variant: 'error',
          },
        }),
      );

      dispatch({
        type: types.DELETE_RECORD,
        table,
        payload: record,
      });
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};
