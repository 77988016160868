import { pick } from 'lodash';

export const ROW_GAP = 4;
export const HEADER_HEIGHT = 25;
export const DEFAULT_ROW_HEIGHT = 55;
export const GROUP_HEADER_HEIGHT_DIFF = 12;
export const GROUP_HEADER_ROW_HEIGHT =
  DEFAULT_ROW_HEIGHT - GROUP_HEADER_HEIGHT_DIFF;

/**
 * Search a value in a table
 * @param {array} header table header
 * @param {array} data table data
 * @param {string} search value to search
 * @param {string} column search column, if null search in all displayed columns
 */
export const searchData = (header, data, search, column = null) => {
  if (search.length === 0) return data;

  const keys = header.map(column => column.searchColumn || column.dataKey);
  const matchingData = data.filter(_datum => {
    // Only use displayed columns
    const datum = pick(_datum, keys);
    if (!column)
      return Object.keys(datum).some(key =>
        dataIncludesValue(datum, search, key),
      );

    return dataIncludesValue(datum, search, column);
  });
  return matchingData.length ? matchingData : ['NO DATA'];
};

/**
 * Search a value in a record
 * @param {*} data record data
 * @param {string} search value to search
 * @param {string} column search column
 */
export const dataIncludesValue = (data, search, column) => {
  if (
    (Array.isArray(data[column]) && data[column].length) ||
    (Array.isArray(data) && data.length)
  )
    return (data[column] || data).some(value => {
      return `${value[column] || value}`
        .toUpperCase()
        .includes(search.toUpperCase());
    });

  if (column && !data[column]) return false;

  return `${data[column] || data}`.toUpperCase().includes(search.toUpperCase());
};

/**
 * Define if the data is a table group header
 * @param {*} datum line data
 */
export const isGroupHeader = datum =>
  typeof datum === 'string' && datum !== 'NO DATA';

export const itemTable = {
  /**
   * Define the row height in regards of the quantity of lines needing to show all data from eff-loc
   * @param {*} effectivityLocation eff-loc data
   */
  getRowHeight({ isAttachingPart, effectivityLocation }) {
    if (isAttachingPart) return 30;
    const extraRowHeight =
      effectivityLocation &&
      typeof effectivityLocation !== 'string' &&
      Object.keys(effectivityLocation).length > 1
        ? 19 * Object.keys(effectivityLocation).length
        : 0;
    return 55 + extraRowHeight;
  },

  /**
   * Define if the row is extendable
   * @param {*} rowData data of the line
   */
  isRowExtendable(rowData) {
    return !!(rowData.NHA || rowData.BKDN);
  },

  getRowExtensionHeight(rowData, extensionRefs, extendedRows) {
    if (extensionRefs[rowData.id] && extendedRows.includes(rowData.id)) {
      return extensionRefs[rowData.id]?.clientHeight + 11;
    }
    return 40;
  },

  getExtensionStyle() {
    return { paddingBottom: 10 };
  },

  /**
   * Define the row style in regards of it's an attachingPart or not
   * @param {*} item data of the line
   */
  getRowStyle(item) {
    return item.isAttachingPart
      ? { backgroundColor: 'transparent', fontWeight: 'bold' }
      : {};
  },

  /**
   * Define either the row is selectable. attachingPart row aren't
   * @param {*} item data of the line
   */
  isRowSelectable(item) {
    return !item.isAttachingPart;
  },
};
