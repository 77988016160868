import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  user: null,
  token: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.LOGIN:
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        break;

      case types.LOGOUT:
        draft.user = null;
        draft.token = null;
        break;

      default:
        break;
    }
  });
