import React, { Component } from 'react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';
import { makeGetLineTableSearch } from 'selectors/tableSelector';

import {
  pageButtonRenderer,
  documentLineRenderer,
  sizeRenderer,
} from 'components/table/containers/columnRenderers';
import M3dianTable from 'components/table/Table';

class LineTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        {
          label: 'Page',
          cellRenderer: pageButtonRenderer,
          dataKey: 'documentPage',
          width: 1 / 18,
        },
        {
          label: 'Line',
          cellRenderer: documentLineRenderer,
          dataKey: 'documentLine',
          width: 1 / 18,
        },
        {
          label: 'Text',
          dataKey: 'text',
          width: 5 / 6,
        },
        {
          label: 'Size',
          cellRenderer: this.sizeRenderer,
          dataKey: 'size',
          width: 1 / 18,
        },
      ],
    };
  }

  sizeRenderer = data => sizeRenderer(data, this.SIZE_LIMIT);

  SIZE_LIMIT = 150;

  componentDidMount() {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;

    document.title = 'M3DIAN V2 - LINE';
    this.props.actions.getDocumentLines();
    this.props.actions.changeTableHeader(this.state.header, this.props.modal);
  }

  componentWillUnmount() {
    document.title = 'M3DIAN V2';
  }

  componentDidUpdate(prevProps) {
    if (process.env.NODE_ENV !== 'test' && !this.props.auth.token) return;

    // In case of access when disconnected
    if (prevProps.auth.token !== this.props.auth.token)
      this.props.actions.getDocumentLines();

    // Handle document change
    if (prevProps.currentDocumentId !== this.props.currentDocumentId)
      this.props.actions.getDocumentLines();

    if (this.props.refresh) {
      this.props.actions.getDocumentLines();
      this.props.actions.refreshTable(true);
    }
  }

  render() {
    const { documentLines, highlight, scrollTo, modal, gridStyle } = this.props;

    return (
      <M3dianTable
        modal={modal}
        name="documentLineTable"
        sortBy={['documentPage', 'documentLine', 'size']}
        header={this.state.header}
        data={documentLines}
        highlight={highlight}
        scrollTo={scrollTo}
        gridStyle={gridStyle}
      />
    );
  }
}

LineTable.propTypes = {
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  documentLines: PropTypes.array,
  gridStyle: PropTypes.object,
  highlight: PropTypes.array,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
};

const makeMapStateToProps = () => {
  const getDocumentLineTableSearch = makeGetLineTableSearch();
  const mapStateToProps = (state, props) => {
    return {
      documentLines: getDocumentLineTableSearch(state, props.modal),
      scrollTo: state.lineTable.scrollTo,
      highlight: state.lineTable.highlight,
      refresh: state.table.refresh,
    };
  };
  return mapStateToProps;
};

export default process.env.NODE_ENV !== 'test'
  ? connect(makeMapStateToProps())(LineTable)
  : LineTable;
