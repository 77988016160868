import { sortByFields } from 'ais-utils';
import { sortItems } from 'lib/utils';
import _ from 'lodash';
import { createSelector } from 'reselect';

export const getCurrentDocument = state => state.utils.currentDocument;

export const getXmlTexts = state => state.utils.XmlText;

export const getLocationCategory = state => state.utils.LocationCategory;

export const getFamilies = state => state.utils.Family;

export const getSubAssemblyCategories = state =>
  state.utils.SubAssemblyCategory;

export const getItemCoordinates = state => state.utils.ItemCoordinate;

export const getDocuments = createSelector(
  state => state.utils.Document,
  getFamilies,
  (documents = [], families = {}) => {
    const [noData] = documents;
    if (noData === 'NO DATA') return [noData];
    return documents.length
      ? sortByFields(
          documents.map(document => ({
            ...document,
            Family: families[document.FamilyId],
          })),
          ['id', 'DESC'],
        )
      : [];
  },
);

export const getLopas = createSelector(
  state => state.utils.Lopa,
  getFamilies,
  (lopas = [], families = {}) => {
    const [noData] = lopas;
    if (noData === 'NO DATA') return [noData];
    return lopas.length
      ? sortByFields(
          lopas.map(lopa => ({
            ...lopa,
            Family: families[lopa.FamilyId],
          })),
          ['id', 'DESC'],
        )
      : [];
  },
);

export const getFilteredDocuments = createSelector(
  state => state.home.filteredInfo,
  getDocuments,
  (filteredInfo = {}, documents = []) => {
    const [noData] = documents;
    if (!documents.length || !filteredInfo || noData === 'NO DATA')
      return documents;

    const filteredDocuments = documents.filter(document => {
      return Object.keys(filteredInfo).every(
        key =>
          document[key] &&
          (document[key] === filteredInfo[key] ||
            document[key].name === filteredInfo[key]),
      );
    });

    return filteredDocuments.length ? filteredDocuments : ['NO DATA'];
  },
);

export const getDocumentSuggestions = documents => {
  let filteredDictionary = {
    type: [],
    Family: [],
    customer: [],
    manufacturer: [],
    fleet: [],
    status: [],
    semanticGroup: [],
  };

  if (!documents || (documents.length && documents[0] === 'NO DATA'))
    return filteredDictionary;

  documents.forEach(document => {
    Object.keys(document).forEach(documentKey => {
      if (Object.keys(filteredDictionary).includes(documentKey)) {
        const value =
          document[documentKey] &&
          (document[documentKey].name || document[documentKey]);
        if (!value) return;

        filteredDictionary[documentKey] = [
          ...new Set([...filteredDictionary[documentKey], value]),
        ];
      }
    });
  });

  return filteredDictionary;
};

export const getAllDocumentsSuggestions = createSelector(
  getDocuments,
  getDocumentSuggestions,
);

export const getFilteredDocumentsSuggestions = createSelector(
  getFilteredDocuments,
  getDocumentSuggestions,
);

export const getXmlPages = createSelector(
  state => state.utils.XmlPage,
  (state, section) => section,
  (xmlPages, section = null) => {
    return sortByFields(
      typeof section === 'string'
        ? xmlPages.filter(
            xmlPage =>
              xmlPage.section === (section === 'blank' ? null : section),
          )
        : xmlPages,
      ['documentPage', 'ASC'],
    );
  },
);

export const getItems = createSelector(
  state => state.utils.Item,
  items => {
    const [noData] = items;
    return noData !== 'NO DATA' ? sortItems(items) : [noData];
  },
);

export const getEquipments = createSelector(
  state => state.utils.Equipment,
  equipments => sortByFields(equipments, ['figureDoc', 'ASC'], false),
);

/*
  We get all records from Figure, so each figure multiple time if the figure has multiple sheets
*/
export const getFigures = createSelector(
  state => state.utils.Figure,
  figures =>
    sortByFields(
      figures,
      ['figureNumeric', 'ASC'],
      ['figureSheet', 'ASC'],
      false,
    ),
);

/*
  We get records from Figure where FigureDoc are different
*/
export const getGroupedFigureByFigureDoc = createSelector(
  getFigures,
  (figures = []) => {
    return _.uniqBy(figures, 'figureDoc');
  },
);

export const getEquipmentSideLetterings = createSelector(
  getEquipments,
  equipments => {
    const sideLetterings = [];

    equipments.forEach(equipment => {
      if (!equipment.lettering || !equipment.side) return;

      if (
        sideLetterings.some(
          sideLettering =>
            sideLettering.lettering === equipment.lettering &&
            sideLettering.side === equipment.side,
        )
      )
        return;

      sideLetterings.push({
        lettering: equipment.lettering,
        side: equipment.side,
      });
    });

    return sideLetterings;
  },
);
