import { push } from 'connected-react-router';

import * as types from 'config/types';
import * as network from 'config/network';
import * as API from 'lib/rest';

export const verifyCredentials = (
  login,
  password,
  noNavigation,
) => async dispatch => {
  try {
    const url = API.buildURL(network.ENDPOINTS.auth.login);
    const response = await API.post(url, {
      login,
      password,
    });

    if (response && response.user) {
      const { user } = response;
      delete user.password;

      dispatch({
        type: types.LOGIN,
        payload: { user, token: response.token },
      });
      if (!noNavigation) dispatch(push('/'));
    }
    if (response && response.message) throw response;

    if (!response) return false;
    return true;
  } catch (err) {
    return err;
  }
};

export const logout = () => ({
  type: types.LOGOUT,
});
