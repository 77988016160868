import * as React from 'react';
import classnames from 'classnames';

import { color } from 'config/constants';

import { isGroupHeader, ROW_GAP, GROUP_HEADER_HEIGHT_DIFF } from '../utils';

const rowRenderer = ({
  className,
  columns,
  index,
  key,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  rowData,
  clickable,
  isSelected,
  isRowSelectable,
  isGrouped,
  isShadowSelected,
  isHighlighted,
  style,
  extensionStyle: _extensionStyle,
  isExtended,
  isExtendEnable,
  extensionRenderer,
  toggleExtendable,
  getRowExtensionHeight,
  getRowHeight,
  hasAnomaly,
  tableSize,
  data = [],
  isSelectAnchor,
  isRowExtendable,
}) => {
  const a11yProps = { 'aria-rowindex': index + 1 };
  const isExtendable = isRowExtendable && isRowExtendable(rowData);

  if (
    onRowClick ||
    onRowDoubleClick ||
    onRowMouseOut ||
    onRowMouseOver ||
    onRowRightClick
  ) {
    a11yProps['aria-label'] = 'row';
    a11yProps.tabIndex = 0;

    if (onRowClick) {
      a11yProps.onClick = event => {
        onRowClick({ event, index, rowData });
        if (isExtendEnable && isExtendable && extensionRenderer)
          toggleExtendable(index);
      };
    }

    if (onRowDoubleClick) {
      a11yProps.onDoubleClick = event =>
        onRowDoubleClick({ event, index, rowData });
    }

    if (onRowMouseOut) {
      a11yProps.onMouseOut = event => onRowMouseOut({ event, index, rowData });
    }

    if (onRowMouseOver) {
      a11yProps.onMouseOver = event =>
        onRowMouseOver({ event, index, rowData });
    }

    if (onRowRightClick) {
      a11yProps.onContextMenu = event =>
        onRowRightClick({ event, index, rowData });
    }
  }

  let backgroundColor = null;
  if (isShadowSelected) backgroundColor = color.table.shadowSelected.background;
  if (isHighlighted) backgroundColor = color.table.highlighted.background;
  if (isSelected) backgroundColor = color.table.selected.background;

  let borderColor = null;
  if (isShadowSelected) borderColor = color.table.shadowSelected.border;
  if (isHighlighted) borderColor = color.table.highlighted.border;
  if (isSelected) borderColor = color.table.selected.border;
  if (rowData.isBordered) borderColor = 'rgb(0, 0, 0)';
  if (isSelectAnchor) borderColor = color.table.selected.anchor;

  const isTopExtension = isExtendable === 'top';

  const commonsStyle = {
    fontWeight: isSelected && 900,
    width: style.width,
    cursor:
      (clickable || (isExtendEnable && isExtendable && extensionRenderer)) &&
      'pointer',
    outline: 'none',
    borderStyle: (isSelectAnchor || rowData.isBordered) && 'solid',
    borderColor,
    boxSizing: 'border-box',
    backgroundColor,
  };

  let rowStyle = {
    ...commonsStyle,
    height: getRowHeight({ index, width: style.width }, false) - ROW_GAP,
    borderWidth: '1px',
    borderTopLeftRadius: !isTopExtension && 'inherit',
    borderTopRightRadius: !isTopExtension && 'inherit',
    borderBottomLeftRadius: (!isExtended || isTopExtension) && 'inherit',
    borderBottomRightRadius: (!isExtended || isTopExtension) && 'inherit',
  };
  if (isExtended)
    rowStyle = {
      ...rowStyle,
      borderColor: 'unset',
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderBottomColor:
        isExtended && !isTopExtension ? 'transparent' : borderColor,
      borderTopColor:
        isExtended && isTopExtension ? 'transparent' : borderColor,
    };

  const extensionStyle = {
    ...commonsStyle,
    height: getRowExtensionHeight({ index, width: style.width }),
    top: getRowHeight({ index, width: style.width }, false),
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 1,
    borderWidth: isTopExtension ? '1px 1px 0px 1px' : '0px 1px 1px 1px',
    borderTopLeftRadius: (!isExtended || isTopExtension) && 'inherit',
    borderTopRightRadius: (!isExtended || isTopExtension) && 'inherit',
    borderBottomLeftRadius: !isTopExtension && 'inherit',
    borderBottomRightRadius: !isTopExtension && 'inherit',
    ..._extensionStyle,
  };

  const previousData = data.slice(0, index + 1).reverse();
  const hasGroupBefore = previousData.findIndex(isGroupHeader) !== -1;

  let { top } = style;
  if (isGrouped) {
    const isFirstRowAGroup = isGroupHeader(data[0]) || false;

    top = style.top - (hasGroupBefore ? GROUP_HEADER_HEIGHT_DIFF : 0);

    if (hasGroupBefore && !isFirstRowAGroup)
      top = top + GROUP_HEADER_HEIGHT_DIFF - ROW_GAP;
  }

  return (
    <div
      {...a11yProps}
      key={key}
      role="row"
      style={{
        backgroundColor: 'white',
        ...style,
        top,
        height: getRowHeight({ index, width: style.width }) - ROW_GAP,
        outline: 'none',
        borderRadius: '.28571429rem',
        display: 'flex',
        overflow: 'visible',
        flexDirection: isTopExtension ? 'column-reverse' : 'column',
        zIndex: tableSize - index,
      }}
      className={classnames('table_row', {
        selectable: isRowSelectable ? isRowSelectable(rowData) : true,
      })}
    >
      <div
        className={classnames(className, {
          has_anomaly: hasAnomaly,
          is_extendable: isExtendEnable && isExtendable && extensionRenderer,
          bottom: isExtended && !isTopExtension,
          top: isExtended && isTopExtension,
          main: true,
        })}
        style={rowStyle}
      >
        {columns}
      </div>
      {isExtended && extensionRenderer && (
        <div
          className={`${className} extension ${
            isExtendable === 'top'
              ? 'scale-vertically-from-bottom'
              : 'scale-vertically-from-top'
          }`}
          style={extensionStyle}
        >
          {extensionRenderer({ rowData, rowIndex: index })}
        </div>
      )}
    </div>
  );
};

export { rowRenderer, ROW_GAP };
