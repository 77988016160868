import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import connect from 'lib/reduxConnect';

const Button = React.memo(
  ({ table, id, actions, text, circular }) => (
    <SemanticButton
      compact
      basic
      color="blue"
      size="tiny"
      style={styles.button}
      title={text !== undefined ? text : id}
      className="link"
      circular={circular || false}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        actions.openRecordEditorModal(table, id);
      }}
    >
      {text !== undefined ? text : id}
    </SemanticButton>
  ),
  (prevProps, nextProps) => prevProps.text === nextProps.text,
);

const IdButton = process.env.NODE_ENV !== 'test' ? connect()(Button) : Button;

IdButton.propTypes = {
  id: PropTypes.number,
  table: PropTypes.string,
};

const styles = {
  button: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
};

export default IdButton;
