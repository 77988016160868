import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

export const getLocationCategoryForSemanticGroup = () => async (
  dispatch,
  getState,
) => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.LocationCategory.getSemanticGroup,
    );

    const where = {
      semanticGroup: getState().utils.currentDocument.semanticGroup,
    };

    const locationCategoriesForSemanticGroup = await API.get(url, where);
    dispatch({
      type: types.GET_LOCATION_CATEGORY_FOR_SEMANTIC_GROUP,
      payload: locationCategoriesForSemanticGroup?.length
        ? locationCategoriesForSemanticGroup
        : ['NO DATA'],
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};
