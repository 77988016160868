import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import classnames from 'classnames';
import { uniqBy } from 'lodash';

import connect from 'lib/reduxConnect';
import { getFigures } from 'selectors/utilsSelector';
import { renderFigureText } from 'lib/utils';

class FigureFilter extends Component {
  static defaultProps = {
    all: true,
    groupBy: 'id',
    figures: [],
    basic: false,
    icon: 'filter',
  };

  state = { open: false };

  componentDidMount() {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;
    if (!isUserConnected) return;
    this.props.actions.getTable('Figure');
  }

  componentDidUpdate(prevProps) {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;
    const hasUserConnected = prevProps.auth.token !== this.props.auth.token;
    const hasDocumentChanged =
      prevProps.currentDocumentId !== this.props.currentDocumentId;
    if (!isUserConnected) return;

    if (hasDocumentChanged || hasUserConnected)
      this.props.actions.getTable('Figure');
  }

  all = { id: 'all', [this.props.groupBy]: 'all', description: 'ALL' };
  buildOptions() {
    const figures =
      this.props.all === false
        ? this.props.figures
        : [this.all, ...this.props.figures];

    return uniqBy(figures, this.props.groupBy).map(figure => {
      const description =
        this.props.descriptions &&
        this.props.descriptions[figure[this.props.groupBy]];

      return {
        value: figure[this.props.groupBy] || figure.id,
        text: figure.description,
        description: this.props.descriptions && figure.id !== 'all' && (
          <div
            style={{
              color: description?.value || description?.count ? 'green' : 'red',
            }}
          >
            {description ? description.value || description.count : 0}
          </div>
        ),
      };
    });
  }

  handleClick = (e, { value }) => {
    this.setState({ open: false });
    this.props.actions.changeFigureFilter(
      this.props.table,
      value === 'all'
        ? this.all
        : this.props.figures.find(
            figure => figure[this.props.groupBy] === value,
          ),
    );
  };

  render() {
    const {
      value,
      figures,
      groupBy,
      table,
      icon,
      basic,
      placeholder,
      style,
    } = this.props;

    const text = renderFigureText(value, figures, groupBy);

    const [noData] = figures;
    if (noData === 'NO DATA') return null;

    return table ? (
      <Dropdown
        text={text}
        icon={icon}
        labeled
        title={text}
        button={!basic}
        placeholder={placeholder}
        lazyLoad={!basic}
        className={classnames('icon margin ellipsis', {
          inverted_dropdown: basic,
        })}
        onClick={() => this.setState({ open: !this.state.open })}
        onBlur={() => this.setState({ open: false })}
        open={this.state.open}
        style={{ ...styles.centered, ...style }}
        scrolling
        fluid
        loading={figures.length === 0}
      >
        <Dropdown.Menu className="figure_filter_list">
          {(this.state.open ? this.buildOptions() : []).map(option => (
            <Dropdown.Item
              active={
                value &&
                (value[groupBy] === option.value || value.id === option.value)
              }
              key={option.value}
              {...option}
              onClick={
                !(
                  value &&
                  (value[groupBy] === option.value || value.id === option.value)
                )
                  ? this.handleClick.bind(this)
                  : null
              }
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : null;
  }
}

const styles = {
  centered: { display: 'flex', alignItems: 'center' },
};

FigureFilter.propTypes = {
  all: PropTypes.bool,
  auth: PropTypes.object,
  basic: PropTypes.bool,
  currentDocumentId: PropTypes.number,
  descriptions: PropTypes.object,
  figures: PropTypes.array,
  groupBy: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  placeholder: PropTypes.string,
  searchInput: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.object,
  ]),
  style: PropTypes.object,
  table: PropTypes.string,
  value: PropTypes.object,
};

const mapStateToProps = state => ({
  figures: getFigures(state),
});

export default process.env.NODE_ENV !== 'test'
  ? connect(mapStateToProps)(FigureFilter)
  : FigureFilter;
