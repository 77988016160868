import React from 'react';
import { Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const FigureSheetLabel = React.memo(({ text, color, backgroundColor }) => (
  <Label
    title={text}
    size="small"
    style={{
      color,
      backgroundColor,
      ...style,
    }}
  >
    {text}
  </Label>
));

const style = {
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

FigureSheetLabel.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
};

export default FigureSheetLabel;
