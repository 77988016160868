import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  currentDocumentAnomalies: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ANOMALIES_GET_FOR_DOCUMENT:
        draft.currentDocumentAnomalies = action.payload;
        break;

      case types.BATCH_UPDATE: {
        if (action.table === 'Anomaly') {
          const updatedDocumentAnomalies = state.currentDocumentAnomalies.map(
            anomaly =>
              action.payload.ids.includes(anomaly.id)
                ? { ...anomaly, ...action.payload.values }
                : anomaly,
          );
          draft.currentDocumentAnomalies = updatedDocumentAnomalies;
        }
        break;
      }

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.currentDocumentAnomalies = initialState.currentDocumentAnomalies;
        break;

      default:
        break;
    }
  });
