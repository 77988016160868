import React from 'react';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';

import { color } from 'config/constants';
import { DEFAULT_ROW_HEIGHT, ROW_GAP } from 'components/table/utils';
import { changeItemTableFilter, openTableModal } from 'actions';
import { isNotNullish } from 'lib/utils';

import ItemButton from './ItemButton';
import PageButton from './PageButton';
import IdButton from './IdButton';
import FigureButton from './FigureButton';
import FigureSheetLabel from './FigureSheetLabel';
import LetteringDropdown from './LetteringDropdown';
import DocumentIdButton from './DocumentIdButton';
import LastStepButton from './LastStepButton';
import UsernameLabels from './UsernameLabels';
import IdButtonLocationEditor from './IdButtonLocationEditor';
import Label from './Label';
import POCTime from './POCTime';
import DocumentLineButton from './DocumentLineButton';
import SectionButton from './SectionButton';
import Input from './Input';
import ItemRepresentations from './ItemRepresentations';
import EffectivityLocation from './EffectivityLocation';
import LayerSubAssembly from './LayerSubAssembly';
import Remarks from './Remarks';
import DocumentProgress from './DocumentProgress';
import ItemDescription from './ItemDescription';
import FigureDescription from './FigureDescription';
import Indentation from './Indentation';

const styles = {
  noData: {
    fontWeight: 'bold',
    alignItems: 'center',
    height: DEFAULT_ROW_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '.28571429rem',
  },
  fullHeight: {
    height: '100%',
  },
};

export const itemButtonRenderer = ({ rowData }) => {
  const xmlTextIds = rowData?.XmlTexts.map(xmlText => xmlText.id);

  return (
    rowData && (
      <ItemButton highlight={xmlTextIds} documentPage={rowData.documentPage} />
    )
  );
};

export const pageButtonRenderer = ({ cellData, rowData }) => (
  <PageButton
    documentPage={cellData}
    highlight={rowData && rowData.XmlTextIds}
  />
);

export const documentIdRenderer = ({ cellData, rowData }) => (
  <DocumentIdButton
    text={cellData}
    color={rowData.label && rowData.label.color}
    document={rowData}
  />
);

export const familyRenderer = ({ cellData, rowData }) =>
  (rowData.Family && rowData.Family.name) || cellData;

export const lastStepRenderer = ({ cellData, rowData }) => {
  return cellData ? (
    <LastStepButton step={cellData} document={rowData} />
  ) : (
    'LOADING'
  );
};

export const usernamesRenderer = ({ cellData, rowData }) =>
  cellData ? (
    <UsernameLabels usernames={cellData} DocumentId={rowData.id} />
  ) : (
    'LOADING'
  );

export const documentProgressRenderer = ({ cellData }) =>
  isNotNullish(cellData) ? <DocumentProgress progress={cellData} /> : 'LOADING';

export const letteringRenderer = ({ cellData, rowData }, tableRef) => (
  <LetteringDropdown
    cellData={cellData}
    rowData={rowData}
    tableRef={tableRef}
  />
);

export const idButtonRenderer = ({ cellData, rowData }, table) => (
  <IdButton table={table} id={rowData.id} text={cellData} />
);

export const idButtonLocationEditorRenderer = ({ rowData }, disabled) => (
  <IdButtonLocationEditor
    id={rowData.id}
    disabled={disabled}
    value={rowData.location}
  />
);

export const itemDocRenderer = ({ rowData }) => rowData.itemDoc;

export const labelRenderer = ({ cellData }) => (
  <Label content={cellData} circular />
);

export const timeRenderer = ({ cellData, rowData }) => (
  <POCTime
    time={cellData}
    averageTime={rowData.averageTime}
    fetchTime={rowData.fetchTime}
  />
);

export const figureButtonRenderer = ({ cellData, rowData }) => {
  if (!rowData?.Figure?.documentPage) return rowData.figureDoc;

  const xmlTextIds = rowData.ItemCoordinates?.length
    ? rowData.ItemCoordinates.map(itemCoordinate => itemCoordinate.XmlTextId)
    : rowData.XmlTextId
    ? [rowData.XmlTextId]
    : [];

  return (
    <FigureButton highlight={xmlTextIds} text={cellData} record={rowData} />
  );
};

export const documentLineRenderer = ({ cellData, rowData }) => (
  <DocumentLineButton
    text={cellData}
    documentPage={rowData.documentPage}
    XmlTextIds={rowData.XmlTextIds}
  />
);

export const sizeRenderer = ({ cellData }, sizeLimit) => (
  <Label
    content={cellData}
    size="small"
    color={cellData > sizeLimit ? 'red' : 'green'}
  />
);

export const countRenderer = ({ cellData, rowData }, type) => {
  const onClick = () => [
    changeItemTableFilter({
      [type]: rowData[type],
    }),
    openTableModal('item'),
  ];

  return <Label onClick={onClick} circular color="blue" content={cellData} />;
};

export const countAverageRenderer = (
  { cellData, rowData },
  countAverages,
  averageIncertitude,
) => {
  const { count, quantity } = countAverages[rowData.section] || {
    count: 0,
    quantity: 1,
  };
  return (
    <Label
      size="tiny"
      color={
        Object.keys(countAverages).length > 1
          ? (count / quantity) * averageIncertitude > cellData ||
            cellData > (count / quantity) * (1 + averageIncertitude)
            ? 'red'
            : 'green'
          : 'grey'
      }
      content={cellData}
    />
  );
};

export const sectionRenderer = ({ cellData, rowData }) => (
  <SectionButton
    color={
      color.viewer.page[rowData.section]
        ? color.viewer.page[rowData.section]
        : 'black'
    }
    section={cellData}
  />
);

export const figureSheetRenderer = ({ cellData, rowData }) => {
  if (!rowData || rowData === 'NO DATA') return;
  const letters = rowData.figureDoc.replace(/[0-9]/g, '');

  const textColor =
    letters.charCodeAt(letters.length - 1) % 2
      ? rowData.figureNumeric % 2
        ? color.hardPrimary
        : '#a84a0b'
      : rowData.figureNumeric % 2
      ? color.primary
      : '#f17827';
  const backgroundColor = rowData.figureNumeric % 2 ? color.odd : color.even;

  return (
    <FigureSheetLabel
      text={cellData}
      color={textColor}
      backgroundColor={backgroundColor}
    />
  );
};

export const inputRenderer = (
  { dataKey, cellData, rowData, rowIndex },
  disabled,
  onInputChange,
) => {
  return (
    <Input
      dataKey={dataKey}
      value={isNotNullish(cellData) ? cellData : ''}
      rowData={rowData}
      rowIndex={rowIndex}
      disabled={disabled}
      onChange={onInputChange}
    />
  );
};

export const itemsRepresentationRenderer = ({ cellData }) => (
  <ItemRepresentations itemRepresentations={cellData} />
);

export const itemDescriptionRenderer = (
  { cellData, rowData },
  { table, hasLineButton, hasNhdBkdnButtons },
) =>
  rowData !== 'NO DATA' && (
    <ItemDescription
      description={cellData}
      item={rowData}
      hasLineButton={hasLineButton}
      hasNhdBkdnButtons={hasNhdBkdnButtons}
      table={table}
    />
  );

export const loadingRenderer = (height, style = {}) => {
  return height > 120 ? (
    <Dimmer active inverted style={{ ...styles.noData, height, ...style }}>
      <Loader size="massive">Loading</Loader>
    </Dimmer>
  ) : (
    <div style={{ ...styles.noData, height, ...style }}>
      <Icon loading name="spinner" size="large" />
      Loading
    </div>
  );
};

export const noDataRenderer = height => {
  return (
    <div
      style={{
        ...styles.noData,
        height: height || DEFAULT_ROW_HEIGHT - ROW_GAP,
      }}
    >
      NO DATA
    </div>
  );
};

export const effectivityLocationRenderer = ({ rowData }) => (
  <EffectivityLocation
    locationList={rowData.locationList}
    effectivityLocation={rowData.effectivityLocation}
    rowData={rowData}
  />
);
export const lsaRenderer = ({ rowData }) => {
  const { LSAStatus } = rowData;
  if (!rowData.isAttachingPart)
    return <LayerSubAssembly LSAStatus={LSAStatus} />;
};

export const indentRenderer = (
  { rowData, isSelected, isShadowSelected, isHighlighted },
  extensionHeight = 0,
  rowHeight = DEFAULT_ROW_HEIGHT - ROW_GAP,
) => (
  <Indentation
    id={rowData.id}
    indentation={rowData.indentation}
    height={rowHeight + extensionHeight}
    isSelected={isSelected}
    isShadowSelected={isShadowSelected}
    isHighlighted={isHighlighted}
  />
);

export const attachingRenderer = ({ cellData, rowData }) => {
  if (rowData.isAttachingPart) {
    return;
  }
  return cellData;
};

export const remarkRenderer = ({ rowData }) => (
  <Remarks remarks={rowData.remark} />
);

export const figureDescriptionRenderer = ({ cellData }, regex) => (
  <FigureDescription description={cellData} regex={regex} />
);
