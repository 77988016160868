import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  savedExtraction: false, // will be useful once we get the cancel system
  alerts: [],
  extractionResults: {},
  processedOn: null,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.ITEM_EXTRACTION_NEW_RESULTS:
        draft.extractionResults = action.results.items;
        draft.alerts = action.results.alerts;
        draft.processedOn = action.results.processedOn;
        break;

      case types.CHANGE_CURRENT_DOCUMENT:
        draft.extractionResults = initialState.extractionResults;
        draft.alerts = initialState.alerts;
        draft.processedOn = initialState.processedOn;

        break;

      default:
        break;
    }
  });
