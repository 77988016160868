import produce from 'immer';

import * as types from 'config/types';

const initialState = {
  locationCategoriesForSemanticGroup: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.locationCategoriesForSemanticGroup =
          initialState.locationCategoriesForSemanticGroup;
        break;

      case types.GET_LOCATION_CATEGORY_FOR_SEMANTIC_GROUP:
        draft.locationCategoriesForSemanticGroup = action.payload;
        break;

      default:
        break;
    }
  });
