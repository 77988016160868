import pluralize from 'pluralize';

import * as types from 'config/types';
import * as API from 'lib/rest';
import * as network from 'config/network';

import { enqueueSnackbar } from 'components/snackbar/snackbarActions';
import { getRemarkData } from 'components/table/tables/item/itemActions';
import { getNotificationsForDocument } from 'components/notifications/notificationActions';
import { getAnomaliesForDocument } from 'components/anomalies/anomalyActions';
import { getStepProgress } from 'integration/integrationNavigationActions';
import { refreshTable } from 'components/table/tableActions';

/**
 * Create default description rules
 */
export const initDescriptionStep = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.steps.formatting.description.initDescriptionStep,
    );

    const where = {
      DocumentId: getState().utils.currentDocument.id,
    };

    const input = await API.get(url, where);
    if (input)
      dispatch({
        type: types.DESCRIPTION_GET_RULES,
        payload: input,
      });

    dispatch({
      type: types.DESCRIPTION_INIT,
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Return descriptions rules
 */
export const getDescriptionRules = (all = false) => async (
  dispatch,
  getState,
) => {
  try {
    const urlAttributes = API.buildURL({
      table: 'Input',
      function: 'get',
    });
    let where = {
      milestone: getState().description.milestone,
      step: getState().description.step,
    };
    if (!all)
      where = { ...where, DocumentId: getState().utils.currentDocument.id };

    const input = await API.get(urlAttributes, where);

    dispatch({
      type: types.DESCRIPTION_GET_RULES,
      all,
      payload: input && input.length ? input : ['NO DATA'],
    });
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Create new description rule or update a given rule (Input.id)
 * @param {string} type descriptionDoc or descriptionShort
 * @param {string} formula rule formula
 * @param {*} parameters formula parameters
 * @param {?number} id Input id
 * @param {?bool} defaultRule is a default rule (will not execute rule)
 */
export const createDescriptionRule = (
  type,
  formula,
  parameters,
  id = null,
  defaultRule = false,
) => async (dispatch, getState) => {
  try {
    const url = API.buildURL(
      network.ENDPOINTS.steps.formatting.description.createDescriptionRule,
    );
    const rule = await API.post(url, {
      DocumentId: defaultRule ? 0 : getState().utils.currentDocument.id,
      type,
      formula,
      parameters,
      id,
    });

    if (!defaultRule)
      dispatch(
        enqueueSnackbar({
          message: rule.success
            ? `<div>
            ${
              rule.result['Item description']
                ? `<div>${pluralize(
                    'Item description',
                    rule.result['Item description'],
                    true,
                  )} edited</div>`
                : ''
            }
            ${
              rule.result['Equipment description']
                ? `<div>${pluralize(
                    'Equipment description',
                    rule.result['Equipment description'],
                    true,
                  )} edited</div>`
                : ''
            }
            ${
              rule.result.remark
                ? `<div>${pluralize(
                    'remark',
                    rule.result.remark,
                    true,
                  )} created</div>`
                : ''
            }
            ${
              rule.result.anomaly
                ? `<div>${pluralize(
                    'anomaly',
                    rule.result.anomaly,
                    true,
                  )} remaining</div>`
                : ''
            }
          </div>`
            : `<div>${rule.result}</div>`,
          options: {
            variant: rule.success ? 'info' : id ? 'warning' : 'error',
          },
        }),
      );

    if (rule && rule.success)
      dispatch({
        type: types.CREATE_RECORD,
        table: 'Input',
        payload: rule.rule,
      });

    if (id)
      dispatch({
        type: types.UPDATE_RECORD,
        table: 'Input',
        payload: rule.rule,
      });

    if (!defaultRule) {
      if (
        type === 'descriptionDoc' &&
        getState().description.rules.filter(
          rule => rule.name === 'descriptionDoc',
        ).length &&
        !id &&
        rule.success
      )
        dispatch(
          enqueueSnackbar({
            message:
              'DESCRIPTION DOC rule created, re-apply step to get right values',
            options: {
              variant: 'warning',
            },
          }),
        );

      dispatch(getRemarkData());
      dispatch(refreshTable());
      dispatch(getNotificationsForDocument());
      dispatch(
        getAnomaliesForDocument(
          getState().utils.currentDocument.id,
          getState().description.milestone,
          getState().description.step,
        ),
      );
    }
    return rule.success;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Delete a description rule
 * @param {string} type descriptionDoc or descriptionShort
 * @param {*} rule rule record to delete
 * @param {?bool} defaultRule is a default rule
 */
export const deleteDescriptionRule = (
  type,
  rule,
  defaultRule = false,
) => async (dispatch, getState) => {
  try {
    const DocumentId = defaultRule ? 0 : getState().utils.currentDocument.id;
    const urlSort = API.buildURL(
      network.ENDPOINTS.steps.formatting.description.sortDescriptionRules,
    );
    // Re order rules
    await API.get(urlSort, {
      DocumentId,
      type,
      source: rule.sort,
      destination: -1,
    });
    dispatch({
      type: types.DESCRIPTION_SORT_RULES,
      payload: {
        type,
        DocumentId,
        source: rule.sort,
        destination: -1,
      },
    });
    // Delete rule
    const urlDelete = API.buildURL({
      table: 'Input',
      id: rule.id,
      function: 'delete',
    });
    const deleted = await API.get(urlDelete, null, false);
    const failure = deleted.status >= 400;
    if (!failure) {
      dispatch({
        type: types.DELETE_RECORD,
        table: 'Input',
        payload: rule,
      });
      if (!defaultRule)
        dispatch(
          enqueueSnackbar({
            message:
              'Rule deleted, think to re-apply step to take into account',
            options: {
              variant: 'warning',
            },
          }),
        );
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Process description step
 */
export const processDescription = () => async (dispatch, getState) => {
  try {
    const url = API.buildURL(network.ENDPOINTS.steps.process);
    const progress = await API.post(url, {
      DocumentId: getState().utils.currentDocument.id,
      milestone: getState().description.milestone,
      step: getState().description.step,
    });

    progress &&
      progress.message &&
      dispatch(
        enqueueSnackbar({
          message: progress.message,
          options: {
            variant: progress.success ? 'success' : 'error',
          },
        }),
      );

    dispatch(getStepProgress(getState().utils.currentDocument.id));
    dispatch(getRemarkData());
    dispatch(refreshTable());
    dispatch(getNotificationsForDocument());
    dispatch(
      getAnomaliesForDocument(
        getState().utils.currentDocument.id,
        getState().description.milestone,
        getState().description.step,
      ),
    );
  } catch (err) {
    console.error(err);
  }
};
