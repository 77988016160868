import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label, Button } from 'semantic-ui-react';
import equal from 'deep-equal';

import connect from 'lib/reduxConnect';

import {
  makeGetItemTableSearch,
  getItemFigureFilter,
  getItemTableType,
} from 'selectors/tableSelector';
import {
  figureButtonRenderer,
  itemDescriptionRenderer,
  idButtonRenderer,
  remarkRenderer,
  effectivityLocationRenderer,
  indentRenderer,
  attachingRenderer,
} from 'components/table/containers/columnRenderers';
import { extensionNhaBkdnRenderer } from 'components/table/containers/extensionRenderers';
import { itemTable } from 'components/table/utils';

import FigureFilter from 'components/table/containers/FigureFilter';
import SwitchButton from 'components/utils/SwitchButton';

import M3dianTable from 'components/table/Table';

class Table extends Component {
  extensionRefs = [];

  constructor(props) {
    super(props);
    this.state = {
      extendedRows: [],
      header:
        this.props.type === 'classic' ? this.classicHeader : this.remarkHeader,
    };
  }

  componentDidMount() {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;

    if (!isUserConnected) return;

    this.init(true);
  }

  componentDidUpdate(prevProps) {
    const isUserConnected =
      process.env.NODE_ENV !== 'test' && this.props.auth.token;

    if (!isUserConnected) return;

    const hasUserConnected = prevProps.auth.token !== this.props.auth.token;
    const hasDocumentChanged =
      prevProps.currentDocumentId !== this.props.currentDocumentId;
    const hasTypeChanged = prevProps.type !== this.props.type;

    const hasFigureFilterChanged =
      this.props.filter === null &&
      prevProps.figureFilter !== this.props.figureFilter;
    const hasFilterChanged = !equal(prevProps.filter, this.props.filter);

    const hasItemsChanged =
      this.props.items.length &&
      this.props.items[0] !== 'NO DATA' &&
      this.props.filter &&
      !equal(prevProps.items, this.props.items);

    if (hasUserConnected || hasDocumentChanged || hasTypeChanged)
      this.init(true);

    if (hasFilterChanged || hasFigureFilterChanged) this.init();

    if (hasTypeChanged) this.updateHeader(this.props.type);

    if (hasItemsChanged)
      this.props.actions.getItemData(
        null,
        this.props.items.map(item => item.id),
      );

    // Handle table refresh
    if (this.props.refresh) {
      this.init();
      this.props.actions.refreshTable(true);
    }
  }

  updateHeader = type => {
    this.setState(
      {
        header: type === 'classic' ? this.classicHeader : this.remarkHeader,
      },
      () =>
        this.props.actions.changeTableHeader(
          this.state.header,
          this.props.modal,
        ),
    );
  };

  init(init = false) {
    const isRemarkTable = this.props.type === 'remark';

    this.props.actions.loadItemTable(init);
    this.props.actions.getTable('Figure');

    if (isRemarkTable) return this.props.actions.getTableCounts('Remark');
    this.props.actions.getTableCounts('Item');
  }

  onExtensionToggle = (event, { item: { id } }) => {
    event.stopPropagation();

    if (this.state.extendedRows.includes(id))
      return this.setState({
        extendedRows: this.state.extendedRows.filter(
          extendedRow => extendedRow !== id,
        ),
      });

    this.setState({
      extendedRows: [...this.state.extendedRows, id],
    });
  };

  idRenderer = data => idButtonRenderer(data, 'Item');

  descriptionRenderer = data =>
    itemDescriptionRenderer(data, {
      hasNhdBkdnButtons: false,
      hasLineButton: !data.rowData.isAttachingPart,
    });

  extensionNhaBkdnRenderer = data => {
    const isExtendable = this.extensionRefs[data.rowData.id]?.clientHeight > 40;
    return extensionNhaBkdnRenderer(data, {
      table: this.props.type === 'classic' ? 'itemTable' : 'remarkTable',
      isExtendable,
      isExtended: this.state.extendedRows.includes(data.rowData.id),
      onClick: isExtendable ? this.onExtensionToggle : () => {},
      getRef: ref => {
        this.extensionRefs[data.rowData.id] = ref;
      },
    });
  };

  indentRenderer = (data, dimensions) =>
    indentRenderer(data, dimensions?.extension?.height, dimensions?.height);

  figureInfo = ({ rowData }) => {
    if (rowData?.isAttachingPart) {
      return '';
    }
    return rowData?.Figure?.figureSheet;
  };

  figureButtonRenderer = ({ cellData, rowData }) => {
    return rowData?.isAttachingPart
      ? ''
      : figureButtonRenderer({ cellData, rowData });
  };

  classicHeader = [
    {
      dataKey: '',
      label: '',
      width: 0.5 / 18,
      cellRenderer: this.indentRenderer,
      fullHeight: true,
      overflow: true,
    },
    {
      label: 'Figure',
      cellRenderer: this.figureButtonRenderer,
      dataKey: 'Figure.figureSheet',
      cellDataGetter: this.figureInfo,
      width: 3 / 36,
    },
    {
      label: 'Item',
      cellRenderer: this.idRenderer,
      dataKey: 'itemDoc',
      width: 1 / 18,
    },
    {
      label: 'Part Number',
      dataKey: 'partNumber',
      width: 2 / 18,
    },
    {
      label: 'Description',
      cellRenderer: this.descriptionRenderer,
      dataKey: 'descriptionShort',
      width: 9 / 18,
      overflow: true,
    },
    {
      label: 'Effectivity - Location',
      dataKey: 'effectivity',
      width: 2.5 / 18,
      cellRenderer: effectivityLocationRenderer,
    },
    {
      label: 'Qty',
      dataKey: 'quantityPerAssy',
      width: 3 / 36,
      cellRenderer: attachingRenderer,
    },
  ];

  remarkHeader = [
    {
      dataKey: '',
      label: '',
      width: 0.5 / 18,
      cellRenderer: this.indentRenderer,
      fullHeight: true,
      overflow: true,
    },
    {
      label: 'Figure',
      cellRenderer: figureButtonRenderer,
      dataKey: 'Figure.figureSheet',
      cellDataGetter: ({ rowData }) => rowData?.Figure?.figureSheet,
      width: 3 / 36,
    },
    {
      label: 'Item',
      cellRenderer: this.idRenderer,
      dataKey: 'itemDoc',
      width: 1 / 18,
    },
    {
      label: 'Description',
      cellRenderer: this.descriptionRenderer,
      dataKey: 'descriptionShort',
      width: 7 / 18,
    },
    {
      label: 'Remarks',
      cellRenderer: remarkRenderer,
      dataKey: 'remarkCount',
      searchColumn: 'remark',
      width: 8 / 18,
    },
  ];

  onRowClick = async (rowData, newSelection) => {
    this.props.actions.highlightTable('itemTable');
    if (!rowData?.id) return;

    const descendants = await this.props.actions.getItemDescendants(
      newSelection,
    );

    const highlight =
      descendants &&
      Object.values(descendants).flatMap(
        itemDescendants => itemDescendants.children,
      );

    this.props.actions.highlightTable('itemTable', highlight || []);
  };

  getRowExtensionHeight = rowData =>
    itemTable.getRowExtensionHeight(
      rowData,
      this.extensionRefs,
      this.state.extendedRows,
    );

  render() {
    const {
      items,
      highlight,
      scrollTo,
      type,
      selected,
      filter,
      modal,
      gridStyle,
    } = this.props;
    return (
      <M3dianTable
        modal={modal}
        data={items}
        table="Item"
        selectable={modal ? !filter : true}
        multiple={false}
        selected={selected}
        extensionRenderer={this.extensionNhaBkdnRenderer}
        getExtensionStyle={itemTable.getExtensionStyle}
        getRowStyle={itemTable.getRowStyle}
        isRowSelectable={itemTable.isRowSelectable}
        isRowExtendable={itemTable.isRowExtendable}
        onRowClick={this.onRowClick}
        getRowHeight={itemTable.getRowHeight}
        getRowExtensionHeight={this.getRowExtensionHeight}
        isDefaultOpen
        toggleExtend={false}
        isExtendEnable={false}
        name={type === 'classic' ? 'itemTable' : 'remarkTable'}
        sortBy={[
          'id',
          'Figure.figureSheet',
          'itemDoc',
          'partNumber',
          'descriptionShort',
          'effectivity',
          'quantityPerAssy',
          'remarkCount',
        ]}
        header={this.state.header}
        highlight={highlight}
        scrollTo={scrollTo}
        gridStyle={gridStyle}
        isGroupByEnable={[{ dataKey: 'itemNumeric', label: 'Item Numeric' }]}
      />
    );
  }
}

Table.propTypes = {
  auth: PropTypes.object,
  currentDocumentId: PropTypes.number,
  figureFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  filter: PropTypes.object,
  gridStyle: PropTypes.object,
  highlight: PropTypes.array,
  items: PropTypes.array,
  modal: PropTypes.bool,
  refresh: PropTypes.bool,
  scrollTo: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  selected: PropTypes.array,
  type: PropTypes.string,
};

const makeMapStateToProps = () => {
  const getItemTableSearch = makeGetItemTableSearch(
    getItemFigureFilter,
    getItemTableType,
  );
  const mapStateToProps = (state, props) => {
    return {
      figureFilter: state.itemTable.figureFilter,
      items: getItemTableSearch(state, props.modal),
      scrollTo: state.itemTable.scrollTo,
      highlight: state.itemTable.highlight,
      type: state.itemTable.type,
      onlyRemarks: state.itemTable.onlyRemarks,
      refresh: state.table.refresh,
      filter: state.itemTable.filter,
      selected: state.itemTable.selected,
    };
  };
  return mapStateToProps;
};

export const ItemTable =
  process.env.NODE_ENV !== 'test'
    ? connect(makeMapStateToProps())(Table)
    : Table;

const Header = ({
  type,
  figureFilter,
  counts,
  onlyRemarks,
  filter,
  actions,
}) => (
  <React.Fragment>
    <SwitchButton
      style={styles.switch}
      first="Classic"
      second="Remark"
      firstActive={type === 'classic'}
      secondActive={type === 'remark'}
      onClick={() => actions.toggleItemTableType(null, true)}
    />
    {filter ? (
      <div style={styles.filterLabels}>
        {Object.keys(filter).map(key => (
          <div key={key} style={styles.label}>
            <Label>{key}</Label> {filter[key]}
          </div>
        ))}
      </div>
    ) : (
      figureFilter !== 'NO DATA' && (
        <FigureFilter
          table={type === 'classic' ? 'itemTable' : 'remarkTable'}
          value={figureFilter}
          groupBy="figureDoc"
          descriptions={counts}
          style={styles.marginLeft}
        />
      )
    )}
    {type === 'remark' && (
      <Button
        style={styles.marginLeft}
        toggle
        active={onlyRemarks}
        onClick={actions.toggleOnlyRemarks}
      >
        ONLY REMARKS
      </Button>
    )}
  </React.Fragment>
);

Header.propTypes = {
  counts: PropTypes.object,
  figureFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  filter: PropTypes.object,
  onlyRemarks: PropTypes.bool,
  type: PropTypes.string,
};

const styles = {
  filterLabels: {
    marginLeft: 5,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: { marginTop: 1, marginBottom: 1 },
  marginLeft: { marginLeft: 5, marginRight: 0 },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  switch: { width: '30%' },
};

const mapStateToProps = state => ({
  figureFilter: state.itemTable.figureFilter,
  counts: state.itemTable.counts,
  type: state.itemTable.type,
  onlyRemarks: state.itemTable.onlyRemarks,
  filter: state.itemTable.filter,
});

export const ItemTableHeader =
  process.env.NODE_ENV !== 'test' ? connect(mapStateToProps)(Header) : Header;
