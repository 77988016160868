import produce from 'immer';
import { toObject } from 'ais-utils';

import * as types from 'config/types';
import { getFirstFigure } from 'lib/utils';

const initialState = {
  type: 'classic',
  itemTable: {},
  nhaBkdn: {},
  onlyRemarks: false,
  figureFilter: null,
  counts: null,
  remarkTable: {},
  highlight: [],
  scrollTo: null,
  filter: null,
  selected: [],
  effectivityDistribution: false,
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.CHANGE_CURRENT_DOCUMENT:
        draft.itemTable = initialState.itemTable;
        draft.nhaBkdn = initialState.nhaBkdn;
        draft.figureFilter = initialState.figureFilter;
        draft.counts = initialState.counts;
        draft.remarkTable = initialState.remarkTable;
        draft.highlight = initialState.highlight;
        draft.scrollTo = initialState.scrollTo;
        draft.filter = initialState.filter;
        draft.selected = initialState.selected;
        draft.effectivityDistribution = initialState.effectivityDistribution;
        break;

      case types.CHANGE_FIGURE_FILTER:
        if (action.table === 'itemTable' || action.table === 'remarkTable')
          draft.figureFilter = action.payload;
        break;

      case types.ITEM_TABLE_GET_DATA:
        draft.itemTable = action.payload;
        break;

      case types.ITEM_TABLE_GET_NHA_BKDN:
        draft.nhaBkdn = action.payload;
        break;

      case types.ITEM_TABLE_TOGGLE_REMARK_ONLY:
        draft.onlyRemarks = !state.onlyRemarks;
        break;

      case types.ITEM_TABLE_GET_REMARK_DATA:
        draft.remarkTable = action.payload;
        break;

      case types.GET_TABLE:
        if (action.table === 'Figure' && !state.figureFilter)
          draft.figureFilter = getFirstFigure(
            action.payload,
            state.figureFilter,
          );
        break;

      case types.SELECT_TABLE:
        if (action.table === 'itemTable' || action.table === 'remarkTable')
          draft.selected = action.payload.selected;
        break;

      case types.GET_TABLE_NOT_SYNC:
        if (action.table === 'Figure' && !state.figureFilter)
          draft.figureFilter = getFirstFigure(
            action.payload,
            state.figureFilter,
          );
        break;

      case types.GET_COUNTS:
        if (action.table === 'Item')
          draft.counts = toObject(action.payload, 'figureDoc');
        if (action.table === 'Remark' && state.type === 'remark')
          draft.counts = toObject(action.payload, 'figureDoc');
        break;

      case types.HIGHLIGHT_TABLE:
        if (action.table === 'itemTable' || action.table === 'remarkTable') {
          draft.highlight = action.payload.highlight;
          draft.scrollTo = action.payload.scrollTo;
        }
        break;

      case types.TABLE_TOGGLE_TYPE:
        if (action.table !== 'item') break;
        draft.counts = initialState.counts;
        draft.type = action.payload;
        break;

      case types.UPDATE_RECORD:
        if (action.table === 'Remark') {
          const record = action.payload;
          const remarks = state.remarkTable[record.documentLine];

          const remarkIndex = remarks?.length
            ? remarks.findIndex(remark => remark.id === record.id)
            : -1;

          // change documentLine value
          // remove record in previous documentLine
          Object.entries(state.remarkTable).forEach(
            ([documentLine, remarks]) => {
              if (record.documentLine !== documentLine) {
                const remarkDeleted = remarks.filter(
                  remark => remark.id !== record.id,
                );
                draft.remarkTable[documentLine] = remarkDeleted;
              }
            },
          );

          // create record in new documentLine
          if (remarkIndex === -1) {
            draft.remarkTable[record.documentLine] = remarks
              ? [...remarks, record]
              : [record];
            break;
          }

          // update record
          draft.remarkTable[record.documentLine][remarkIndex] = record;
        }
        break;

      case types.CREATE_RECORD:
        if (action.table === 'Remark') {
          const record = action.payload;

          const remarks = state.remarkTable[record.documentLine];

          draft.remarkTable[record.documentLine] = remarks
            ? [...remarks, record]
            : [record];
        }
        break;

      case types.DELETE_RECORD:
        if (action.table === 'Remark') {
          const record = action.payload;

          const remarkDeleted = state.remarkTable[record.documentLine].filter(
            remark => remark.id !== record.id,
          );
          remarkDeleted.length > 0
            ? (draft.remarkTable[record.documentLine] = remarkDeleted)
            : delete draft.remarkTable[record.documentLine];
        }
        break;

      case types.ITEM_TABLE_CHANGE_FILTER:
        draft.filter = action.payload;
        break;

      case types.TABLE_CLOSE_MODAL:
        draft.filter = initialState.filter;
        break;

      case types.ITEM_TABLE_GET_EQUIPMENT_DISTRIBUTION:
        draft.effectivityDistribution = action.payload;
        break;

      default:
        break;
    }
  });
