import React from 'react';
import { color } from 'config/constants';

const LayerSubAssembly = React.memo(({ LSAStatus }) => {
  const getStatusColor = status => {
    const lsaStatus = {
      heredity: color.primary,
      override: color.success,
      warning: color.warning,
    };
    return lsaStatus[status] ?? color.grey;
  };
  return (
    <div
      style={{
        height: 12,
        width: 12,
        backgroundColor: getStatusColor(LSAStatus),
      }}
    />
  );
});

export default LayerSubAssembly;
