import produce from 'immer';
import * as types from 'config/types';

const initialState = {
  uploading: false,
  documents: [],
  filePicker: null,
  dbFiles: [],
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case types.GET_DOCUMENTS_FROM_BUCKET:
        draft.documents = action.payload;
        break;

      case types.PICK_FILE:
        draft.filePicker = action.payload;
        break;

      case types.FILE_UPLOADED:
        draft.uploading = false;
        draft.filePicker = null;
        break;

      case types.CREATE_RECORD:
        if (state.uploading === false && state.filePicker !== null)
          draft.uploading = true;
        break;

      case types.UPDATE_RECORD:
        if (state.uploading === false && state.filePicker !== null)
          draft.uploading = true;
        break;

      case types.GET_FILES_FROM_DB:
        draft.dbFiles = action.payload;
        break;

      case types.DOCUMENT_CREATION_CLOSE:
        draft.filePicker = initialState.filePicker;
        break;

      default:
        break;
    }
  });
