import { createSelector } from 'reselect';
import { getCurrentDocumentStepProgress as _getCurrentDocumentStepProgress } from './stepNavigationSelector';
import { groupBy } from 'ais-utils';
import { color } from 'config/constants';
import { toPercentage, shouldItemBeIgnored } from 'lib/utils';

import {
  getFigures as _getFigures,
  getSubAssemblyCategories as _getSubAssemblyCategories,
} from './utilsSelector';
import { getItemTable as _getItemTable } from './tableSelector';
const getFigures = state => _getFigures(state);
const getCurrentDocumentStepProgress = state =>
  _getCurrentDocumentStepProgress(state);
const getItemTable = (filter, type = () => undefined) => state =>
  _getItemTable(filter, type)(state);
const getSubAssemblyCategories = state => _getSubAssemblyCategories(state);

export const getSelected = state => state.itemSettings.selected;
export const getFigureFilter = state => state.itemSettings.figureFilter;
export const getSubAssemblies = state => state.utils.SubAssembly;
export const getCurrentDocument = state => state.utils.currentDocument;
export const getLayers = state => state.utils.Layer;
export const getLayerBySemanticGroup = state =>
  state.userChoices.layerBySemanticGroup;
export const getSubAssemblyBySemanticGroup = state =>
  state.userChoices.subAssemblyBySemanticGroup;

export const getFiguresForCurrentFigureDoc = createSelector(
  getFigures,
  getFigureFilter,
  (figures, currentFigure) => {
    const groupedFiguresByFigureDoc = groupBy(figures, 'figureDoc');
    const currentFigureDocFigures =
      groupedFiguresByFigureDoc?.[currentFigure?.figureDoc];
    return currentFigureDocFigures || [];
  },
);

export const getCurrentFigure = createSelector(
  getFigures,
  getFigureFilter,
  (figures, currentFigure) => {
    if (!currentFigure?.description && currentFigure?.figureDoc) {
      return (
        figures?.find(
          figure => figure?.figureDoc === currentFigure.figureDoc,
        ) || currentFigure
      );
    }
    return currentFigure;
  },
);

export const getItemSettingsProgress = createSelector(
  getCurrentDocumentStepProgress,
  state => state.itemSettings.step,
  (stepProgress, stepName) => {
    if (!stepProgress?.length) return null;
    const done = [];
    const inProgress = [];
    let total = 0;
    const { progress } =
      stepProgress.find(step => step.name === stepName) || {};

    if (progress?.current?.progress?.atoms) {
      // eslint-disable-next-line prefer-destructuring
      total = progress.current.progress.total;
      progress.current.progress.atoms.forEach(atom => {
        if (atom.status === 'done') done.push(atom.reference);
        if (atom.status === 'in_progress') inProgress.push(atom.reference);
      });
    }

    return {
      total,
      done,
      inProgress,
      current: progress?.current?.progress || null,
    };
  },
);
export const getItemSettingsTable = createSelector(
  getItemTable(getFigureFilter),
  items => {
    const [noData] = items;
    if (noData === 'NO DATA') return items;

    const groupedItems = [];
    let previousItem = null;

    const getAncestorLsaValues = (item, lsa) => {
      const lsaValues = item.closestAncestors?.reduce((acc, ancestor) => {
        if (
          ancestor[lsa] &&
          !acc.some(
            ({ description }) => description === ancestor[lsa]?.description,
          )
        )
          return [...acc, ancestor[lsa]];
        return acc;
      }, []);
      return lsaValues;
    };

    const status = (isLSAWarning, isLSAHeredity, isLSAOverride) => {
      if (isLSAHeredity) return 'heredity';
      if (isLSAOverride) return 'override';
      if (isLSAWarning) return 'warning';
      return;
    };

    items
      .filter(item => !shouldItemBeIgnored(item))
      .forEach(item => {
        const [firstLayerES] = item.LayerES || [];
        const [firstSubAssemblyES] = item.SubAssemblyES || [];

        const hasClosestLSA = !!item.closestAncestors;

        const ancestorSubAssemblyValues = getAncestorLsaValues(
          item,
          'SubAssembly',
        );

        const ancestorLayerValues = getAncestorLsaValues(item, 'Layer');

        const isClosestLSAAnUniqValue =
          hasClosestLSA &&
          ancestorSubAssemblyValues.length === 1 &&
          ancestorLayerValues.length === 1;

        const closestLayer = item.closestAncestors?.[0]?.Layer?.description;
        const closestSubAssembly =
          item.closestAncestors?.[0]?.SubAssembly?.description;

        const isLSAHeredity =
          hasClosestLSA &&
          isClosestLSAAnUniqValue &&
          closestLayer === item.Layer.description &&
          closestSubAssembly === item.SubAssembly.description;

        const isLSAWarning =
          (firstLayerES || firstSubAssemblyES) &&
          (firstLayerES?.id !== item.LayerId ||
            firstSubAssemblyES?.id !== item.SubAssemblyId);

        const isLSAOverride = item.History?.some?.(
          history =>
            history.field === 'LayerId' || history.field === 'SubAssemblyId',
        );

        const LSAStatus = status(isLSAWarning, isLSAHeredity, isLSAOverride);

        const hasLSAChanged =
          previousItem?.LayerId !== item?.LayerId ||
          previousItem?.SubAssemblyId !== item?.SubAssemblyId;

        if (hasLSAChanged && !item.isAttachingPart)
          groupedItems.push(`${item.LayerId} / ${item.SubAssemblyId}`);

        groupedItems.push({
          ...item,
          LSAStatus,
          ancestorSubAssemblyValues,
          ancestorLayerValues,
          effLocStatus: 'heredity',
        });

        if (!item.isAttachingPart) previousItem = item;
      });
    return groupedItems;
  },
);

export const getLSAProgress = createSelector(getItemSettingsTable, items => {
  let warningQuantity = 0;
  let itemQuantity = 0;
  let heredityQuantity = 0;
  let overrideQuantity = 0;

  items.forEach(item => {
    if (!item.isAttachingPart && typeof item !== 'string') itemQuantity += 1;

    if (item.LSAStatus === 'warning') warningQuantity += 1;
    if (item.LSAStatus === 'heredity') heredityQuantity += 1;
    if (item.LSAStatus === 'override') overrideQuantity += 1;
  });

  const strangeQuantity =
    itemQuantity - warningQuantity - heredityQuantity - overrideQuantity;

  return {
    warning: warningQuantity,
    progress: [
      {
        name: 'Heredity',
        value: toPercentage(heredityQuantity, itemQuantity),
        color: color.primary,
      },
      {
        name: 'Override',
        value: toPercentage(overrideQuantity, itemQuantity),
        color: color.success,
      },
      {
        name: 'Warning',
        value: toPercentage(warningQuantity, itemQuantity),
        color: color.warning,
      },
      {
        name: 'Strange',
        value: toPercentage(strangeQuantity, itemQuantity),
        color: color.grey,
      },
    ],
  };
});

export const getSelectedItemData = createSelector(
  getSelected,
  getItemSettingsTable,
  (itemIds, items) => {
    if (!itemIds || itemIds?.length === 0) return null;
    return items.find(item => item.id === itemIds[0]);
  },
);

export const getFirstWarningItem = createSelector(
  getItemSettingsTable,
  items => {
    return items.find(item => item?.LSAStatus === 'warning');
  },
);

export const getMoreLayer = createSelector(
  getSelectedItemData,
  getLayers,
  getLayerBySemanticGroup,
  (item, layers, layerBySemanticGroup) => {
    const more = [];
    if (item.LayerES?.length)
      more.push({
        header: 'Automatic proposals',
        source: 'automatic',
        rows: item.LayerES,
      });

    if (layerBySemanticGroup?.[0] !== 'NO DATA')
      more.push({
        header: 'Same Semantic Group',
        source: 'semanticGroup',
        rows: layerBySemanticGroup,
      });

    if (layers?.[0] !== 'NO DATA')
      more.push({ header: 'Others', source: 'all', rows: layers });
    return more;
  },
);

export const getMoreSubAssembly = createSelector(
  getSelectedItemData,
  getSubAssemblies,
  getSubAssemblyBySemanticGroup,
  (item, subAssemblies, subAssemblyBySemanticGroup) => {
    const more = [];

    if (item.SubAssemblyES?.length)
      more.push({ header: 'Automatic proposals', rows: item.SubAssemblyES });

    if (subAssemblyBySemanticGroup?.[0] !== 'NO DATA')
      more.push({
        header: 'Same Semantic Group',
        rows: subAssemblyBySemanticGroup,
      });

    if (subAssemblies?.[0] !== 'NO DATA')
      more.push({ header: 'Others', rows: subAssemblies });

    return more;
  },
);

export const getSelectedItems = createSelector(
  getItemSettingsTable,
  getSelected,
  (items, selectedItemIds) => {
    return items.filter(item =>
      selectedItemIds?.some(selectedItemId => selectedItemId === item.id),
    );
  },
);

export const getNhaBkdnFigures = createSelector(
  getItemSettingsTable,
  getFigures,
  (items, figures) => {
    const bkdnFigureDoc = [
      ...new Set(
        items
          .filter(item => !!item.BKDN && item)
          .map(item => item?.BKDN?.figureDoc),
      ),
    ];

    const nhaFigureDoc = [
      ...new Set(
        items
          .filter(item => !!item.NHA && item)
          .flatMap(item => item?.NHA?.map(nha => nha.figureDoc)),
      ),
    ];

    const groupedFiguresByFigureDoc = groupBy(figures, 'figureDoc');

    const getFigures = figureDocs => {
      return figureDocs
        .map(figureDoc => groupedFiguresByFigureDoc[figureDoc])
        .filter(Boolean);
    };

    return {
      nha: getFigures(nhaFigureDoc),
      bkdn: getFigures(bkdnFigureDoc),
    };
  },
);

export const getCurrentCategorySubAssemblies = createSelector(
  getSelectedItemData,
  getSubAssemblyCategories,
  (item, subAssemblyCategories) => {
    const groupedSubAssemblyCategories = groupBy(
      Object.values(subAssemblyCategories),
      'category',
    );

    return (
      groupedSubAssemblyCategories[item?.SubAssemblyCategory?.category]
        ?.map(subAssemblyCategory => subAssemblyCategory.SubAssembly)
        ?.filter(Boolean) || []
    );
  },
);
